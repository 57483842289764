import Axios from 'axios';
// import { localsAsTemplateData } from 'hbs';
import React, { Component } from 'react';
import swal from 'sweetalert';



class CheckOTP extends Component {
    constructor(props) {
        super(props)
        this.state = {
            OTP: ''
        }
        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }
    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    handleSubmit(e) {
        e.preventDefault()
        var otp = localStorage.getItem('otp')
        if (this.state.OTP == otp) {
            this.props.history.push('/resetPassword')
        }
        else {
            swal('OTP Invalid', '', 'error')
        }
    }
    componentDidMount() {

    }
    render() {
        const { OTP } = this.state
        return (
            <div>
                <center>
                    <div className="content" style={{ marginTop: "15%" }}>
                        <div className="container-fluid">
                            <div className="login-box">
                                <div className="card card-outline card-info">
                                    <div className="card-header text-center">
                                        <h3><b>OTP&nbsp;</b></h3>
                                    </div>
                                    <div className="card-body">

                                        <form onSubmit={this.handleSubmit}>
                                            <div className="input-group mb-3">
                                                <input type="number" className="form-control" name="OTP" placeholder="Enter OTP" value={OTP} onChange={this.handleChange} />
                                                <div className="input-group-append">
                                                    <div className="input-group-text">
                                                        <span className="fas fa-envelope"></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12">
                                                    <button type="submit" className="btn btn-info btn-block">Submit</button>
                                                </div>

                                            </div>
                                        </form>


                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </center>
            </div>
        );
    }
}

export default CheckOTP;
import React, { Component } from 'react';
import Axios from 'axios';
import swal from 'sweetalert';
import { Link } from 'react-router-dom';
import { messaging } from '../init-fcm';
import disableBrowserBackButton from 'disable-browser-back-navigation';
import axios from 'axios';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      UserName: '',
      Password: '',
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleSubmit(event) {
    event.preventDefault();
    Axios.post('/login', this.state).then((res) => {
      if (res.data.errors != null) {
        swal(res.data.errors[0].msg, '', 'error');
      } else {
        if (res.data.status === false) {
          localStorage.setItem('token', res.data.token);
          localStorage.setItem('user', this.state.UserName);
          localStorage.setItem('password', this.state.Password);
          localStorage.setItem('isSupplier', 'false');
          this.props.history.push('/adminHome');
        } else {
          localStorage.setItem('token', res.data.token);
          localStorage.setItem('user', this.state.UserName);
          localStorage.setItem('password', this.state.Password);
          localStorage.setItem('isSupplier', 'true');
          this.props.history.push('/supplierDashboard');
        }
      }
    });
  }
  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  async componentDidMount() {
    disableBrowserBackButton();
    // messaging.requestPermission()
    //   .then(async function() {
    //     const token = await messaging.getToken();

    //     axios.post('/updateDeviceID',{
    //         deviceID:token
    //     }).then(resp=>{

    //     })
    //   })
    //   .catch(function(err) {

    //   });
  }

  render() {
    return (
      <div>
        <div className="wrapper">
          <center>
            <div
              className="login-box"
              style={{
                marginTop: '5%',
                transform: 'scale(1.01)',
                boxShadow:
                  '0 10px 20px rgb(0 0 0 / 54%), 0 4px 8px rgb(0 0 0 / 6%)',
                borderRadius: '20px',
              }}
            >
              <div className="card">
                <div className="card-title">
                  <div className="login-logo pt-4">
                    <i className="far fa-gem"></i>&nbsp;<b>Shree</b>Diamond
                  </div>
                </div>
                <div className="card-body login-card-body">
                  <p className="login-box-msg">Sign in to start your session</p>

                  <form onSubmit={this.handleSubmit}>
                    <div className="input-group mb-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="UserName"
                        name="UserName"
                        value={this.state.UserName}
                        onChange={this.handleChange}
                      />
                      <div className="input-group-append">
                        <div className="input-group-text">
                          <span className="fas fa-envelope"></span>
                        </div>
                      </div>
                    </div>
                    <div className="input-group mb-3">
                      <input
                        type="password"
                        className="form-control"
                        placeholder="Password"
                        name="Password"
                        value={this.state.password}
                        onChange={this.handleChange}
                      />
                      <div className="input-group-append">
                        <div className="input-group-text">
                          <span className="fas fa-lock"></span>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <div
                          className="icheck-primary"
                          style={{ float: 'left' }}
                        >
                          <input type="checkbox" id="remember" />
                          &nbsp;<span>Remember Me</span>
                        </div>
                      </div>
                    </div>
                    <div className="row pt-2">
                      <div className="col-12">
                        <button
                          type="submit"
                          className="btn btn-danger btn-block"
                        >
                          Sign In
                        </button>
                      </div>
                    </div>
                  </form>

                  <p className="mb-1 pt-4">
                    <Link to="/forgotpassword">I forgot my password</Link>
                  </p>
                  <p className="mb-0">
                    <Link to="/Supplier" className="text-center">
                      New Supplier?
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </center>
        </div>
      </div>
    );
  }
}

export default Login;

import Axios from 'axios';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
import Header from '../../Headers/Header';

class UpdateMaster extends Component {
    constructor(props) {
        super(props)
        this.state = {
            fieldsname: '',
            fieldscategory: '',
            Storevalue: ''
        }
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleTextChange = this.handleTextChange.bind(this)
    }
    handleTextChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })

    }
    handleSubmit(e) {
        e.preventDefault()
      
        Axios.post(`/Update/${this.props.match.params.id}`, this.state).then((res) => {
            if (res.data.msg) {
                swal('Update Successfully', {
                    button: false,
                    timer: 1000,
                    icon: 'success'
                })
            }
            this.props.history.push('/MasterEntry')

        })
    }
    componentDidMount() {
        Axios.get(`/Update/${this.props.match.params.id}`).then((res) => {
            this.setState({
                fieldsname: res.data.fieldsname,
                fieldscategory: res.data.fieldscategory,
                Storevalue: res.data.StoreValue
            })

        })
    }
    render() {
        return (
            <div>
                <Header />
                <div className="wrapper">
                    <div className="content-wrapper">
                        <section className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-2">
                                    <div className="col-sm-6">
                                        <h1>Master Entry</h1>
                                    </div>
                                    <div className="col-sm-6">
                                        <ol className="breadcrumb float-sm-right">
                                            <li className="breadcrumb-item"><Link to="/adminHome" style={{ color: "#17a2b8" }}>Home</Link></li>
                                            <li className="breadcrumb-item active">Master Entry</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="container">
                            <div className="container-fluid">
                                <Link to="/MasterEntry" className="btn btn-info"><i class="fas fa-chevron-left"></i>&nbsp; Back</Link>
                                <form onSubmit={this.handleSubmit} style={{ marginTop: "5px" }}>
                                    <div className="row">
                                        <div className="col-md-3">
                                            <input type="text" name="fieldsname" value={this.state.fieldsname} className="form-control" onChange={this.handleTextChange} readOnly />
                                        </div>
                                        <div className="col-md-3">
                                            <input type="text" name="fieldscategory" value={this.state.fieldscategory} className="form-control" onChange={this.handleTextChange} />
                                        </div>

                                        <div className="col-md-3">
                                            <input className="form-control" name="Storevalue" value={this.state.Storevalue} type="text" onChange={this.handleTextChange} />
                                        </div>
                                        <div className="col-md-3">
                                            <button type="submit" className="btn btn-info" >Update</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        );
    }
}

export default UpdateMaster;
import Axios from 'axios';
import React, { Component } from 'react';
import Header from '../../Headers/Header';
import { ProgressBar, Tab } from 'react-bootstrap';
import swal from 'sweetalert';

class FetchAPI extends Component {
  constructor(props) {
    super(props);
    this.state = {
      API_URL: '',
      getData: '',
      partyname: [],
      getMapping: [],
      getFileHeader: [],
      getName: '',
      getMarkup: 0,
      InwardType: '',
      uploadPercentage: 0,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleTextChange = this.handleTextChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleTypeChange = this.handleTypeChange.bind(this);
  }

  handleTypeChange(e) {
    this.setState({
      InwardType: e.target.value,
    });
  }

  handleChange(e) {
    Axios.get(`/displayMarkup/${e.target.value}`).then((resp) => {
      this.setState({
        getMapping: resp.data.mapping,
        getFileHeader: resp.data.fileheader,
        getName: resp.data.partyName,
        getMarkup: resp.data.markup,
      });
    });
  }

  handleTextChange(e) {
    this.setState({
      API_URL: e.target.value,
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    Axios.post('/users/downloadAPIData', this.state, {
      onUploadProgress: (ProgressEvent) => {
        let percent = Math.round(
          (ProgressEvent.loaded / ProgressEvent.total) * 100
        );

        if (percent < 100) {
          this.setState({
            uploadPercentage: percent,
          });
        }
      },
    }).then((res) => {
      this.setState({
        getData: res.data.json,
      });
      swal('Imported', '', 'success');
      this.componentDidMount();
    });
  }

  componentDidMount() {
    Axios.get('/users/getParties').then((party) => {
      this.setState({
        partyname: party.data,
      });
    });
  }

  render() {
    return (
      <div>
        <Header />
        <div className="wrapper">
          <div className="content-wrapper">
            <section className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-6">
                    <h1>Fetch API</h1>
                  </div>
                  <div className="col-sm-6">
                    <ol className="breadcrumb float-sm-right">
                      <li className="breadcrumb-item">
                        <a href="#" style={{ color: '#17a2b8' }}>
                          Home
                        </a>
                      </li>
                      <li className="breadcrumb-item active">Fetch API</li>
                    </ol>
                  </div>
                </div>
              </div>
            </section>
            <section className="container-fluid">
              <form onSubmit={this.handleSubmit}>
                <div className="row">
                  <div className="col-md-2">
                    <select
                      name="partyID"
                      className="form-control"
                      size="3"
                      onChange={this.handleChange}
                    >
                      <option selected disabled>
                        Select Party
                      </option>
                      {this.state.partyname.map((partyname) => (
                        <option value={partyname._id}>
                          {partyname.partyName}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-md-2">
                    <select
                      name="InwardType"
                      className="form-control"
                      size="3"
                      onChange={this.handleTypeChange}
                    >
                      <option selected disabled>
                        Select Type
                      </option>
                      <option>NATURAL</option>
                      <option>LABGROWN</option>
                    </select>
                  </div>
                  <div className="col-md-8">
                    <input
                      type="text"
                      name="API_URL"
                      className="form-control"
                      placeholder="Enter Fetch URL"
                      onChange={this.handleTextChange}
                    />
                  </div>
                </div>
                <div className="row pt-2">
                  <div className="col-md-12">
                    <center>
                      <button
                        className="btn btn-success col-3"
                        disabled={this.state.API_URL == ''}
                      >
                        Fetch
                      </button>
                    </center>
                  </div>
                </div>
              </form>
              <div className="row pt-2">
                <div className="col-md-12">
                  <textarea
                    className="form-control"
                    rows="13"
                    value={this.state.getData}
                  ></textarea>
                </div>
              </div>
              <div className="row" style={{ marginTop: '10px' }}>
                {this.state.uploadPercentage > 0 && (
                  <ProgressBar
                    striped
                    variant="warning"
                    now={this.state.uploadPercentage}
                    label={`${this.state.uploadPercentage}%`}
                    style={{ width: '20%', marginLeft: '2%' }}
                  />
                )}
              </div>
            </section>
          </div>
        </div>
      </div>
    );
  }
}

export default FetchAPI;

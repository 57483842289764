import Axios from 'axios';
import React, { Component } from 'react';
import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
import Header from '../../Headers/Header';

class MasterEntry extends Component {
    constructor(props) {
        super(props)
        this.state = {
            fieldsname: '',
            fieldscategory: '',
            viewdata: [],
            Storevalue: '',
            selectedvalue: ''
        }

        this.handleTextChange = this.handleTextChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleKeyPress = this.handleKeyPress.bind(this)
    }
    handleKeyPress(e) {
        if (e.charCode === 32) {
            alert('Please Remove Space')
        }
    }
    handleChange(e) {
        this.setState({
            selectedvalue: e.target.value
        })




    }

    componentDidMount() {
   
        Axios.get(`/ViewMaster`).then((res) => {
            this.setState({
                viewdata: res.data
            })
            var script = document.createElement("script")
            script.id = "FormTable"
            script.src = `js/content.js`
            document.body.appendChild(script)


        })



    }
    handleDelete(e, id) {
        e.preventDefault()
        swal({
            title: "Are you sure?",
            text: "You want to delete this record?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {

                    Axios.get(`/Delete/${id}`).then((res) => {

                        swal('Delete Successfully', {
                            button: false,
                            timer: 1500,
                            icon: 'success'
                        })
                        // this.componentDidMount()
                        this.viewdata1()

                    })

                } else {
                    swal("Canceled");
                }
            });

    }
    // handleUpdate(e, id) {
    //     e.preventDefault()


    // }

    handleTextChange(e) {
        this.setState({
            [e.target.name]: e.target.value,

        })

    }
    // componentWillReceiveProps() {
    //     Axios.get(`/ViewMaster`).then((res) => {
    //         this.setState({
    //             viewdata: res.data
    //         })
    //         const script = document.createElement("script")
    //         script.src = `js/content.js`
    //         script.async = true
    //         document.body.appendChild(script)


    //     })
    // }
    handleSubmit(e) {
        e.preventDefault()
      
        Axios.post('/users/MasterEntry', this.state).then((res) => {


            swal('Saved', {
                button: false,
                timer: 1500,
                icon: 'success'
            })
            // this.componentDidMount()
            this.viewdata1()




            this.setState({
                fieldsname: '',
                fieldscategory: '',
                Storevalue: ''
            })
            // const script = document.createElement("script")
            // script.src = `js/content.js`
            // script.async = true
            // document.body.appendChild(script)


        })


    }
    viewdata1() {
        Axios.get(`/ViewMaster`).then(res => {
            this.setState({
                viewdata: res.data
            })

        })
    }


    render() {

        return (
            <div>
                <Header />
                <div className="wrapper">
                    <div className="content-wrapper">
                        <section className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-2">
                                    <div className="col-sm-6">
                                        <h1>Master Entry</h1>
                                    </div>
                                    <div className="col-sm-6">
                                        <ol className="breadcrumb float-sm-right">
                                            <li className="breadcrumb-item"><Link to="/adminHome" style={{ color: "#17a2b8" }}>Home</Link></li>
                                            <li className="breadcrumb-item active">Master Entry</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="container">



                            <div className="container-fluid">
                                <form onSubmit={this.handleSubmit}>
                                    <div className="row">


                                        <div className="col-md-3">

                                            <input type="text" name="fieldsname" value={this.state.fieldsname} className="form-control" placeholder="Fields Name" onChange={this.handleTextChange} required onKeyPress={this.handleKeyPress} />


                                        </div>
                                        <div className="col-md-3">
                                            <input type="text" name="fieldscategory" value={this.state.fieldscategory} placeholder="Fields Category" className="form-control" onChange={this.handleTextChange} required />
                                        </div>
                                        <div className="col-md-3">
                                            <input type="text" name="Storevalue" value={this.state.Storevalue} placeholder="Store value" className="form-control" onChange={this.handleTextChange} required />

                                        </div>
                                        <div className="col-md-3">
                                            <button type="submit" className="btn btn-info">Save</button>
                                        </div>

                                    </div>
                                </form>
                                <div style={{ marginTop: "20px" }}>
                                    <div className="card" >
                                        <div className="card-header">
                                            <div className="card-title">View Master Entry</div>

                                        </div>
                                        <Fragment>

                                            <div className="card-body">
                                                <div className="table-responsive">

                                                    <table id="example1" className="table1 jsDataTable table table-bordered table-striped" >
                                                        <thead>
                                                            <tr >
                                                                <th>Fields Name</th>
                                                                <th>Fields Category</th>
                                                                <th>Store Value</th>
                                                                <th>Update</th>
                                                                {/* <th>Delete</th> */}
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.viewdata.map((view) => (
                                                                <tr>
                                                                    <td>{view.fieldsname}</td>
                                                                    <td>{view.fieldscategory}</td>
                                                                    <td>{view.StoreValue}</td>
                                                                    <td><Link to={`/update/${view._id}`} ><center><span style={{ color: "#17a2b8" }}><i class="fas fa-edit"></i></span></center></Link></td>
                                                                    {/* <td><Link onClick={(e) => { this.handleDelete(e, view._id) }}><center><span style={{ color: "rgb(201, 61, 58)" }}><i className="fa fa-trash"></i></span></center></Link></td> */}

                                                                </tr>
                                                            ))}
                                                        </tbody>


                                                    </ table >




                                                </div>

                                            </div>


                                        </Fragment>

                                    </div>
                                </div>
                            </div>


                        </section>

                    </div>
                </div>
            </div >
        );
    }
}

export default MasterEntry;
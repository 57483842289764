import Axios from 'axios';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../Headers/Header';
import swal from 'sweetalert'
class RapImport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            RapnetImport: '',
            Shapename: ''
        }
        this.handleFile = this.handleFile.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleChange = this.handleChange.bind(this)
    }

    handleFile(event) {

        this.setState({
            RapnetImport: event.target.files[0]
        })

    }
    handleChange(e) {
        this.setState({
            Shapename: e.target.value
        })
    }
    handleSubmit(event) {
        event.preventDefault()

     

        const formData = new FormData()
        formData.append('RapnetImport', this.state.RapnetImport)
        formData.append('Shapename', this.state.Shapename)

        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        };
        Axios.post(`/users/RapnetData?shapename=${this.state.Shapename}`, formData, {

            onUploadProgress: ProgressEvent => {
                //  const {loaded,total}=ProgressEvent
                let percent = Math.round(ProgressEvent.loaded / ProgressEvent.total * 100)
     

                if (percent < 100) {
                    this.setState({ uploadPercentage: percent })
                }

            }
        }).then((response) => {

            if (response.data.msg) {
                this.setState({ uploadPercentage: 100 })
                swal("File has been imported", {
                    button: false,
                    timer: 1500,
                    icon: 'success'
                });
            }
            this.setState({ uploadPercentage: 100 }, () => {
                setTimeout(() => {
                    this.setState({ uploadPercentage: 0 })

                }, 1000)
            })

        })



    }
    render() {
        return (

            <div>
                <Header />
                <div className="wrapper">
                    <div className="content-wrapper">
                        <section className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-2">
                                    <div className="col-sm-6">
                                        <h1 >Import Rapaport</h1>
                                    </div>
                                    <div className="col-sm-6">
                                        <ol className="breadcrumb float-sm-right">
                                            <li className="breadcrumb-item"><a href="#" style={{ color: "#17a2b8" }}>Home</a></li>
                                            <li className="breadcrumb-item active">Upload Rapnet</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="content">
                            <div className="container-fluid">
                                <Link to="/ViewRapnet" className="btn btn-info"><i class="fas fa-chevron-left" ></i>&nbsp; Back</Link>

                                <form>
                                    <div className="card" >
                                        <div className="card-header">
                                            <h3 className="card-title">Add Rapaport</h3>

                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <button type="button" className="btn btn-danger"><i className="fa fa-download"></i>&nbsp;Import from API</button>
                                                </div>
                                                <div className="col-md-2">
                                                    <select name="Shapename" className="form-control" onChange={this.handleChange}>
                                                        <option disabled selected>--Select Shape----</option>
                                                        <option value="round">Round</option>
                                                        <option value="pear">Pear</option>
                                                    </select>
                                                </div>
                                                <div className="col-md-3" style={{ flex: "0 0 18%" }}>
                                                    <label className="btn btn-secondary " style={{ fontWeight: "normal" }}><i className="fa fa-upload"></i> Upload Manually
                                                                <input type="file" id="actual-btn" style={{ display: "none" }} name="RapnetImport" onChange={this.handleFile} /></label>
                                                </div>
                                                <div className="col-md-3">
                                                    <input type="submit" className="btn btn-info " style={{ fontSize: "1rem" }} value="Upload" onClick={this.handleSubmit}></input>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </section>
                    </div>
                </div>

            </div>
        );
    }
}

export default RapImport;
import React, { Component, Fragment } from 'react';
import Header from '../../Headers/Header';
import axios from 'axios';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
import $ from 'jquery';
import config from '../../Config/config.json';
class StoneOffer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Offers: [],
      Weight: '',
      Color: '',
      Clarity: '',
      Price: '',
      Stock: '',
      VendorStock: '',
      VendorName: '',
      deviceID: '',
    };
  }

  // updateStatus(id, e) {
  //     e.preventDefault()

  //     swal({
  //         title: "Are you sure?",
  //         text: "Do you want to reject this stone offer?",
  //         icon: "warning",
  //         buttons: true,
  //         dangerMode: true,
  //     })
  //         .then((willDelete) => {
  //             if (willDelete) {
  //                 axios.get(`/users/updateOffStone/${id}`).then(res => {
  //                     this.componentDidMount()
  //                 })
  //             } else {
  //                 swal("You canceled");
  //             }
  //         });
  // }
  // updateStatuson(id, e) {
  //     e.preventDefault()

  //     swal({
  //         title: "Are you sure?",
  //         text: "Do you want to accept this stone offer?",
  //         icon: "warning",
  //         buttons: true,
  //         dangerMode: true,
  //     })
  //         .then((willDelete) => {
  //             if (willDelete) {
  //                 axios.get(`/users/updateOnStone/${id}`).then(res => {
  //                     this.componentDidMount()
  //                 })
  //             } else {
  //                 swal("You Canceled");
  //             }
  //         });

  // }

  AcceptRequest(e, id, userid) {
    axios.get(`/getCustomerDeviceToken/${userid}`).then((resp) => {
      this.setState({
        deviceID: resp.data.deviceID,
      });
    });
    swal({
      title: 'Are you sure?',
      text: 'Do you want to Accept the api access request?',
      icon: 'warning',
      buttons: true,
    }).then((willDelete) => {
      if (willDelete) {
        axios.get(`/users/AcceptStoneRequest/${id}`).then((res) => {
          const bodyData = {
            notification: {
              body: 'Your stone offer has been accepted',
              title: 'Stone offer',
            },
            to: this.state.deviceID,
          };
          const options = {
            method: 'post',
            headers: {
              Accept: 'application/json, text/plain, */*',
              'Content-Type': 'application/json',
              Authorization: config.SECRET_KEY,
            },
            body: JSON.stringify(bodyData),
          };
          fetch('https://fcm.googleapis.com/fcm/send', options).then(
            (response) => {
              return response.json();
            }
          );

          this.componentDidMount();
        });
      } else {
        swal('You Canceled');
      }
    });
  }
  RejectRequest(e, id, userid) {
    axios.get(`/getCustomerDeviceToken/${userid}`).then((resp) => {
      this.setState({
        deviceID: resp.data.deviceID,
      });
    });
    swal({
      title: 'Are you sure?',
      text: 'Do you want to Accept the api access request?',
      icon: 'warning',
      buttons: true,
    }).then((willDelete) => {
      if (willDelete) {
        axios.get(`/users/RejectStoneRequest/${id}`).then((res) => {
          const bodyData = {
            notification: {
              body: 'Your stone offer has been rejected',
              title: 'Stone Offer',
            },
            to: this.state.deviceID,
          };
          const options = {
            method: 'post',
            headers: {
              Accept: 'application/json, text/plain, */*',
              'Content-Type': 'application/json',
              Authorization: config.SECRET_KEY,
            },
            body: JSON.stringify(bodyData),
          };
          fetch('https://fcm.googleapis.com/fcm/send', options).then(
            (response) => {
              return response.json();
            }
          );

          this.componentDidMount();
        });
        // this.viewRequest()
      } else {
        swal('You Canceled');
      }
    });
  }
  viewRequest() {
    axios.get('/users/viewStoneOffers').then((res) => {
      this.setState({
        Offers: res.data,
      });
    });
  }
  componentDidMount() {
    axios.get('/users/viewStoneOffers').then((res) => {
      this.setState({
        Offers: res.data.offer,
      });

      const script = document.createElement('script');
      script.src = `js/content1.js`;
      script.async = true;
      document.body.appendChild(script);
    });
  }

  render() {
    return (
      <div>
        <Header />

        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <h3 className="card-title">Stone Offer Requests</h3>
              </div>
              {this.state.Offers.length != 0 ? (
                <div className="table-responsive">
                  <div className="card-body">
                    <table
                      id="example2"
                      className="table table-bordered table-striped"
                    >
                      <thead>
                        <tr>
                          <th>Created At</th>
                          <th>Name</th>
                          <th>UserName</th>
                          <th>Stock</th>
                          <th>Vendor Stock</th>
                          <th>Certificate</th>
                          <th>PartyName</th>
                          <th>Discount</th>
                          <th>Approve/Reject</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.Offers.map((offer, index) => (
                          <tr>
                            <td>{offer.createdAt || '2023'}</td>
                            <td>{offer.UserID.FullName}</td>
                            <td>{offer.UserID.UserName}</td>
                            <td>{offer.InwardDetails.Stock}</td>
                            <td>{offer.InwardDetails.VendorStock}</td>
                            <td>{offer.InwardDetails.Certificate}</td>
                            <td>{offer.InwardDetails.PartyName}</td>
                            <td>{offer.Discount}</td>
                            <td>
                              {offer.IsPending === true ? (
                                <Fragment>
                                  <Link
                                    onClick={(e) =>
                                      this.AcceptRequest(
                                        e,
                                        offer._id,
                                        offer.UserID._id
                                      )
                                    }
                                  >
                                    <i
                                      className="fas fa-check-circle fa-2x"
                                      style={{ color: '#17a2b8' }}
                                    ></i>
                                  </Link>
                                  &nbsp;&nbsp;
                                  <Link
                                    onClick={(e) =>
                                      this.RejectRequest(
                                        e,
                                        offer._id,
                                        offer.UserID._id
                                      )
                                    }
                                  >
                                    <i
                                      className="fas fa-times-circle fa-2x"
                                      style={{ color: '#c93d3a' }}
                                    ></i>
                                  </Link>
                                </Fragment>
                              ) : (
                                <Fragment>
                                  {offer.Status === true ? (
                                    <b style={{ color: '#17a2b8' }}>ACCEPTED</b>
                                  ) : (
                                    <b style={{ color: '#c93d3a' }}>REJECTED</b>
                                  )}
                                </Fragment>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              ) : (
                <div className="card-body text-center">No Data</div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default StoneOffer;

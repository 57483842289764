import axios from 'axios'
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import PartyHeader from '../../Headers/PartyHeader'

class InvalidStones extends Component {

    constructor(props) {
        super(props)
        this.state = {
            InvalidStones: []
        }
    }

    componentDidMount() {

        axios.get(`/users/getInvalidStones/${localStorage.getItem('user')}`).then(resp => {
            this.setState({
                InvalidStones: resp.data
            })
            const script = document.createElement("script")
            script.src = `js/content.js`
            script.async = true
            document.body.appendChild(script)
        })
    }



    render() {
        return (
            <div>
                <PartyHeader />
                <div className="wrapper">
                    <div className="content-wrapper">
                        <section className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-2">
                                    <div className="col-sm-6">
                                        <h1>Manage Inward</h1>
                                    </div>
                                    <div className="col-sm-6">
                                        <ol className="breadcrumb float-sm-right">
                                            <li className="breadcrumb-item"><a href="#" style={{ color: "#17a2b8" }}>Home</a></li>
                                            <li className="breadcrumb-item active">Add Inward</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="content">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-12">

                                        <div className="card" style={{ marginTop: "2%" }}>
                                            <div className="card-header">
                                                <h3 className="card-title">Invalid Stones</h3>
                                            </div>
                                            <div className="card-body">
                                                < div className="table-responsive">

                                                    {
                                                        <table id="example1" className="table table-bordered table-striped " >
                                                            <thead>
                                                                <tr>
                                                                    {/* <th><div>{this.state.status ? <Link className="removestyle  " onClick={(e) => this.loadPage('SrNo')}>SR No.<i class="fa fa-caret-up" aria-hidden="true"></i>:<i class="fa fa-caret-down" aria-hidden="true"></i></Link>}</div></th> */}
                                                                    {/* {<th><div>this.state.status ? <Link className="removestyle  " onClick={(e) => this.loadPage('SrNo')}>SR No.<i class="fa fa-caret-up" aria-hidden="true"></i>:<i class="fa fa-caret-down" aria-hidden="true"></i></Link></div></th>} */}


                                                                    <th>VenderStock</th>
                                                                    <th>VendorName</th>
                                                                    <th>Availability</th>
                                                                    <th>Shape</th>
                                                                    <th>Weight</th>
                                                                    <th>Color</th>
                                                                    <th>Clarity</th>
                                                                    <th>CutGrade</th>
                                                                    <th>Polish</th>
                                                                    <th>Symmetry</th>
                                                                    <th>FluorescenceIntensity</th>
                                                                    <th>FluorescenceColor</th>
                                                                    <th>Measurements</th>
                                                                    <th>Lab</th>
                                                                    <th>Certificate</th>
                                                                    <th>Treatment</th>
                                                                    <th>Rap</th>
                                                                    <th>RapAmt</th>
                                                                    <th>Rapnet Discount</th>
                                                                    <th>RapNetPrice</th>
                                                                    <th>NetValue</th>
                                                                    <th>CashPrice</th>
                                                                    <th>CashPriceDiscount</th>
                                                                    <th>FancyColor</th>
                                                                    <th>FancyColorIntensity</th>
                                                                    <th>FancyColorOvertone</th>
                                                                    <th>Depth</th>
                                                                    <th>Table</th>
                                                                    <th>GirdleThin</th>
                                                                    <th>GidleThick</th>
                                                                    <th>Girdle</th>
                                                                    <th>GirdleCondition</th>
                                                                    <th>CuletSize</th>
                                                                    <th>CuletCondition</th>
                                                                    <th>CrownHeight</th>
                                                                    <th>CrownAngle</th>
                                                                    <th>PavilionDepth</th>
                                                                    <th>PavilionAngle</th>
                                                                    <th>LaserInscription</th>
                                                                    <th>CertComment</th>
                                                                    <th>Country</th>
                                                                    <th>State</th>
                                                                    <th>City</th>
                                                                    <th>Timetolocation</th>
                                                                    <th>IsMatchedPairSeparable</th>
                                                                    <th>PairStock</th>
                                                                    <th>AllowRapLinkFeed</th>
                                                                    <th>ParcelStones</th>
                                                                    <th>CertificateFilename</th>
                                                                    <th>DiamondImage</th>
                                                                    <th>SarinLoupe</th>
                                                                    <th>TradeShow</th>
                                                                    <th>Keytosymbols</th>
                                                                    <th>Shade</th>
                                                                    <th>StarLength</th>
                                                                    <th>CenterInclusion</th>
                                                                    <th>BlackInclusion</th>
                                                                    <th>MemberComment</th>
                                                                    <th>ReportIssueDate</th>
                                                                    <th>ReportType</th>
                                                                    <th>LabLocation</th>
                                                                    <th>Brand</th>
                                                                    <th>Milky</th>
                                                                    <th>PriceFromRapnet</th>
                                                                    <th>RapnetDiscMarkup</th>
                                                                    <th>Price per Carat</th>
                                                                    <th>Final Price</th>
                                                                    <th>INWARD TYPE</th>
                                                                </tr>
                                                            </thead>

                                                            <tbody >
                                                                {
                                                                    this.state.InvalidStones.map(view => (
                                                                        <tr key={view._id}>


                                                                            <td>{view.VendorStock}</td>
                                                                            <td>{view.PartyName}</td>
                                                                            <td>{view.Availability}</td>
                                                                            <td>{view.Shape}</td>
                                                                            <td>{view.Weight}</td>
                                                                            <td>{view.Color}</td>
                                                                            <td>{view.Clarity}</td>
                                                                            <td>{view.CutGrade}</td>
                                                                            <td>{view.Polish}</td>
                                                                            <td>{view.Symmetry}</td>
                                                                            <td>{view.FluorescenceIntensity}</td>
                                                                            <td>{view.FluorescenceColor}</td>
                                                                            <td>{view.Measurements}</td>
                                                                            <td>{view.Lab}</td>
                                                                            <td>{view.Certificate}</td>
                                                                            <td>{view.Treatment}</td>
                                                                            <td>{view.Rap}</td>
                                                                            <td>{view.RapAmt}</td>
                                                                            <td>{view.RapnetDiscount}</td>
                                                                            <td>{view.RapNetPrice}</td>
                                                                            <td>{view.NetValue}</td>
                                                                            <td>{view.CashPrice}</td>
                                                                            <td>{view.CashPriceDiscount}</td>
                                                                            <td>{view.FancyColor}</td>
                                                                            <td>{view.FancyColorIntensity}</td>
                                                                            <td>{view.FancyColorOvertone}</td>
                                                                            <td>{view.Depth}</td>
                                                                            <td>{view.Table}</td>
                                                                            <td>{view.GirdleThin}</td>
                                                                            <td>{view.GirdleThick}</td>
                                                                            <td>{view.Girdle}</td>
                                                                            <td>{view.GirdleCondition}</td>
                                                                            <td>{view.CuletSize}</td>
                                                                            <td>{view.CuletCondition}</td>
                                                                            <td>{view.CrownHeighht}</td>
                                                                            <td>{view.CrownAngle}</td>
                                                                            <td>{view.PavilionDepth}</td>
                                                                            <td>{view.PavilionAngle}</td>
                                                                            <td>{view.LaserInscription}</td>
                                                                            <td>{view.Certcomment}</td>
                                                                            <td>{view.Country}</td>
                                                                            <td>{view.State}</td>
                                                                            <td>{view.City}</td>
                                                                            <td>{view.Timetolocation}</td>
                                                                            <td>{view.IsMatchedPairSeparable}</td>
                                                                            <td>{view.PairStock}</td>
                                                                            <td>{view.AllowRapLinkFeed}</td>
                                                                            <td>{view.ParcelStones}</td>
                                                                            <td>{view.CertificateFilename}</td>
                                                                            <td>{view.DiamondImage}</td>
                                                                            <td>{view.SarinLoupe}</td>
                                                                            <td>{view.TardeShow}</td>
                                                                            <td>{view.Keytosymbols}</td>
                                                                            <td>{view.Shade}</td>
                                                                            <td>{view.StarLength}</td>
                                                                            <td>{view.CenterInclusion}</td>
                                                                            <td>{view.BlackInclusion}</td>
                                                                            <td>{view.MemberComment}</td>
                                                                            <td>{view.ReportIssueDate}</td>
                                                                            <td>{view.ReportType}</td>
                                                                            <td>{view.LabLocation}</td>
                                                                            <td>{view.Brand}</td>
                                                                            <td>{view.Milky}</td>
                                                                            <td>{view.PriceFromRapnet}</td>
                                                                            <td>{view.RapnetDiscMarkup}</td>
                                                                            <td>{view.DPerCarat}</td>
                                                                            <td>{view.FinalPrice}</td>
                                                                            <td>{view.InwardType}</td>
                                                                            {/* <td><Link to={`/addInward/${view._id}`}><span style={{ color: "#17a2b8" }}><i className="fa fa-edit"></i></span></Link></td> */}


                                                                        </tr>

                                                                    ))
                                                                }


                                                            </tbody>


                                                        </table>


                                                    }


                                                </ div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        )
    }
}

export default InvalidStones;



import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Axios from 'axios';
import swal from 'sweetalert';
// import { localsAsTemplateData } from 'hbs';

class ResetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: localStorage.getItem('EmailID'),
            newPassword: '',
            reTypePassword: ''
        }
        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }
    handleChange(event) {
        event.preventDefault()
        this.setState({
            [event.target.name]: event.target.value
        })
    }
    handleSubmit(e) {
        e.preventDefault()
     
        Axios.post('/resetpassword', this.state).then((res) => {
          
            if (res.data.msg=="Password Changed Successfully") {
                swal(res.data.msg, '', 'success')
                this.props.history.push('/')
            }
            else{
                swal(res.data.msg, '', 'error')
                this.setState({
                    newPassword:"",
                    reTypePassword:""
                })
            }
        })
    }
    componentDidMount() {

    }
    render() {
        const { newPassword, reTypePassword } = this.state
        return (
            <div>
                <center>
                    <div className="content" style={{ marginTop: "10%" }}>
                        <div className="container-fluid">
                            <div className="login-box">
                                <div className="card card-outline card-info">
                                    <div className="card-header text-center">
                                        <h3><b>Reset&nbsp;</b>Password</h3>
                                    </div>
                                    <div className="card-body">

                                        <form onSubmit={this.handleSubmit}>
                                            <div className="input-group mb-3">
                                                <input type="password" className="form-control" name="newPassword" placeholder="Enter New Password" onChange={this.handleChange} value={newPassword} />
                                                <div className="input-group-append">
                                                    <div className="input-group-text">
                                                        <span className="fas fa-lock"></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="input-group mb-3">
                                                <input type="password" className="form-control" name="reTypePassword" placeholder="Enter Re-Type Password" onChange={this.handleChange} value={reTypePassword} />
                                                <div className="input-group-append">
                                                    <div className="input-group-text">
                                                        <span className="fas fa-lock"></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12">
                                                    <button type="submit" className="btn btn-info btn-block">Reset Password</button>
                                                </div>

                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </center>
            </div>
        );
    }
}


export default ResetPassword;
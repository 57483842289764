import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { withRouter } from 'react-router';
import axios from 'axios';
import swal from 'sweetalert';
import { token } from 'morgan';
class PartyHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      UserName: localStorage.getItem('user'),
      redirect: '',
      token: localStorage.getItem('token'),
    };
    this.logout = this.logout.bind(this);
  }
  logout(e) {
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    this.setState({
      redirect: '/',
    });
  }

  componentDidMount() {
    if (this.state.token == '' || this.state.UserName == '') {
      this.props.history.push('/');
    }
  }

  render() {
    const { UserName } = this.state;
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    return (
      <div>
        <div className="wrapper">
          <nav className="main-header navbar navbar-expand navbar-white navbar-light">
            <ul className="navbar-nav">
              <li className="nav-item">
                <a
                  className="nav-link"
                  data-widget="pushmenu"
                  href="#"
                  role="button"
                >
                  <i className="fa fa-arrow-left"></i>
                </a>
              </li>
            </ul>
            <ul className="navbar-nav ml-auto">
              {/* <li className="nav-item dropdown">
                <a className="nav-link" data-toggle="dropdown" href="#">
                  <i className="far fa-comments"></i>
                  <span className="badge badge-danger navbar-badge">3</span>
                </a>
                <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                  <a href="#" className="dropdown-item">
                    <div className="media">
                      <img
                        src="dist/img/user1-128x128.jpg"
                        alt="User Avatar"
                        className="img-size-50 mr-3 img-circle"
                      />
                      <div className="media-body">
                        <h3 className="dropdown-item-title">
                          Brad Diesel
                          <span className="float-right text-sm text-danger">
                            <i className="fas fa-star"></i>
                          </span>
                        </h3>
                        <p className="text-sm">Call me whenever you can...</p>
                        <p className="text-sm text-muted">
                          <i className="far fa-clock mr-1"></i> 4 Hours Ago
                        </p>
                      </div>
                    </div>
                  </a>
                  <div className="dropdown-divider"></div>
                  <a href="#" className="dropdown-item">
                    <div className="media">
                      <img
                        src="dist/img/user8-128x128.jpg"
                        alt="User Avatar"
                        className="img-size-50 img-circle mr-3"
                      />
                      <div className="media-body">
                        <h3 className="dropdown-item-title">
                          John Pierce
                          <span className="float-right text-sm text-muted">
                            <i className="fas fa-star"></i>
                          </span>
                        </h3>
                        <p className="text-sm">I got your message bro</p>
                        <p className="text-sm text-muted">
                          <i className="far fa-clock mr-1"></i> 4 Hours Ago
                        </p>
                      </div>
                    </div>
                  </a>
                  <div className="dropdown-divider"></div>

                  <div className="dropdown-divider"></div>
                  <a href="#" className="dropdown-item dropdown-footer">
                    See All Messages
                  </a>
                </div>
              </li> */}
              <li className="nav-item">
                <a
                  className="nav-link"
                  data-widget="fullscreen"
                  href="#"
                  role="button"
                >
                  <i className="fas fa-expand-arrows-alt"></i>
                </a>
              </li>
              <li className="nav-item dropdown">
                <a
                  id="dropdownSubMenu1"
                  href="#"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  class="nav-link dropdown-toggle "
                >
                  <b
                    style={{
                      fontFamily: '-webkit-pictograph',
                      fontSize: '15px',
                    }}
                  >
                    {UserName}
                  </b>
                  &nbsp;
                  <img
                    src="./dist/img/UserImage.png"
                    style={{ height: '30px' }}
                  />
                </a>
                <div class="dropdown-menu dropdown-menu-right">
                  <Link onClick={(e) => this.logout(e)}>
                    <i className="fas fa-power"></i>&nbsp;<b>Logout</b>
                  </Link>
                  <button class="dropdown-item" type="button">
                    Another action
                  </button>
                  <button class="dropdown-item" type="button">
                    Something else here
                  </button>
                </div>
              </li>
            </ul>
          </nav>

          <aside className="main-sidebar sidebar-dark-primary elevation-4">
            <Link to="/supplierDashboard" className="brand-link">
              <img
                src="./dist/img/AdminLTELogo.png"
                alt="AdminLTE Logo"
                className="brand-image img-circle elevation-3"
                style={{ opacity: '.8' }}
              />
              <span className="brand-text font-weight-light">
                DIAMOND DEALER
              </span>
            </Link>

            <div className="sidebar">
              {/* <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                            <div className="image">
                            <img src="./dist/img/user2-160x160.jpg" className="img-circle elevation-2" alt="User Image"/>
                            </div>
                            <div className="info">
                            <a href="#" className="d-block">Alexander Pierce</a>
                            </div>
                        </div> */}

              <div className="form-inline"></div>

              <nav className="mt-2">
                <ul
                  className="nav nav-pills nav-sidebar flex-column"
                  data-widget="treeview"
                  role="menu"
                  data-accordion="false"
                >
                  <li className="nav-item">
                    <Link to="/supplierDashboard" className="nav-link">
                      <i className="nav-icon fas fa-columns"></i>
                      <p>Dashboard</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/addInwardSupplier" className="nav-link">
                      <i className="nav-icon fas fa-file-import"></i>
                      <p>Import Files</p>
                    </Link>
                  </li>
                  {/* <li className="nav-item">
                    <Link to="/viewStockSupplier" className="nav-link">
                      <i className="nav-icon fas fa-history"></i>
                      <p>View Stock</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/displayInvalidStones" className="nav-link">
                      <i className="nav-icon fa fa-exclamation-triangle"></i>
                      <p>Invalid Diamonds</p>
                    </Link>
                  </li> */}
                </ul>
              </nav>
            </div>
          </aside>

          <aside className="control-sidebar control-sidebar-dark"></aside>
        </div>
      </div>
    );
  }
}

export default PartyHeader;

import Axios from "axios";
import React, { Component } from "react";
import Header from "../../Headers/Header";
import swal from "sweetalert";
import axios from "axios";
import { Link } from "react-router-dom";

class FormatForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      storedata: [],
      formatname: "",
      viewFormats: [],
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleTextChange = this.handleTextChange.bind(this);
  }
  handleTextChange(e) {
    this.setState({
      formatname: e.target.value,
    });
  }
  handleChange(e) {
    if (e.target.checked) {
      this.state.storedata.push(e.target.value);
    } else {
      this.state.storedata.splice(
        this.state.storedata.indexOf(e.target.value),
        1
      );
    }
    this.state.storedata = [...new Set(this.state.storedata)];
  }
  handleSubmit(e) {
    e.preventDefault();
    Axios.post(`/users/columnformat`, this.state).then((res) => {
      swal("Format Saved", "", "success");
      this.get();
    });
  }
  componentDidMount() {
    axios.get("/users/viewFormats").then((res) => {
      this.setState({
        viewFormats: res.data,
      });

      var script = document.createElement("script");
      script.id = "FormTable";
      script.src = `js/content.js`;
      document.body.appendChild(script);
    });
  }
  get() {
    axios.get("/users/viewFormats").then((res) => {
      this.setState({
        viewFormats: res.data,
      });
    });
  }

  deleteFormat(e, id) {
    e.preventDefault();
    swal({
      title: "Are you sure?",
      text: "You want to delete this record?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        axios.get(`/users/delFormat/${id}`).then((res) => {
          swal("Record Deleted!", "", "success");
          this.get();
        });
      } else {
        swal("Canceled");
      }
    });
  }
  render() {
    const { FormatName } = this.state;
    return (
      <div>
        <Header />
        <div className="wrapper">
          <div className="content-wrapper">
            <section className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-6">
                    <h1>Column Visibility Format</h1>
                  </div>
                  <div className="col-sm-6">
                    <ol className="breadcrumb float-sm-right">
                      <li className="breadcrumb-item">
                        <a href="#" style={{ color: "#17a2b8" }}>
                          Home
                        </a>
                      </li>
                      <li className="breadcrumb-item active">
                        Column visibility Format
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </section>
            <section className="container-fluid">
              <div className="container-fluid">
                <div class="card card-default">
                  <div class="card-header">
                    <h3 class="card-title">Column Visibility Form </h3>

                    <div class="card-tools pr-3">
                      <button
                        type="button"
                        class="btn btn-tool"
                        data-card-widget="collapse"
                      >
                        <i class="fas fa-minus"></i>
                      </button>
                    </div>
                  </div>
                  <div className="card-body">
                    <form onSubmit={this.handleSubmit}>
                      <div className="row">
                        <div className="col-md-3">
                          <input
                            type="text"
                            name="formatname"
                            className="form-control"
                            placeholder="Enter Format Name"
                            value={FormatName}
                            onChange={this.handleTextChange}
                          />
                        </div>
                        <div className="col-md-9 ">
                          <label>
                            <input
                              type="checkbox"
                              value="jsSrNo"
                              onChange={this.handleChange}
                            />
                            <span style={{ margin: "10px" }}>SrNo</span>
                          </label>
                          <label>
                            <input
                              type="checkbox"
                              value="jsStock"
                              onChange={this.handleChange}
                            />
                            <span style={{ margin: "10px" }}>Stock</span>
                          </label>
                          <label>
                            <input
                              type="checkbox"
                              value="jsVendorStock"
                              onChange={this.handleChange}
                            />
                            <span style={{ margin: "10px" }}>VendorStock</span>
                          </label>
                          <label>
                            <input
                              type="checkbox"
                              value="jsVendorName"
                              onChange={this.handleChange}
                            />
                            <span style={{ margin: "10px" }}>VendorName</span>
                          </label>
                          <label>
                            <input
                              type="checkbox"
                              value="jsAvailability"
                              onChange={this.handleChange}
                            />
                            <span style={{ margin: "10px" }}>Availability</span>
                          </label>
                          <label>
                            <input
                              type="checkbox"
                              value="jsShape"
                              onChange={this.handleChange}
                            />
                            <span style={{ margin: "10px" }}>Shape</span>
                          </label>
                          <label>
                            <input
                              type="checkbox"
                              value="jsWeight"
                              onChange={this.handleChange}
                            />
                            <span style={{ margin: "10px" }}>Weight</span>
                          </label>
                          <label>
                            <input
                              type="checkbox"
                              value="jsColor"
                              onChange={this.handleChange}
                            />
                            <span style={{ margin: "10px" }}>Color</span>
                          </label>
                          <label>
                            <input
                              type="checkbox"
                              value="jsClarity"
                              onChange={this.handleChange}
                            />
                            <span style={{ margin: "10px" }}>Clarity</span>
                          </label>
                          <label>
                            <input
                              type="checkbox"
                              value="jsCutGrade"
                              onChange={this.handleChange}
                            />
                            <span style={{ margin: "10px" }}>CutGrade</span>
                          </label>
                          <label>
                            <input
                              type="checkbox"
                              value="jsPolish"
                              onChange={this.handleChange}
                            />
                            <span style={{ margin: "10px" }}>Polish</span>
                          </label>
                          <label>
                            <input
                              type="checkbox"
                              value="jsSymmetry"
                              onChange={this.handleChange}
                            />
                            <span style={{ margin: "10px" }}>Symmentry</span>
                          </label>
                          <label>
                            <input
                              type="checkbox"
                              value="jsFluorescenceIntensity"
                              onChange={this.handleChange}
                            />
                            <span style={{ margin: "10px" }}>
                              FluorescenceIntensity
                            </span>
                          </label>
                          <label>
                            <input
                              type="checkbox"
                              value="jsFluorescenceColor"
                              onChange={this.handleChange}
                            />
                            <span style={{ margin: "10px" }}>
                              FluorescenceColor
                            </span>
                          </label>
                          <label>
                            <input
                              type="checkbox"
                              value="jsMeasurements"
                              onChange={this.handleChange}
                            />
                            <span style={{ margin: "10px" }}>Measurements</span>
                          </label>
                          <label>
                            <input
                              type="checkbox"
                              value="jsLab"
                              onChange={this.handleChange}
                            />
                            <span style={{ margin: "10px" }}>Lab</span>
                          </label>
                          <label>
                            <input
                              type="checkbox"
                              value="jsCertificate"
                              onChange={this.handleChange}
                            />
                            <span style={{ margin: "10px" }}>Certificate</span>
                          </label>
                          <label>
                            <input
                              type="checkbox"
                              value="jsTreatment"
                              onChange={this.handleChange}
                            />
                            <span style={{ margin: "10px" }}>Treatment</span>
                          </label>
                          <label>
                            <input
                              type="checkbox"
                              value="jsRap"
                              onChange={this.handleChange}
                            />
                            <span style={{ margin: "10px" }}>Rap</span>
                          </label>

                          <label>
                            <input
                              type="checkbox"
                              value="jsVendorDiscount"
                              onChange={this.handleChange}
                            />
                            <span style={{ margin: "10px" }}>
                              VendorDiscount
                            </span>
                          </label>
                          <label>
                            <input
                              type="checkbox"
                              value="jsRapnetDiscount"
                              onChange={this.handleChange}
                            />
                            <span style={{ margin: "10px" }}>
                              RapnetDiscount
                            </span>
                          </label>
                          <label>
                            <input
                              type="checkbox"
                              value="jsRapNetPrice"
                              onChange={this.handleChange}
                            />
                            <span style={{ margin: "10px" }}>RapNetPrice</span>
                          </label>
                          <label>
                            <input
                              type="checkbox"
                              value="jsNetValue"
                              onChange={this.handleChange}
                            />
                            <span style={{ margin: "10px" }}>NetValue</span>
                          </label>
                          <label>
                            <input
                              type="checkbox"
                              value="jsCashPrice"
                              onChange={this.handleChange}
                            />
                            <span style={{ margin: "10px" }}>CashPrice</span>
                          </label>
                          <label>
                            <input
                              type="checkbox"
                              value="jsCashPriceDiscount"
                              onChange={this.handleChange}
                            />
                            <span style={{ margin: "10px" }}>
                              CashPriceDiscount
                            </span>
                          </label>
                          <label>
                            <input
                              type="checkbox"
                              value="jsFancyColor"
                              onChange={this.handleChange}
                            />
                            <span style={{ margin: "10px" }}>FancyColor</span>
                          </label>
                          <label>
                            <input
                              type="checkbox"
                              value="jsFancyColorIntensity"
                              onChange={this.handleChange}
                            />
                            <span style={{ margin: "10px" }}>
                              FancyColorIntensity
                            </span>
                          </label>
                          <label>
                            <input
                              type="checkbox"
                              value="jsFancyColorOvertone"
                              onChange={this.handleChange}
                            />
                            <span style={{ margin: "10px" }}>
                              FancyColorOvertone
                            </span>
                          </label>
                          <label>
                            <input
                              type="checkbox"
                              value="jsDepth"
                              onChange={this.handleChange}
                            />
                            <span style={{ margin: "10px" }}>Depth</span>
                          </label>
                          <label>
                            <input
                              type="checkbox"
                              value="jsTable"
                              onChange={this.handleChange}
                            />
                            <span style={{ margin: "10px" }}>Table</span>
                          </label>
                          <label>
                            <input
                              type="checkbox"
                              value="jsGirdleThin"
                              onChange={this.handleChange}
                            />
                            <span style={{ margin: "10px" }}>GirdleThin</span>
                          </label>
                          <label>
                            <input
                              type="checkbox"
                              value="jsGirdleThick"
                              onChange={this.handleChange}
                            />
                            <span style={{ margin: "10px" }}>GirdleThick</span>
                          </label>
                          <label>
                            <input
                              type="checkbox"
                              value="jsGirdle"
                              onChange={this.handleChange}
                            />
                            <span style={{ margin: "10px" }}>Girdle</span>
                          </label>
                          <label>
                            <input
                              type="checkbox"
                              value="jsGirdleCondition"
                              onChange={this.handleChange}
                            />
                            <span style={{ margin: "10px" }}>
                              GirdleCondition
                            </span>
                          </label>
                          <label>
                            <input
                              type="checkbox"
                              value="jsCuletSize"
                              onChange={this.handleChange}
                            />
                            <span style={{ margin: "10px" }}>CuletSize</span>
                          </label>
                          <label>
                            <input
                              type="checkbox"
                              value="jsCuletCondition"
                              onChange={this.handleChange}
                            />
                            <span style={{ margin: "10px" }}>
                              CuletCondition
                            </span>
                          </label>
                          <label>
                            <input
                              type="checkbox"
                              value="jsCrownHeight"
                              onChange={this.handleChange}
                            />
                            <span style={{ margin: "10px" }}>CrownHeight</span>
                          </label>
                          <label>
                            <input
                              type="checkbox"
                              value="jsCrownAngle"
                              onChange={this.handleChange}
                            />
                            <span style={{ margin: "10px" }}>CrownAngle</span>
                          </label>
                          <label>
                            <input
                              type="checkbox"
                              value="jsPavilionDepth"
                              onChange={this.handleChange}
                            />
                            <span style={{ margin: "10px" }}>
                              PavilionDepth
                            </span>
                          </label>
                          <label>
                            <input
                              type="checkbox"
                              value="jsPavilionAngle"
                              onChange={this.handleChange}
                            />
                            <span style={{ margin: "10px" }}>
                              PavilionAngle
                            </span>
                          </label>
                          <label>
                            <input
                              type="checkbox"
                              value="jsLaserInscription"
                              onChange={this.handleChange}
                            />
                            <span style={{ margin: "10px" }}>
                              LaserInscription
                            </span>
                          </label>
                          <label>
                            <input
                              type="checkbox"
                              value="jsCertcomment"
                              onChange={this.handleChange}
                            />
                            <span style={{ margin: "10px" }}>CertComment</span>
                          </label>
                          <label>
                            <input
                              type="checkbox"
                              value="jsCountry"
                              onChange={this.handleChange}
                            />
                            <span style={{ margin: "10px" }}>Country</span>
                          </label>
                          <label>
                            <input
                              type="checkbox"
                              value="jsState"
                              onChange={this.handleChange}
                            />
                            <span style={{ margin: "10px" }}>State</span>
                          </label>
                          <label>
                            <input
                              type="checkbox"
                              value="jsCity"
                              onChange={this.handleChange}
                            />
                            <span style={{ margin: "10px" }}>City</span>
                          </label>
                          <label>
                            <input
                              type="checkbox"
                              value="jsTimetolocation"
                              onChange={this.handleChange}
                            />
                            <span style={{ margin: "10px" }}>
                              Timetolocation
                            </span>
                          </label>
                          <label>
                            <input
                              type="checkbox"
                              value="jsIsMatchedPairSeparable"
                              onChange={this.handleChange}
                            />
                            <span style={{ margin: "10px" }}>
                              IsMatchedPairSeparable
                            </span>
                          </label>
                          <label>
                            <input
                              type="checkbox"
                              value="jsPairStock"
                              onChange={this.handleChange}
                            />
                            <span style={{ margin: "10px" }}>PairStock</span>
                          </label>
                          <label>
                            <input
                              type="checkbox"
                              value="jsAllowRapLinkFeed"
                              onChange={this.handleChange}
                            />
                            <span style={{ margin: "10px" }}>
                              AllowRapLinkFeed
                            </span>
                          </label>
                          <label>
                            <input
                              type="checkbox"
                              value="jsParcelStones"
                              onChange={this.handleChange}
                            />
                            <span style={{ margin: "10px" }}>ParcelStones</span>
                          </label>
                          <label>
                            <input
                              type="checkbox"
                              value="jscertificateFilename"
                              onChange={this.handleChange}
                            />
                            <span style={{ margin: "10px" }}>
                              CertificateFilename
                            </span>
                          </label>
                          <label>
                            <input
                              type="checkbox"
                              value="jsDiamondImage"
                              onChange={this.handleChange}
                            />
                            <span style={{ margin: "10px" }}>DiamondImage</span>
                          </label>
                          <label>
                            <input
                              type="checkbox"
                              value="jsSarinLoupe"
                              onChange={this.handleChange}
                            />
                            <span style={{ margin: "10px" }}>SarinLoupe</span>
                          </label>
                          <label>
                            <input
                              type="checkbox"
                              value="jsTradeShow"
                              onChange={this.handleChange}
                            />
                            <span style={{ margin: "10px" }}>TradeShow</span>
                          </label>
                          <label>
                            <input
                              type="checkbox"
                              value="jsKeytosymbols"
                              onChange={this.handleChange}
                            />
                            <span style={{ margin: "10px" }}>Keytosymbols</span>
                          </label>
                          <label>
                            <input
                              type="checkbox"
                              value="jsShade"
                              onChange={this.handleChange}
                            />
                            <span style={{ margin: "10px" }}>Shade</span>
                          </label>
                          <label>
                            <input
                              type="checkbox"
                              value="jsStarLength"
                              onChange={this.handleChange}
                            />
                            <span style={{ margin: "10px" }}>StarLength</span>
                          </label>
                          <label>
                            <input
                              type="checkbox"
                              value="jsCenterInclusion"
                              onChange={this.handleChange}
                            />
                            <span style={{ margin: "10px" }}>
                              CenterInclusion
                            </span>
                          </label>
                          <label>
                            <input
                              type="checkbox"
                              value="jsBlackInclusion"
                              onChange={this.handleChange}
                            />
                            <span style={{ margin: "10px" }}>
                              BlackInclusion
                            </span>
                          </label>
                          <label>
                            <input
                              type="checkbox"
                              value="jsMemberComment"
                              onChange={this.handleChange}
                            />
                            <span style={{ margin: "10px" }}>
                              MemberComment
                            </span>
                          </label>
                          <label>
                            <input
                              type="checkbox"
                              value="jsReportIssueDate"
                              onChange={this.handleChange}
                            />
                            <span style={{ margin: "10px" }}>
                              ReportIssueDate
                            </span>
                          </label>
                          <label>
                            <input
                              type="checkbox"
                              value="jsReportType"
                              onChange={this.handleChange}
                            />
                            <span style={{ margin: "10px" }}>ReportType</span>
                          </label>
                          <label>
                            <input
                              type="checkbox"
                              value="jsLabLocation"
                              onChange={this.handleChange}
                            />
                            <span style={{ margin: "10px" }}>LabLocation</span>
                          </label>
                          <label>
                            <input
                              type="checkbox"
                              value="jsBrand"
                              onChange={this.handleChange}
                            />
                            <span style={{ margin: "10px" }}>Brand</span>
                          </label>
                          <label>
                            <input
                              type="checkbox"
                              value="jsMilky"
                              onChange={this.handleChange}
                            />
                            <span style={{ margin: "10px" }}>Milky</span>
                          </label>
                        </div>
                      </div>
                      <div className="pt-3">
                        <center>
                          <button type="submit" className="btn btn-info">
                            Save
                          </button>
                        </center>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </section>
            <div className="card" style={{ margin: "10px 15px" }}>
              <div className="card-header">
                <h3 className="card-title">Format Details</h3>
              </div>

              <div id="roundDiv" className="card-body">
                <div className="table-responsive">
                  <table
                    id="example1"
                    className="table1 jsDataTable table table-bordered table-striped"
                  >
                    <thead>
                      <tr>
                        <th>Format Name</th>
                        <th>Fields</th>
                        <th>Delete</th>
                        <th>Edit</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.viewFormats.map((data) => (
                        <tr>
                          <td>{data.FormatName}</td>
                          <td>
                            {data.FormatFields &&
                              data.FormatFields.split("js").join("  ")}
                          </td>
                          <td>
                            <Link
                              onClick={(e) => this.deleteFormat(e, data._id)}
                            >
                              <i
                                className="fa fa-trash"
                                style={{ color: "#17a2b8" }}
                              ></i>
                            </Link>
                          </td>
                          <td>
                            <Link to={`/updateFormat/${data._id}`}>
                              <span style={{ color: "#17a2b8" }}>
                                <i className="fa fa-edit"></i>
                              </span>
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FormatForm;

import { CloseOutlined, MenuOpen } from '@material-ui/icons'
import { AiOutlineMenu } from 'react-icons/ai'
import { CloseButton } from 'react-bootstrap'
import { Button } from 'react-bootstrap'
// import sdLogo from '../../views/img/sd-logo-1.jpg'
import sdLogo from '../../views/img/sd-log-2.jpg'
export const Navigation = props => {
  return (
    <nav id='menu' className='navbar navbar-default navbar-fixed-top'>
      <div className='container d-flex'>
        <div className='navbar-header'>
          <a className='navbar-brand page-scroll' href='#page-top'>
            <img src={sdLogo} width="240" height="100%" class='navbar-brand page-scroll' />
          </a>
        </div>
        <Button
          type='button'
          className='navbar-toggle collapsed d-xl-none d-sm-none'
          data-toggle='collapse'
          data-target='#bs-example-navbar-collapse-1'
        >
          <AiOutlineMenu />
          <span className='sr-only'>Toggle navigation</span>{' '}
          <span className='icon-bar'></span> <span className='icon-bar'></span>{' '}
          <span className='icon-bar'></span>{' '}
        </Button>
        <ul className='nav navbar-right d-custom-nav d-none d-md-flex d-md-flex'>
          <li>
            <a href='#features' className='page-scroll'>
              Features
            </a>
          </li>
          <li>
            <a href='#about' className='page-scroll'>
              About
            </a>
          </li>
          <li>
            <a href='#services' className='page-scroll'>
              Services
            </a>
          </li>
          <li>
            <a href='#portfolio' className='page-scroll'>
              Gallery
            </a>
          </li>
          <li>
            <a href='#testimonials' className='page-scroll'>
              Testimonials
            </a>
          </li>
          <li>
            <a href='#team' className='page-scroll'>
              Team
            </a>
          </li>
          <li>
            <a href='#contact' className='page-scroll'>
              Contact
            </a>
          </li>
        </ul>
        <div
          className='collapse navbar-collapse'
          id='bs-example-navbar-collapse-1'
        >
          <ul className='nav navbar-nav navbar-right'>
            <li>
              <a href='#features' className='page-scroll'>
                Features
              </a>
            </li>
            <li>
              <a href='#about' className='page-scroll'>
                About
              </a>
            </li>
            <li>
              <a href='#services' className='page-scroll'>
                Services
              </a>
            </li>
            <li>
              <a href='#portfolio' className='page-scroll'>
                Gallery
              </a>
            </li>
            <li>
              <a href='#testimonials' className='page-scroll'>
                Testimonials
              </a>
            </li>
            <li>
              <a href='#team' className='page-scroll'>
                Team
              </a>
            </li>
            <li>
              <a href='#contact' className='page-scroll'>
                Contact
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}

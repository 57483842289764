import Axios from 'axios';
import $ from 'jquery';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
import Header from '../../Headers/Header';

class AddParty extends Component {
  constructor(props) {
    super(props);
    this.state = {
      partyName: '',
      DOB: '',
      UserName: '',
      DisplayName: '',
      Designation: '',
      Password: '',
      UserBusiness: '',
      SkypeID: '',
      QQID: '',
      EmailID: '',
      WeChatID: '',
      CompanyName: '',
      Address1: '',
      Address2: '',
      Address3: '',
      Country: '',
      State: '',
      City: '',
      PinCode: '',
      STD_Code: '',
      ISD_Code: '',
      Mobile1: '',
      Mobile2: '',
      Phone1: '',
      Phone2: '',
      CompanyEmailID: '',
      FaxNo: '',
      CompanyType: '',
      Website: '',
      Assoc1: '',
      Assoc2: '',
      Comment: '',
      Reference: '',
      DImagePath: '',
      DPhotoType: '',
      Narration: '',
      CPhotoType: '',
      CNarration: '',
      CImagePath: '',

      MappingColumn: [],
      FileHeader: [],
      IsStock: '',
      County1: [],
      State1: [],
      City1: [],
      Country2: [],
      State2: [],
      City2: [],
      mappingpage: '',
      data: [],
      pushdata: [],
      Mapping: [],
      FileHeaderdata: [],
      formula: [],
      textformula: [],
      discount: true,
      WhiteMarkup: 0,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.viewcolumn = this.viewcolumn.bind(this);
    this.UploadSecondFile = this.UploadSecondFile.bind(this);
    this.ViewColumnChange = this.ViewColumnChange.bind(this);
    this.handletextChange = this.handletextChange.bind(this);
    this.handleChangeFormula = this.handleChangeFormula.bind(this);
    this.resetDropDown = this.resetDropDown.bind(this);
  }

  resetDropDown(e, view) {
    $('#' + e.target.id).prop('selectedIndex', 0);
    this.state.pushdata[e.target.id] = null;
  }
  handletextChange(e) {
    var values = [];
    var val1 = $("input[name='textformula[]']").each(function (i, sel) {
      var selectedVal = $(sel).val();
      values.push(selectedVal);
    });
    this.setState({
      textformula: values,
    });
  }

  handleChangeFormula(e) {
    // if (e.target.checked) {
    //     this.state.formula.push(e.target.value)
    // }
    // else {
    //     this.state.formula.splice(e.target.value, 1)
    // }

    var array1 = [];
    $('input:checkbox:checked').each(function () {
      array1.push($(this).val());
    });

    this.setState({
      Formula: array1,
    });
  }

  viewcolumn(event) {
    event.preventDefault();
    const formData = new FormData();
    formData.append(`mappingpage`, this.state.mappingpage);

    Axios.post('/mappingpage', formData).then((res) => {
      this.setState({
        data: res.data,
        FileHeader: res.data,
      });
    });
  }

  UploadSecondFile(event) {
    this.setState({
      mappingpage: event.target.files[0],
    });
  }
  ViewColumnChange(event) {
    var values = [];
    var val1 = $("select[name='min_select[]']").each(function (i, sel) {
      var selectedVal = $(sel).val();
      values.push(selectedVal);
    });
    this.state.pushdata = values;
    // this.setState({
    //     pushdata: values
    // })
  }


  // handleChange(e) {
  //   this.setState({
  //     [e.target.name]: e.target.value,
  //   });
  // }

  // New handle change function
  handleChange(e) {
    const { name, type, checked } = e.target; // Destructure event target properties
    this.setState({
      [name]: type === 'checkbox' ? checked : e.target.value // Set state based on type
    });
  }


  // handleSubmit(e){
  //     e.preventDefault()

  //     Axios.post('/users/partyInsert',this.state).then(res=>{

  //         swal('Party Inserted','','success')
  //     })

  // }
  handleSubmit(e) {
    e.preventDefault();

    if (this.props.match.params.id == null) {
      Axios.post(`/users/partyInsert`, this.state).then((res) => {
        if (res.data.Status === 'DONE') {
          swal('Supplier Inserted', {
            button: false,
            timer: 1500,
            icon: 'success',
          });
        } else {
          swal('Supplier Already Exists', {
            button: false,
            timer: 1500,
            icon: 'error',
          });
        }
      });
    } else {
      Axios.post(
        `/users/update/${this.props.match.params.id}`,
        this.state
      ).then((res) => {
        swal('Update Supplier Successfully', {
          button: false,
          timer: 1500,
          icon: 'success',
        });
        this.props.history.push('/ViewParty');
      });
    }
  }

  handleDelete(e, id) {
    e.preventDefault();
    swal({
      title: 'Are you sure?',
      text: 'Once deleted, you will not be able to recover this imaginary file!',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        Axios.get(`/users/delete/${id}`).then((res) => {
          swal('Deleted Successfully', '', 'success');
        });
        this.componentDidMount();
      } else {
        swal('Canceled');
      }
    });
  }

  // componentDidMount() {
  //     // axios.get('/getCountries').then(res => {
  //     //     this.setState({
  //     //         County1: res.data,
  //     //         Country2: res.data,
  //     //         State1: [],
  //     //         City1: [],
  //     //         State2: [],
  //     //         City2: []
  //     //     })

  //     // })

  // }
  componentDidMount() {
    Axios.get(`/users/update/${this.props.match.params.id}`).then((res) => {
      let party = res.data.updatedata;
      console.log('-------------PARTY----------------');
      console.log(party);
      this.setState({
        partyName: party.partyName,
        DisplayName: party.DisplayName,
        UserName: party.UserName,
        DOB: party.DOB,
        Designation: party.Designation,
        Markup: party.Markup,
        FancyMarkup: party.FancyMarkup,
        UserBusiness: party.UserBusiness,
        SkypeID: party.SkypeID,
        QQID: party.QQID,
        WeChatID: party.WeChatID,
        EmailID: party.EmailID,
        CompanyName: party.CompanyName,
        Address1: party.Address1,
        Address2: party.Address2,
        Address3: party.Address3,
        PinCode: party.PinCode,
        STD_Code: party.STD_Code,
        ISD_Code: party.ISD_Code,
        Mobile1: party.Mobile1,
        Mobile2: party.Mobile2,
        Phone1: party.Phone1,
        Phone2: party.Phone2,
        CompanyEmailID: party.CompanyEmailID,
        FaxNo: party.FaxNo,
        CompanyType: party.CompanyType,
        Website: party.Website,
        Assoc1: party.Assoc1,
        Assoc2: party.Assoc2,
        Comment: party.Comment,
        Reference: party.Reference,
        Narration: party.Narration,
        MappingColumn: party.MappingColumn,
        FileHeader: party.FileHeader,
        Formula: party.Formula,
        inwardType: party.inwardType,
        discount: party.discount,
        WhiteMarkup: party.WhiteMarkup,
      });

      const Mappingdata = this.state.MappingColumn.toString().split(',');
      const fileheaderdata = this.state.FileHeader.toString().split(',');

      this.setState({
        pushdata: Mappingdata,
        FileHeaderdata: fileheaderdata,
        //Formula: this.state.Formula
      });

      var array1 = [];

      if (party.Formula) {
        party.Formula.forEach((data) => {
          $("input[type=checkbox][value='" + data + "']").prop('checked', true);
        });
        $('input:checkbox:checked').each(function () {
          array1.push($(this).val());
        });

        this.setState({
          FormData: array1,
        });
        console.log('---------------FORM DATAAAA------------------');
        console.log(this.state.FormData);
      }
    });
  }

  render() {
    const {
      errors,
      partyName,
      DOB,
      UserName,
      DisplayName,
      Designation,
      Password,
      UserBusiness,
      SkypeID,
      QQID,
      EmailID,
      WeChatID,
      CompanyName,
      Address1,
      Address2,
      Address3,
      City,
      PinCode,
      STD_Code,
      ISD_Code,
      Mobile1,
      Mobile2,
      Phone1,
      Phone2,
      CompanyEmailID,
      FaxNo,
      CompanyType,
      Website,
      Assoc1,
      Assoc2,
      Comment,
      Reference,
      DPhotoType,
      Narration,
      CPhotoType,
      CNarration,
      TermCondition,
      Country,
      State,
      City1,
      inwardType,
      Markup,
      FancyMarkup,
      discount,
      WhiteMarkup
    } = this.state;
    return (
      <div>
        <Header />
        <div className="wrapper">
          <div className="content-wrapper">
            <section className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-6">
                    <h1>Add Supplier</h1>
                  </div>
                  <div className="col-sm-6">
                    <ol className="breadcrumb float-sm-right">
                      <li className="breadcrumb-item">
                        <a href="#" style={{ color: "#17a2b8" }}>
                          Home
                        </a>
                      </li>
                      <li className="breadcrumb-item active">Add Supplier</li>
                    </ol>
                  </div>
                </div>
              </div>
            </section>
            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <Link to="/ViewParty" className="btn btn-info">
                      <i class="fas fa-chevron-left"></i>&nbsp; Back
                    </Link>
                    <form onSubmit={this.handleSubmit}>
                      <div className="card">
                        <div className="card-header">
                          <h3 className="card-title">
                            {this.props.match.params.id ? "Update" : "Add"}{" "}
                            Details
                          </h3>
                          <div className="row" style={{ float: "right" }}>
                            <div className="col-md-12">
                              <div style={{ display: "inline-flex", marginRight: "10px" }}>
                                <label>
                                  Apply Discount:
                                  <input
                                    type="checkbox"
                                    name="discount"
                                    checked={discount}
                                    onChange={this.handleChange}
                                    style={{width: "30px"}}
                                  />
                                </label>
                              </div>
                              <div style={{ display: "inline-flex" }}>
                                <label style={{ marginTop: "5px" }}>
                                  % Markup
                                </label>
                                <input
                                  type="text"
                                  name="Markup"
                                  value={Markup}
                                  style={{ width: "30%" }}
                                  onChange={this.handleChange}
                                  className="form-control ml-2 markup"
                                />
                              </div>
                              <div style={{ display: "inline-flex" }}>
                                <label style={{ marginTop: "5px" }}>
                                  White Markup
                                </label>
                                <input
                                  type="text"
                                  name="WhiteMarkup"
                                  value={WhiteMarkup}
                                  onChange={this.handleChange}
                                  className="form-control ml-2 markup"
                                />
                              </div>
                              <div style={{ display: "inline-flex" }}>
                                <label style={{ marginTop: "5px" }}>
                                  Fancy Markup
                                </label>
                                <input
                                  type="text"
                                  name="FancyMarkup"
                                  value={FancyMarkup}
                                  onChange={this.handleChange}
                                  className="form-control ml-2 markup"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="card-body">
                          <div className="row">
                            <div className="col-md-4">
                              <div className="row marginn">
                                <div className="col-md-4">
                                  <label>SupplierName*</label>
                                </div>
                                <div className="col-md-8">
                                  <input
                                    type="text"
                                    className="form-control "
                                    name="partyName"
                                    value={partyName}
                                    onChange={this.handleChange}
                                    required
                                  />
                                </div>
                              </div>
                              <div className="row marginn">
                                <div className="col-md-4">
                                  <label>User Name*</label>
                                </div>
                                <div className="col-md-8">
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="UserName"
                                    value={UserName}
                                    onChange={this.handleChange}
                                    required
                                  />
                                </div>
                              </div>
                              <div className="row marginn">
                                <div className="col-md-4">
                                  <label>Display Name</label>
                                </div>
                                <div className="col-md-8">
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="DisplayName"
                                    value={DisplayName}
                                    onChange={this.handleChange}
                                    required
                                  />
                                </div>
                              </div>
                              <div className="row marginn">
                                <div className="col-md-4">
                                  <label>Designation</label>
                                </div>
                                <div className="col-md-8">
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="Designation"
                                    value={Designation}
                                    onChange={this.handleChange}
                                  />
                                </div>
                              </div>

                              <div className="row marginn">
                                <div className="col-md-4">
                                  <label>Date of Birth*</label>
                                </div>
                                <div className="col-md-8">
                                  <input
                                    type="date"
                                    className="form-control"
                                    name="DOB"
                                    value={DOB}
                                    onChange={this.handleChange}
                                  />
                                </div>
                              </div>
                              <div className="row marginn">
                                <div className="col-md-4">
                                  <label>Email Address</label>
                                </div>
                                <div className="col-md-8">
                                  <input
                                    type="email"
                                    className="form-control"
                                    name="EmailID"
                                    value={EmailID}
                                    onChange={this.handleChange}
                                    required
                                  />
                                </div>
                              </div>
                              {this.props.match.params.id ? (
                                ""
                              ) : (
                                <div className="row marginn">
                                  <div className="col-md-4">
                                    <label>Password</label>
                                  </div>
                                  <div className="col-md-8">
                                    <input
                                      type="password"
                                      className="form-control"
                                      name="Password"
                                      value={Password}
                                      onChange={this.handleChange}
                                      required
                                    />
                                  </div>
                                </div>
                              )}
                              <div className="row marginn">
                                <div className="col-md-4">
                                  <label>User Business</label>
                                </div>
                                <div className="col-md-8">
                                  <select
                                    className="form-control"
                                    name="UserBusiness"
                                    value={UserBusiness}
                                    onChange={this.handleChange}
                                  >
                                    <option
                                      defaultValue
                                      disabled="true"
                                      selected="true"
                                    >
                                      Select User Business
                                    </option>
                                    <option>Wholeseller</option>
                                    <option>End User</option>
                                    <option>Jewellery MFG</option>
                                    <option>Retailer</option>
                                    <option>Polished Distributer</option>
                                    <option>Other</option>
                                  </select>
                                </div>
                              </div>
                              <div className="row marginn">
                                <div className="col-md-4">
                                  <label>User Inward Type</label>
                                </div>
                                <div className="col-md-8">
                                  <select
                                    className="form-control"
                                    name="inwardType"
                                    value={inwardType}
                                    onChange={this.handleChange}
                                  >
                                    <option
                                      defaultValue
                                      disabled="true"
                                      selected="true"
                                    >
                                      Select Inward Type
                                    </option>
                                    <option>Natural</option>
                                    <option>Labgrown</option>
                                  </select>
                                </div>
                              </div>
                              <div className="row marginn">
                                <div className="col-md-4">
                                  <label>Company Name</label>
                                </div>
                                <div className="col-md-8">
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="CompanyName"
                                    value={CompanyName}
                                    onChange={this.handleChange}
                                    required
                                  />
                                </div>
                              </div>
                              <div className="row marginn">
                                <div className="col-md-4">
                                  <label>Skype</label>
                                </div>
                                <div className="col-md-8">
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="SkypeID"
                                    value={SkypeID}
                                    onChange={this.handleChange}
                                  />
                                </div>
                              </div>
                              <div className="row marginn">
                                <div className="col-md-4">
                                  <label>QQID</label>
                                </div>
                                <div className="col-md-8">
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="QQID"
                                    value={QQID}
                                    onChange={this.handleChange}
                                  />
                                </div>
                              </div>
                              <div className="row marginn">
                                <div className="col-md-4">
                                  <label>Wechat</label>
                                </div>
                                <div className="col-md-8">
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="WeChatID"
                                    value={WeChatID}
                                    onChange={this.handleChange}
                                  />
                                </div>
                              </div>

                              <div className="row marginn">
                                <div className="col-md-4">
                                  <label>Address1</label>
                                </div>
                                <div className="col-md-8">
                                  <textarea
                                    className="form-control"
                                    name="Address1"
                                    value={Address1}
                                    onChange={this.handleChange}
                                    required
                                  ></textarea>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="row ">
                                <div className="col-md-4">
                                  <label>Address2</label>
                                </div>
                                <div className="col-md-8">
                                  <textarea
                                    className="form-control"
                                    name="Address2"
                                    value={Address2}
                                    onChange={this.handleChange}
                                  ></textarea>
                                </div>
                              </div>
                              <div className="row marginn">
                                <div className="col-md-4">
                                  <label>Address3</label>
                                </div>
                                <div className="col-md-8">
                                  <textarea
                                    className="form-control"
                                    name="Address3"
                                    value={Address3}
                                    onChange={this.handleChange}
                                  ></textarea>
                                </div>
                              </div>

                              <div className="row marginn">
                                <div className="col-md-4">
                                  <label>Country</label>
                                </div>
                                <div className="col-md-8">
                                  <input
                                    type="text"
                                    name="Country"
                                    className="form-control"
                                    value={Country}
                                    onChange={this.handleChange}
                                  />
                                </div>
                              </div>
                              <div className="row marginn">
                                <div className="col-md-4">
                                  <label>State</label>
                                </div>
                                <div className="col-md-8">
                                  <input
                                    type="text"
                                    name="State"
                                    onChange={this.handleChange}
                                    value={State}
                                    className="form-control"
                                  />
                                </div>
                              </div>
                              <div className="row marginn">
                                <div className="col-md-4">
                                  <label>City</label>
                                </div>
                                <div className="col-md-8">
                                  <input
                                    type="text"
                                    name="City"
                                    onChange={this.handleChange}
                                    value={City}
                                    className="form-control"
                                  />
                                </div>
                              </div>
                              <div className="row marginn">
                                <div className="col-md-4">
                                  <label>Pincode</label>
                                </div>
                                <div className="col-md-8">
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="PinCode"
                                    value={PinCode}
                                    onChange={this.handleChange}
                                  />
                                </div>
                              </div>
                              <div className="row marginn">
                                <div className="col-md-4">
                                  <label>STD Code</label>
                                </div>
                                <div className="col-md-8">
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="STD_Code"
                                    value={STD_Code}
                                    onChange={this.handleChange}
                                  />
                                </div>
                              </div>
                              <div className="row marginn">
                                <div className="col-md-4">
                                  <label>ISD Code</label>
                                </div>
                                <div className="col-md-8">
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="ISD_Code"
                                    value={ISD_Code}
                                    onChange={this.handleChange}
                                  />
                                </div>
                              </div>
                              <div className="row marginn">
                                <div className="col-md-4">
                                  <label>Mobile No1</label>
                                </div>
                                <div className="col-md-8">
                                  <input
                                    type="tel"
                                    className="form-control"
                                    name="Mobile1"
                                    value={Mobile1}
                                    onChange={this.handleChange}
                                    maxLength="10"
                                  />
                                </div>
                              </div>
                              <div className="row marginn">
                                <div className="col-md-4">
                                  <label>Mobile No2</label>
                                </div>
                                <div className="col-md-8">
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="Mobile2"
                                    value={Mobile2}
                                    onChange={this.handleChange}
                                  />
                                </div>
                              </div>
                              <div className="row marginn">
                                <div className="col-md-4">
                                  <label>Phone1</label>
                                </div>
                                <div className="col-md-8">
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="Phone1"
                                    value={Phone1}
                                    onChange={this.handleChange}
                                  />
                                </div>
                              </div>
                              <div className="row marginn">
                                <div className="col-md-4">
                                  <label>Phone2</label>
                                </div>
                                <div className="col-md-8">
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="Phone2"
                                    value={Phone2}
                                    onChange={this.handleChange}
                                  />
                                </div>
                              </div>
                              <div className="row marginn">
                                <div className="col-md-4">
                                  <label>Company EmailID</label>
                                </div>
                                <div className="col-md-8">
                                  <input
                                    type="email"
                                    className="form-control"
                                    name="CompanyEmailID"
                                    value={CompanyEmailID}
                                    onChange={this.handleChange}
                                    required
                                  />
                                </div>
                              </div>
                              <div className="row marginn">
                                <div className="col-md-4">
                                  <label>Fax No</label>
                                </div>
                                <div className="col-md-8">
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="FaxNo"
                                    value={FaxNo}
                                    onChange={this.handleChange}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="col-md-4">
                              <div className="row">
                                <div className="col-md-4">
                                  <label>Company Type</label>
                                </div>
                                <div className="col-md-8">
                                  <select
                                    className="form-control"
                                    name="CompanyType"
                                    value={CompanyType}
                                    onChange={this.handleChange}
                                  >
                                    <option defaultValue>
                                      Select Company Type
                                    </option>
                                    <option>Individual</option>
                                    <option>Partnership</option>
                                    <option>Private Limited/Limited</option>
                                    <option>Proprieter</option>
                                  </select>
                                </div>
                              </div>
                              <div className="row marginn">
                                <div className="col-md-4">
                                  <label>Website</label>
                                </div>
                                <div className="col-md-8">
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="Website"
                                    value={Website}
                                    onChange={this.handleChange}
                                  />
                                </div>
                              </div>
                              <div className="row highlight-content">
                                <span>
                                  Member of any Association/Trading network?
                                </span>
                              </div>
                              <div className="row marginn">
                                <div className="col-md-4">
                                  <label>1</label>
                                </div>
                                <div className="col-md-8">
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="Assoc1"
                                    value={Assoc1}
                                    onChange={this.handleChange}
                                  />
                                </div>
                              </div>
                              <div className="row marginn">
                                <div className="col-md-4">
                                  <label>2</label>
                                </div>
                                <div className="col-md-8">
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="Assoc2"
                                    value={Assoc2}
                                    onChange={this.handleChange}
                                  />
                                </div>
                              </div>
                              <div className="row marginn">
                                <div className="col-md-4">
                                  <label>Comment</label>
                                </div>
                                <div className="col-md-8">
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="Comment"
                                    value={Comment}
                                    onChange={this.handleChange}
                                  />
                                </div>
                              </div>
                              <div className="row highlight-content marginn">
                                <span>
                                  How did you know about Shree Diamonds?
                                </span>
                              </div>
                              <div className="row marginn">
                                <div className="col-md-4"></div>
                                <div className="col-md-8">
                                  <select
                                    className="form-control"
                                    name="Reference"
                                    value={Reference}
                                    onChange={this.handleChange}
                                  >
                                    <option defaultValue>
                                      Select Reference
                                    </option>
                                    <option>Net Search</option>
                                    <option>Email/FAX</option>
                                    <option>Friends Recommendation</option>
                                    <option>Advertisement</option>
                                    <option>Other</option>
                                  </select>
                                </div>
                              </div>
                              <div className="row highlight-content marginn">
                                <span>Documents</span>
                              </div>
                              <div className="row marginn">
                                <div className="col-md-4">
                                  <label>Photo Type</label>
                                </div>
                                <div className="col-md-8">
                                  <select
                                    className="form-control"
                                    name="DPhotoType"
                                    value={DPhotoType}
                                    onChange={this.handleChange}
                                  >
                                    <option>JPG</option>
                                    <option>PNG</option>
                                    <option>DOC</option>
                                    <option>PDF</option>
                                  </select>
                                </div>
                              </div>
                              <div className="row marginn">
                                <div className="col-md-4">
                                  <label>Narration</label>
                                </div>
                                <div className="col-md-8">
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="Narration"
                                    value={Narration}
                                    onChange={this.handleChange}
                                  />
                                </div>
                              </div>
                              <div className="row marginn">
                                <div className="col-md-4">
                                  <label>Image</label>
                                </div>
                                <div className="col-md-8">
                                  <label className="btn btn-info">
                                    Choose File
                                    <input
                                      type="file"
                                      className="form-control"
                                      name="DImagePath"
                                      style={{ display: "none" }}
                                      onChange={this.handleFile}
                                    />
                                  </label>
                                </div>
                              </div>
                              <div className="row highlight-content marginn">
                                <span>Company Registartion Certificate</span>
                              </div>
                              <div className="row marginn">
                                <div className="col-md-4">
                                  <label>Photo Type</label>
                                </div>
                                <div className="col-md-8">
                                  <select
                                    className="form-control"
                                    name="CPhotoType"
                                    value={CPhotoType}
                                    onChange={this.handleChange}
                                  >
                                    <option>JPG</option>
                                    <option>PNG</option>
                                    <option>DOC</option>
                                    <option>PDF</option>
                                  </select>
                                </div>
                              </div>
                              <div className="row marginn">
                                <div className="col-md-4">
                                  <label>Narration</label>
                                </div>
                                <div className="col-md-8">
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="CNarration"
                                    value={CNarration}
                                    onChange={this.handleChange}
                                  />
                                </div>
                              </div>
                              <div className="row marginn">
                                <div className="col-md-4">
                                  <label>Image</label>
                                </div>
                                <div className="col-md-8">
                                  <label className="btn btn-info">
                                    Choose File
                                    <input
                                      type="file"
                                      className="form-control"
                                      name="CImagePath"
                                      style={{ display: "none" }}
                                      onChange={this.handleFile1}
                                    />
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <hr />
                          <div className="text-center">
                            <b>Mapping</b>
                          </div>
                          <div className="row pt-2">
                            <div class="col-md-9">
                              <div class="form-group">
                                <div
                                  class="custom-file"
                                  style={{ width: "unset" }}
                                >
                                  <input
                                    type="file"
                                    class="custom-file-input"
                                    name="mappingpage"
                                    onChange={this.UploadSecondFile}
                                  />
                                  <label
                                    class="custom-file-label"
                                    for="customFile"
                                  >
                                    Choose file
                                  </label>
                                </div>
                                <button
                                  class="btn btn-success ml-2"
                                  onClick={this.viewcolumn}
                                  name="btnSubmit"
                                >
                                  View Column
                                </button>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div class="dropdown">
                                <button
                                  class="btn btn-success dropdown-toggle"
                                  type="button"
                                  id="dropdownMenuButton"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  Formula Column
                                </button>
                                <div
                                  class="dropdown-menu"
                                  aria-labelledby="dropdownMenuButton"
                                >
                                  <ul>
                                    <li>
                                      <input
                                        type="checkbox"
                                        name="formula"
                                        value="RapnetDiscount*100"
                                        onChange={this.handleChangeFormula}
                                      ></input>
                                      <span className="spantag">
                                        RapnetDiscount * 100
                                      </span>
                                    </li>
                                    <li>
                                      <input
                                        type="checkbox"
                                        name="formula"
                                        value="RapnetDiscount*-1"
                                        onChange={this.handleChangeFormula}
                                      ></input>
                                      <span className="spantag">
                                        RapnetDiscount*-1
                                      </span>
                                    </li>
                                    <li>
                                      <input
                                        type="checkbox"
                                        name="formula"
                                        value="RapnetDiscount*-100"
                                        onChange={this.handleChangeFormula}
                                      ></input>
                                      <span className="spantag">
                                        RapnetDiscount*-100
                                      </span>
                                    </li>
                                    <li>
                                      <input
                                        type="checkbox"
                                        name="formula"
                                        value="Length*Width*Height"
                                        onChange={this.handleChangeFormula}
                                      ></input>
                                      <span className="spantag">
                                        Length*Width*Height
                                      </span>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="row pt-2">
                            <div className="col-md-3">
                              {this.state.data.map((view) => (
                                <input
                                  type="text"
                                  className="form-control"
                                  value={view}
                                  readOnly
                                />
                              ))}
                            </div>

                            <div className="col-md-3">
                              {this.state.data.map((view, index) => (
                                <select
                                  id={index}
                                  className="form-control"
                                  name="min_select[]"
                                  onChange={this.ViewColumnChange}
                                >
                                  <option selected disabled>
                                    Select
                                  </option>
                                  <option value="SrNo">Sr No</option>
                                  <option value="Stock">Stock</option>
                                  <option value="VendorStock">
                                    VendorStock
                                  </option>
                                  <option value="PartyName">PartyName</option>
                                  <option value="Availability">
                                    Availability
                                  </option>
                                  <option value="Shape">Shape</option>
                                  <option value="Weight">Weight</option>
                                  <option value="Color">Color</option>
                                  <option value="Clarity">Clarity</option>
                                  <option value="CutGrade">Cut Grade</option>
                                  <option value="Polish">Polish</option>
                                  <option value="Symmetry">Symmetry</option>
                                  <option value="FluorescenceIntensity">
                                    FluorescenceIntensity
                                  </option>
                                  <option value="FluorescenceColor">
                                    FluorescenceColor
                                  </option>
                                  <option value="Measurements">
                                    Measurements
                                  </option>
                                  <option value="Lab">Lab</option>
                                  <option value="Certificate">
                                    Certificate
                                  </option>
                                  <option value="Treatment">Treatment</option>
                                  <option value="Rap">Rap</option>
                                  <option value="RapAmt">RapAmt</option>
                                  <option value="VendorDiscount">
                                    VendorDiscount
                                  </option>
                                  <option value="RapNetPrice">
                                    RapNetPrice
                                  </option>
                                  <option value="NetValue">NetValue</option>
                                  <option value="CashPrice">CashPrice</option>
                                  <option value="CashPriceDiscount">
                                    CashPriceDiscount
                                  </option>
                                  <option value="FancyColorPrice">
                                    FancyColor Price
                                  </option>
                                  <option value="FancyColor">FancyColor</option>
                                  <option value="FancyColorIntensity">
                                    FancyColorIntensity
                                  </option>
                                  <option value="FancyColorOvertone">
                                    FancyColorOvertone
                                  </option>
                                  <option value="Depth">Depth</option>
                                  <option value="Table">Table</option>
                                  <option value="GirdleThin">GirdleThin</option>
                                  <option value="GirdleThick">
                                    GirdleThick
                                  </option>
                                  <option value="Girdle">Girdle</option>
                                  <option value="GirdleCondition">
                                    GirdleCondition
                                  </option>
                                  <option value="CuletSize">CuletSize</option>
                                  <option value="CuletCondition">
                                    CuletCondition
                                  </option>
                                  <option value="CrownHeight">
                                    CrownHeight
                                  </option>
                                  <option value="CrownAngle">CrownAngle</option>
                                  <option value="PavilionDepth">
                                    PavilionDepth
                                  </option>
                                  <option value="PavilionAngle">
                                    PavilionAngle
                                  </option>
                                  <option value="LaserInscription">
                                    LaserInscription
                                  </option>
                                  <option value="Certcomment">
                                    Certcomment
                                  </option>
                                  <option value="Country">Country</option>
                                  <option value="State">State</option>
                                  <option value="City">City</option>
                                  <option value="Timetolocation">
                                    Timetolocation
                                  </option>
                                  <option value="IsMatchedPairSeparable">
                                    IsMatchedPairSeparable
                                  </option>
                                  <option value="PairStock">PairStock</option>
                                  <option value="AllowRapLinkFeed">
                                    AllowRapLinkFeed
                                  </option>
                                  <option value="ParcelStones">
                                    ParcelStones
                                  </option>
                                  <option value="CertificateFilename">
                                    CertificateFilename
                                  </option>
                                  <option value="DiamondImage">
                                    DiamondImage
                                  </option>
                                  <option value="VideoURL">VideoURL</option>
                                  <option value="TradeShow">TradeShow</option>
                                  <option value="Keytosymbols">
                                    Keytosymbols
                                  </option>
                                  <option value="Shade">Shade</option>
                                  <option value="StarLength">StarLength</option>
                                  <option value="CenterInclusion">
                                    CenterInclusion
                                  </option>
                                  <option value="BlackInclusion">
                                    BlackInclusion
                                  </option>
                                  <option value="MemberComment">
                                    MemberComment
                                  </option>
                                  <option value="ReportIssueDate">
                                    ReportIssueDate
                                  </option>
                                  <option value="ReportType">ReportType</option>
                                  <option value="LabLocation">
                                    LabLocation
                                  </option>
                                  <option value="Roundand">Roundand</option>
                                  <option value="Milky">Milky</option>
                                </select>
                              ))}
                            </div>
                            <div className="col-md-3">
                              {this.state.data.map((view, index) => (
                                <input
                                  type="button"
                                  id={index}
                                  name={view}
                                  className="btn btn-info"
                                  style={{
                                    width: "190px",
                                    border: "1px solid white",
                                  }}
                                  onClick={(e) => this.resetDropDown(e, view)}
                                  value="Reset"
                                />
                              ))}
                            </div>
                          </div>
                          {this.state.FileHeaderdata != null ? (
                            <div>
                              {this.props.match.params.id ? (
                                <div className="row">
                                  <div className="col-md-3">
                                    {this.state.FileHeaderdata.map((view) => (
                                      <input
                                        type="text"
                                        className="form-control"
                                        readOnly
                                        value={view}
                                      />
                                    ))}
                                  </div>
                                  <div className="col-md-3">
                                    {this.state.pushdata.map((view) => (
                                      <select
                                        id="mapping"
                                        className="form-control"
                                        name="min_select[]"
                                        onChange={this.ViewColumnChange}
                                      >
                                        <option value={view}>{view}</option>
                                        <option value="SrNo">Sr No</option>
                                        <option value="Stock">Stock</option>
                                        <option value="VendorStock">
                                          VendorStock
                                        </option>
                                        <option value="PartyName">
                                          PartyName
                                        </option>
                                        <option value="Availability">
                                          Availability
                                        </option>
                                        <option value="Shape">Shape</option>
                                        <option value="Weight">Weight</option>
                                        <option value="Color">Color</option>
                                        <option value="Clarity">Clarity</option>
                                        <option value="CutGrade">
                                          Cut Grade
                                        </option>
                                        <option value="Polish">Polish</option>
                                        <option value="Symmetry">
                                          Symmetry
                                        </option>
                                        <option value="FluorescenceIntensity">
                                          FluorescenceIntensity
                                        </option>
                                        <option value="FluorescenceColor">
                                          FluorescenceColor
                                        </option>
                                        <option value="Measurements">
                                          Measurements
                                        </option>
                                        <option value="Lab">Lab</option>
                                        <option value="Certificate">
                                          Certificate
                                        </option>
                                        <option value="Treatment">
                                          Treatment
                                        </option>
                                        <option value="Rap">Rap</option>
                                        <option value="RapAmt">RapAmt</option>
                                        <option value="VendorDiscount">
                                          VendorDiscount
                                        </option>
                                        <option value="RapNetPrice">
                                          RapNetPrice
                                        </option>
                                        <option value="NetValue">
                                          NetValue
                                        </option>
                                        <option value="CashPrice">
                                          CashPrice
                                        </option>
                                        <option value="CashPriceDiscount">
                                          CashPriceDiscount
                                        </option>
                                        <option value="FancyColorPrice">
                                          FancyColor Price
                                        </option>
                                        <option value="FancyColor">
                                          FancyColor
                                        </option>
                                        <option value="FancyColorIntensity">
                                          FancyColorIntensity
                                        </option>
                                        <option value="FancyColorOvertone">
                                          FancyColorOvertone
                                        </option>
                                        <option value="Depth">Depth</option>
                                        <option value="Table">Table</option>
                                        <option value="GirdleThin">
                                          GirdleThin
                                        </option>
                                        <option value="GirdleThick">
                                          GirdleThick
                                        </option>
                                        <option value="Girdle">Girdle</option>
                                        <option value="GirdleCondition">
                                          GirdleCondition
                                        </option>
                                        <option value="CuletSize">
                                          CuletSize
                                        </option>
                                        <option value="CuletCondition">
                                          CuletCondition
                                        </option>
                                        <option value="CrownHeight">
                                          CrownHeight
                                        </option>
                                        <option value="CrownAngle">
                                          CrownAngle
                                        </option>
                                        <option value="PavilionDepth">
                                          PavilionDepth
                                        </option>
                                        <option value="PavilionAngle">
                                          PavilionAngle
                                        </option>
                                        <option value="LaserInscription">
                                          LaserInscription
                                        </option>
                                        <option value="Certcomment">
                                          Certcomment
                                        </option>
                                        <option value="Country">Country</option>
                                        <option value="State">State</option>
                                        <option value="City">City</option>
                                        <option value="Timetolocation">
                                          Timetolocation
                                        </option>
                                        <option value="IsMatchedPairSeparable">
                                          IsMatchedPairSeparable
                                        </option>
                                        <option value="PairStock">
                                          PairStock
                                        </option>
                                        <option value="AllowRapLinkFeed">
                                          AllowRapLinkFeed
                                        </option>
                                        <option value="ParcelStones">
                                          ParcelStones
                                        </option>
                                        <option value="CertificateFilename">
                                          CertificateFilename
                                        </option>
                                        <option value="DiamondImage">
                                          DiamondImage
                                        </option>
                                        <option value="VideoURL">
                                          videoURL
                                        </option>
                                        <option value="TradeShow">
                                          TradeShow
                                        </option>
                                        <option value="Keytosymbols">
                                          Keytosymbols
                                        </option>
                                        <option value="Shade">Shade</option>
                                        <option value="StarLength">
                                          StarLength
                                        </option>
                                        <option value="CenterInclusion">
                                          CenterInclusion
                                        </option>
                                        <option value="BlackInclusion">
                                          BlackInclusion
                                        </option>
                                        <option value="MemberComment">
                                          MemberComment
                                        </option>
                                        <option value="ReportIssueDate">
                                          ReportIssueDate
                                        </option>
                                        <option value="ReportType">
                                          ReportType
                                        </option>
                                        <option value="LabLocation">
                                          LabLocation
                                        </option>
                                        <option value="Roundand">
                                          Roundand
                                        </option>
                                        <option value="Milky">Milky</option>
                                      </select>
                                    ))}
                                  </div>

                                  {/* <div className="col-md-3">
                                                                        {this.state.textformula.map((view) => (
                                                                            <input type="text" value={view} name="formula[]" className="form-control" />
                                                                        ))}
                                                                    </div> */}
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          ) : (
                            ""
                          )}

                          <div
                            className="row"
                            style={{ marginBottom: "2%", marginTop: "2%" }}
                          >
                            <div className="col-md-4"></div>
                            <div className="col-md-4">
                              <center>
                                <button className="btn btn-info">Save</button>
                              </center>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    );
  }
}

export default AddParty;

import React, { Component, Fragment } from 'react';
import Headers from '../../Headers/Header';
import Axios from 'axios';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
import axios from 'axios';
import config from '../../Config/config.json';
import moment from 'moment';

class ViewOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      GetOrder: [],
      Comment: '',
      deviceID: '',
    };
  }
  addComment(e, id) {
    axios
      .post(`/users/addComment/${id}`, { Comment: this.state.Comment })
      .then((resp) => {
        this.setState({
          Comment: '',
        });
      });
  }
  RequestAccepted(e, id, userid) {
    axios.get(`/getCustomerDeviceToken/${userid}`).then((resp) => {
      this.setState({
        deviceID: resp.data.deviceID,
      });
    });
    swal({
      title: 'Are you sure?',
      text: 'You want to accept this order?',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        Axios.get(`/users/accpetorder/${id}`).then((res) => {
          swal('Order request has been accepted', {
            icon: 'success',
          });
          const bodyData = {
            notification: {
              body: 'Your order has been accepted',
              title: 'Order Confirmation',
            },
            to: this.state.deviceID,
          };
          const options = {
            method: 'post',
            headers: {
              Accept: 'application/json, text/plain, */*',
              'Content-Type': 'application/json',
              Authorization: config.SECRET_KEY,
            },
            body: JSON.stringify(bodyData),
          };
          fetch('https://fcm.googleapis.com/fcm/send', options).then(
            (response) => {
              return response.json();
            }
          );

          this.componentDidMount();
        });
      } else {
        swal('You canceled!');
      }
    });
  }
  RequestRejceted(e, id, userid) {
    axios.get(`/getCustomerDeviceToken/${userid}`).then((resp) => {
      this.setState({
        deviceID: resp.data.deviceID,
      });
    });
    swal({
      title: 'Are you sure?',
      text: 'You want to reject this order?',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        Axios.get(`/users/rejectedorder/${id}`).then((res) => {
          swal('Order request has been rejected', {
            icon: 'error',
          });
          const bodyData = {
            notification: {
              body: 'Your order has been rejected',
              title: 'Order Rejection',
            },
            to: this.state.deviceID,
          };
          const options = {
            method: 'post',
            headers: {
              Accept: 'application/json, text/plain, */*',
              'Content-Type': 'application/json',
              Authorization: config.SECRET_KEY,
            },
            body: JSON.stringify(bodyData),
          };
          fetch('https://fcm.googleapis.com/fcm/send', options).then(
            (response) => {
              return response.json();
            }
          );

          this.componentDidMount();
        });
      } else {
        swal('You canceled!');
      }
    });
  }
  componentDidMount() {
    Axios.get('/users/ViewOrder').then((res) => {
      this.setState({
        GetOrder: res.data,
      });
      const script = document.createElement('script');
      script.src = `js/content.js`;
      script.async = true;
      document.body.appendChild(script);
    });
  }
  render() {
    return (
      <div>
        <Headers />
        <div className="wrapper">
          <div className="content-wrapper pl-3">
            <h2>View Order</h2>
            <section className="container">
              <div className="container-fluid">
                <div className="card">
                  <div className="card-header">
                    <div className="card-title"></div>
                  </div>
                  <div className="card-body">
                    {this.state.GetOrder.length > 0 ? (
                      <div className="table-responsive">
                        <table
                          id="example1"
                          className="table table-striped table-bordered"
                        >
                          <thead>
                            <tr>
                              <th>Status</th>
                              <th>Comment</th>

                              <th>User</th>
                              <th>Order Date</th>
                              <th>Weight</th>
                              <th>Color</th>
                              <th>Clarity</th>
                              <th>Shape</th>
                              <th>Certificate</th>
                              <th>RapnetDiscount</th>
                              <th>RapnetPrice</th>
                              <th>RapAmt</th>
                              <th>NetValue</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.GetOrder.map((view) =>
                              view.UserID && view.InwardDetails ? (
                                <tr>
                                  {/* STATUS */}
                                  <td>
                                    {view.IsAccepted === 'Pending' ? (
                                      <Fragment>
                                        <Link
                                          onClick={(e) => {
                                            this.RequestAccepted(
                                              e,
                                              view._id,
                                              view.UserID._id
                                            );
                                          }}
                                        >
                                          <i
                                            className="fas fa-check-circle fa-2x"
                                            style={{ color: '#17a2b8' }}
                                          ></i>
                                        </Link>
                                        &nbsp;&nbsp;
                                        <Link
                                          onClick={(e) =>
                                            this.RequestRejceted(
                                              e,
                                              view._id,
                                              view.UserID._id
                                            )
                                          }
                                        >
                                          <i
                                            className="fas fa-times-circle fa-2x"
                                            style={{ color: '#c93d3a' }}
                                          ></i>
                                        </Link>
                                      </Fragment>
                                    ) : (
                                      <Fragment>
                                        {view.Status === true ? (
                                          <b style={{ color: '#17a2b8' }}>
                                            ACCEPTED
                                          </b>
                                        ) : (
                                          <b style={{ color: '#c93d3a' }}>
                                            REJECTED
                                          </b>
                                        )}
                                      </Fragment>
                                    )}
                                  </td>
                                  {view.IsAccepted === 'Pending' ? (
                                    <td>
                                      <button
                                        type="button"
                                        class="btn btn-info"
                                        data-toggle="modal"
                                        data-target={`#ID${view._id}`}
                                      >
                                        <i
                                          class="fa fa-comment"
                                          aria-hidden="true"
                                        ></i>
                                      </button>
                                    </td>
                                  ) : (
                                    <td>
                                      <button
                                        type="button"
                                        class="btn btn-info disabled"
                                      >
                                        <i
                                          class="fa fa-comment"
                                          aria-hidden="true"
                                        ></i>
                                      </button>
                                    </td>
                                  )}

                                  <div
                                    class="modal fade"
                                    id={`ID${view._id}`}
                                    tabindex="-1"
                                    role="dialog"
                                    aria-labelledby="exampleModalLabel"
                                    aria-hidden="true"
                                  >
                                    <div class="modal-dialog" role="document">
                                      <div class="modal-content">
                                        <div class="modal-header">
                                          <h5
                                            class="modal-title"
                                            id="exampleModalLabel"
                                          >
                                            Modal title
                                          </h5>
                                          <button
                                            type="button"
                                            class="close"
                                            data-dismiss="modal"
                                            aria-label="Close"
                                          >
                                            <span aria-hidden="true">
                                              &times;
                                            </span>
                                          </button>
                                        </div>
                                        <div class="modal-body">
                                          Add your comment
                                          <textarea
                                            name="Comment"
                                            value={this.state.Comment}
                                            className="form-control"
                                            onChange={(e) => {
                                              this.setState({
                                                Comment: e.target.value,
                                              });
                                            }}
                                          ></textarea>
                                        </div>
                                        <div class="modal-footer">
                                          <button
                                            type="button"
                                            class="btn btn-secondary"
                                            data-dismiss="modal"
                                          >
                                            Close
                                          </button>
                                          <button
                                            type="button"
                                            class="btn btn-info"
                                            onClick={(e) =>
                                              this.addComment(e, view._id)
                                            }
                                          >
                                            Add
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <td>{view.UserID.UserName}</td>
                                  <td>
                                    {moment(view.OrderDate).format(
                                      'YYYY-MM-DD HH:mm:ss'
                                    )}
                                  </td>

                                  <td>{view.InwardDetails.Weight}</td>
                                  <td>{view.InwardDetails.Color}</td>
                                  <td>{view.InwardDetails.Clarity}</td>
                                  <td>{view.InwardDetails.Shape}</td>
                                  <td>{view.InwardDetails.Certificate}</td>
                                  <td>{view.InwardDetails.RapnetDiscount}</td>
                                  <td>{view.InwardDetails.RapNetPrice}</td>
                                  <td>{view.InwardDetails.RapAmt}</td>
                                  <td>{view.InwardDetails.NetValue}</td>
                                  {/* <td><i class="fa fa-comment" aria-hidden="true"></i></td> */}
                                </tr>
                              ) : (
                                ''
                              )
                            )}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <h1>No Order</h1>
                    )}
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    );
  }
}

export default ViewOrder;

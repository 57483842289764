import React, { Component, Fragment } from 'react';
import Axios from 'axios'




class ViewStock extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inwardData: [],
            inwardDetailData1: [],
            allData: [],
            ClientToken: localStorage.getItem('token')

        }
    }



    componentDidMount() {
        //console.log("TOKENN ACCESS : " + token1)
        //const tkn = this.state.token

        Axios.get('/users/getInwardData', {
            headers: {
                'x-auth-token': this.state.ClientToken
            }
        }).then(res => {
            console.log("TOKEN" + localStorage.getItem('token'))
            console.log(res.data)
            this.setState({
                inwardData: res.data,
            })
            Axios.get('/users/InwardDetailData', {
                headers: {
                    'x-auth-token': this.state.ClientToken
                }
            }).then(res1 => {
                console.log(res1.data)
                this.setState({
                    inwardDetailData1: res1.data,
                })
            })
        })


    }



    render() {
        const { inwardData, inwardDetailData1 } = this.state
        return (
            <div>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">



                            <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title">Available Stock</h3>
                                </div>

                                <div className="card-body">
                                    <table id="" className="table table-bordered table-striped">
                                        <thead>
                                            <tr>
                                                <th>SR No.</th>
                                                <th>Stock</th>
                                                <th>VendorStock</th>
                                                <th>PartyName</th>
                                                <th>Availability</th>
                                                <th>Shape</th>
                                                <th>Weight</th>
                                                <th>Color</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                inwardDetailData1.map((inward) => {
                                                    return (
                                                        <tr>
                                                            <td>{inward.srNo}</td>
                                                            <td>{inward.stock}</td>
                                                            <td>{inward.VendorStock}</td>

                                                            {

                                                                inwardData.filter((IID) => IID._id === inward.InwardID).map((inwarddetails) => {
                                                                    return (
                                                                        <Fragment>

                                                                            <td>{inwarddetails.partyID.partyName}</td>


                                                                        </Fragment>

                                                                    )
                                                                })
                                                            }
                                                            <td>{inward.Availability}</td>
                                                            <td>{inward.Shape}</td>
                                                            <td>{inward.Weight}</td>
                                                            <td>{inward.Color}</td>
                                                        </tr>
                                                    )


                                                })




                                            }




                                        </tbody>

                                    </table>
                                </div>

                            </div>

                        </div>

                    </div>

                </div>
            </div>
        );
    }
}



export default ViewStock;
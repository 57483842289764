import React, { Component } from 'react';
import axios from 'axios'
import swal from 'sweetalert'


class AddCustomer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            County1: [],
            State1: [],
            City1: [],
            FullName: '',
            DOB: '',
            UserName: '',
            DisplayName: '',
            Designation: '',
            Password: '',
            UserBusiness: '',
            SkypeID: '',
            QQID: '',
            EmailID: '',
            WeChatID: '',
            CompanyName: '',
            Address1: '',
            Address2: '',
            Address3: '',
            CountryID: '',
            StateID: '',
            CityID: '',
            PinCode: '',
            STD_Code: '',
            ISD_Code: '',
            Mobile1: '',
            Mobile2: '',
            Phone1: '',
            Phone2: '',
            CompanyEmailID: '',
            FaxNo: '',
            CompanyType: '',
            Website: '',
            Assoc1: '',
            Assoc2: '',
            Comment: '',
            Reference: '',
            DImagePath: '',
            DPhotoType: '',
            Narration: '',
            CPhotoType: '',
            CNarration: '',
            CImagePath: '',
            TermCondition: false,
            tc: false,
            file: null,
            errors: {
                FullName: '',
                Password: '',
                EmailID: '',
            },
            cid1: '',
            ssid: ''
        }
        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleFile = this.handleFile.bind(this)
        this.handleFile1 = this.handleFile1.bind(this)
        this.countryChange = this.countryChange.bind(this)
        this.stateChange = this.stateChange.bind(this)
    }


    countryChange(e) {
        this.setState({
            cid1: e.target.value
        })

        axios.get(`/getStates/${e.target.value}`).then(res => {

            this.setState({
                State1: res.data
            })
        })
    }
    stateChange(e) {

        axios.get(`/getCities/${e.target.value}`).then(res => {

            this.setState({
                City1: res.data
            })
        })
    }
    handleSubmit(event) {
        event.preventDefault()
        const formData = new FormData()
        formData.append('FullName', this.state.FullName)
        formData.append('DOB', this.state.DOB)
        formData.append('UserName', this.state.UserName)
        formData.append('DisplayName', this.state.DisplayName)
        formData.append('Designation', this.state.Designation)
        formData.append('Password', this.state.Password)
        formData.append('UserBusiness', this.state.UserBusiness)
        formData.append('SkypeID', this.state.SkypeID)
        formData.append('QQID', this.state.QQID)
        formData.append('EmailID', this.state.EmailID)
        formData.append('WeChatID', this.state.WeChatID)
        formData.append('CompanyName', this.state.CompanyName)
        formData.append('Address1', this.state.Address1)
        formData.append('Address2', this.state.Address2)
        formData.append('Address3', this.state.Address3)
        formData.append('CityID', this.state.CityID)
        formData.append('PinCode', this.state.PinCode)
        formData.append('STD_Code', this.state.STD_Code)
        formData.append('ISD_Code', this.state.ISD_Code)
        formData.append('Mobile1', this.state.Mobile1)
        formData.append('Mobile2', this.state.Mobile2)
        formData.append('Phone1', this.state.Phone1)
        formData.append('Phone2', this.state.Phone2)
        formData.append('CompanyEmailID', this.state.CompanyEmailID)
        formData.append('FaxNo', this.state.FaxNo)
        formData.append('CompanyType', this.state.CompanyType)
        formData.append('Website', this.state.Website)
        formData.append('Assoc1', this.state.Assoc1)
        formData.append('Assoc2', this.state.Assoc2)
        formData.append('Comment', this.state.Comment)
        formData.append('Reference', this.state.Reference)
        formData.append('DImagePath', this.state.DImagePath)
        formData.append('DPhotoType', this.state.DPhotoType)
        formData.append('Narration', this.state.Narration)
        formData.append('CPhotoType', this.state.CPhotoType)
        formData.append('CNarration', this.state.CNarration)
        formData.append('CImagePath', this.state.CImagePath)
        formData.append('TermCondition', this.state.TermCondition)

        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        };
        if (this.state.TermCondition === false) {
            swal('PLEASE CHECK THE AGREEMENT', '', 'warning')
        }
        else {
            axios.post('/register', formData, config).then(res => {
                if (res.data.agreement) {

                }
                if (res.data.FileError) {
                    swal(res.data.FileError, '', 'error')
                }
                if (res.data.errors) {
                    swal(res.data.errors, '', 'error')
                }
                else {
                    swal('Data Inserted', '', 'success')
                }
            }).catch((err) => {

            })
        }
    }
    handleChange(event) {

        let errors = this.state.errors


        this.setState({
            errors,
            [event.target.name]: event.target.value,
            TermCondition: event.target.checked,
        })

    }
    handleFile(event) {
        this.setState({
            DImagePath: event.target.files[0]
        })
    }
    handleFile1(event) {
        this.setState({
            CImagePath: event.target.files[0]
        })
    }
    componentDidMount() {
        axios.get('/getCountries').then(res => {
            this.setState({
                County1: res.data,
                state1: [],
                city1: []
            })
        })
    }

    render() {
        const { errors, FullName, DOB, UserName, DisplayName, Designation, Password, UserBusiness, SkypeID, QQID, EmailID, WeChatID, CompanyName, Address1, Address2, Address3, CityID, PinCode, STD_Code, ISD_Code, Mobile1, Mobile2, Phone1, Phone2, CompanyEmailID,
            FaxNo, CompanyType, Website, Assoc1, Assoc2, Comment, Reference, DPhotoType, Narration, CPhotoType, CNarration, TermCondition, County1, State1, City1 } = this.state
        return (
            <div>
                <div className="container" >
                    <h3 style={{ fontFamily: "sans-serif", color: "grey", textAlign: "center", paddingTop: "1%" }}>User Registration</h3>
                    <div className="divider mrg25T mrg25B"></div>
                    <div >
                        <form onSubmit={this.handleSubmit} noValidate >
                            <div className="row marginn">
                                <div className="col-md-4">
                                    <div className="row marginn" >
                                        <div className="col-md-4">
                                            <label>Full Name*</label>


                                        </div>
                                        <div className="col-md-8">
                                            <input type="text" className="form-control " name="FullName" value={FullName} onChange={this.handleChange} required />
                                            {errors.FullName.length > 0 &&
                                                <span className="label-danger">{errors.FullName}</span>
                                            }

                                        </div>
                                    </div>
                                    <div className="row marginn" >
                                        <div className="col-md-4">
                                            <label>User Name*</label>
                                        </div>
                                        <div className="col-md-8">
                                            <input type="text" className="form-control" name="UserName" value={UserName} onChange={this.handleChange} />
                                        </div>
                                    </div>
                                    <div className="row marginn" >
                                        <div className="col-md-4">
                                            <label>Display Name</label>
                                        </div>
                                        <div className="col-md-8">
                                            <input type="text" className="form-control" name="DisplayName" value={DisplayName} onChange={this.handleChange} />
                                        </div>
                                    </div>
                                    <div className="row" >
                                        <div className="col-md-4">
                                            <label>Designation</label>
                                        </div>
                                        <div className="col-md-8">
                                            <input type="text" className="form-control" name="Designation" value={Designation} onChange={this.handleChange} />
                                        </div>
                                    </div>

                                    <div className="row"  >
                                        <div className="col-md-4">
                                            <label>Date of Birth*</label>
                                        </div>
                                        <div className="col-md-8">
                                            <input type="date" className="form-control" name="DOB" value={DOB} onChange={this.handleChange} />
                                        </div>
                                    </div>
                                    <div className="row"  >
                                        <div className="col-md-4">
                                            <label>Email Address</label>
                                        </div>
                                        <div className="col-md-8">
                                            <input type="email" className="form-control" name="EmailID" value={EmailID} onChange={this.handleChange} />
                                            {errors.EmailID.length > 0 &&
                                                <span className="label-danger" style={{ color: "red!important" }}>{errors.EmailID}</span>
                                            }
                                        </div>
                                    </div>
                                    <div className="row"  >
                                        <div className="col-md-4">
                                            <label>Password</label>
                                        </div>
                                        <div className="col-md-8">
                                            <input type="password" className="form-control" name="Password" value={Password} onChange={this.handleChange} />
                                            {errors.Password.length > 0 &&
                                                <span className="label-danger" style={{ color: "red!important" }}>{errors.Password}</span>}
                                        </div>
                                    </div>
                                    <div className="row"  >
                                        <div className="col-md-4">
                                            <label>User Business</label>
                                        </div>
                                        <div className="col-md-8">
                                            <select className="form-control" name="UserBusiness" value={UserBusiness} onChange={this.handleChange}>
                                                <option defaultValue disabled="true" selected="true">Select User Business</option>
                                                <option>Wholeseller</option>
                                                <option>End User</option>
                                                <option>Jewellery MFG</option>
                                                <option>Retailer</option>
                                                <option>Polished Distributer</option>
                                                <option>Other</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row"  >
                                        <div className="col-md-4">
                                            <label>Company Name</label>
                                        </div>
                                        <div className="col-md-8">
                                            <input type="text" className="form-control" name="CompanyName" value={CompanyName} onChange={this.handleChange} />
                                        </div>
                                    </div>
                                    <div className="row"  >
                                        <div className="col-md-4">
                                            <label>Skype</label>
                                        </div>
                                        <div className="col-md-8">
                                            <input type="text" className="form-control" name="SkypeID" value={SkypeID} onChange={this.handleChange} />
                                        </div>
                                    </div>
                                    <div className="row"  >
                                        <div className="col-md-4">
                                            <label>QQID</label>
                                        </div>
                                        <div className="col-md-8">
                                            <input type="text" className="form-control" name="QQID" value={QQID} onChange={this.handleChange} />
                                        </div>
                                    </div>
                                    <div className="row"  >
                                        <div className="col-md-4">
                                            <label>Wechat</label>
                                        </div>
                                        <div className="col-md-8">
                                            <input type="text" className="form-control" name="WeChatID" value={WeChatID} onChange={this.handleChange} />
                                        </div>
                                    </div>

                                    <div className="row"  >
                                        <div className="col-md-4">
                                            <label>Address1</label>
                                        </div>
                                        <div className="col-md-8">
                                            <textarea className="form-control" name="Address1" value={Address1} onChange={this.handleChange}></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">

                                    <div className="row"  >
                                        <div className="col-md-4">
                                            <label>Address2</label>
                                        </div>
                                        <div className="col-md-8">
                                            <textarea className="form-control" name="Address2" value={Address2} onChange={this.handleChange}></textarea>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <label>Address3</label>
                                        </div>
                                        <div className="col-md-8">
                                            <textarea className="form-control" name="Address3" value={Address3} onChange={this.handleChange}></textarea>
                                        </div>
                                    </div>


                                    <div className="row"  >
                                        <div className="col-md-4">
                                            <label>Country</label>
                                        </div>
                                        <div className="col-md-8">
                                            <select name="CountryID" className="form-control" value={this.state.cid1} onChange={this.countryChange}>
                                                <option>--Select Country--</option>
                                                {
                                                    County1.map((c, key) => (
                                                        <option value={c.id} key={key}>{c.name}</option>
                                                    ))
                                                }

                                            </select>
                                        </div>
                                    </div>
                                    <div className="row"  >
                                        <div className="col-md-4">
                                            <label>State</label>
                                        </div>
                                        <div className="col-md-8">
                                            <select name="StateID" className="form-control" value={this.state.sid} onChange={this.stateChange}>
                                                <option defaultValue>--Select State--</option>
                                                {
                                                    State1.map((s, key) => (
                                                        <option value={s.id} key={key}>{s.name}</option>
                                                    ))

                                                }

                                            </select>
                                        </div>
                                    </div>
                                    <div className="row"  >
                                        <div className="col-md-4">
                                            <label>City</label>
                                        </div>
                                        <div className="col-md-8">
                                            <select name="CityID" value={CityID} className="form-control" onChange={this.handleChange}>
                                                <option defaultValue>--Select City--</option>
                                                {
                                                    City1.map((ct, key) => (
                                                        <option value={ct.id} key={key}>{ct.name}</option>
                                                    ))
                                                }

                                            </select>
                                        </div>
                                    </div>
                                    <div className="row"  >
                                        <div className="col-md-4">
                                            <label>Pincode</label>
                                        </div>
                                        <div className="col-md-8">
                                            <input type="text" className="form-control" name="PinCode" value={PinCode} onChange={this.handleChange} />
                                        </div>
                                    </div>
                                    <div className="row"  >
                                        <div className="col-md-4">
                                            <label>STD Code</label>
                                        </div>
                                        <div className="col-md-8">
                                            <input type="text" className="form-control" name="STD_Code" value={STD_Code} onChange={this.handleChange} />
                                        </div>
                                    </div>
                                    <div className="row"  >
                                        <div className="col-md-4">
                                            <label>ISD Code</label>
                                        </div>
                                        <div className="col-md-8">
                                            <input type="text" className="form-control" name="ISD_Code" value={ISD_Code} onChange={this.handleChange} />
                                        </div>
                                    </div>
                                    <div className="row"  >
                                        <div className="col-md-4">
                                            <label>Mobile No1</label>
                                        </div>
                                        <div className="col-md-8">
                                            <input type="text" className="form-control" name="Mobile1" value={Mobile1} onChange={this.handleChange} />
                                        </div>
                                    </div>
                                    <div className="row"  >
                                        <div className="col-md-4">
                                            <label>Mobile No2</label>
                                        </div>
                                        <div className="col-md-8">
                                            <input type="text" className="form-control" name="Mobile2" value={Mobile2} onChange={this.handleChange} />
                                        </div>
                                    </div>
                                    <div className="row"  >
                                        <div className="col-md-4">
                                            <label>Phone1</label>
                                        </div>
                                        <div className="col-md-8">
                                            <input type="text" className="form-control" name="Phone1" value={Phone1} onChange={this.handleChange} />
                                        </div>
                                    </div>
                                    <div className="row"  >
                                        <div className="col-md-4">
                                            <label>Phone2</label>
                                        </div>
                                        <div className="col-md-8">
                                            <input type="text" className="form-control" name="Phone2" value={Phone2} onChange={this.handleChange} />
                                        </div>
                                    </div>
                                    <div className="row"  >
                                        <div className="col-md-4">
                                            <label>Company EmailID</label>
                                        </div>
                                        <div className="col-md-8">
                                            <input type="email" className="form-control" name="CompanyEmailID" value={CompanyEmailID} onChange={this.handleChange} />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <label>Fax No</label>
                                        </div>
                                        <div className="col-md-8">
                                            <input type="text" className="form-control" name="FaxNo" value={FaxNo} onChange={this.handleChange} />
                                        </div>
                                    </div>
                                </div>



                                <div className="col-md-4">


                                    <div className="row"  >
                                        <div className="col-md-4">
                                            <label>Company Type</label>
                                        </div>
                                        <div className="col-md-8">
                                            <select className="form-control" name="CompanyType" value={CompanyType} onChange={this.handleChange}>
                                                <option defaultValue disabled="true" selected="true">Select Company Type</option>
                                                <option>Individual</option>
                                                <option>Partnership</option>
                                                <option>Private Limited/Limited</option>
                                                <option>Proprieter</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <label>Website</label>
                                        </div>
                                        <div className="col-md-8">
                                            <input type="text" className="form-control" name="Website" value={Website} onChange={this.handleChange} />
                                        </div>
                                    </div>
                                    <div className="row highlight-content"  >
                                        <center><b>Member of any Association/Trading network?</b>
                                        </center>
                                    </div>
                                    <div className="row"  >
                                        <div className="col-md-4">
                                            <label>1</label>
                                        </div>
                                        <div className="col-md-8">
                                            <input type="text" className="form-control" name="Assoc1" value={Assoc1} onChange={this.handleChange} />
                                        </div>
                                    </div>
                                    <div className="row"  >
                                        <div className="col-md-4">
                                            <label>2</label>
                                        </div>
                                        <div className="col-md-8">
                                            <input type="text" className="form-control" name="Assoc2" value={Assoc2} onChange={this.handleChange} />
                                        </div>
                                    </div>
                                    <div className="row"  >
                                        <div className="col-md-4">
                                            <label>Comment</label>
                                        </div>
                                        <div className="col-md-8">
                                            <input type="text" className="form-control" name="Comment" value={Comment} onChange={this.handleChange} />
                                        </div>
                                    </div>
                                    <div className="row highlight-content"  >
                                        <center><b>How did you know about Shree Diamonds?</b></center>
                                    </div>
                                    <div className="row"  >
                                        <div className="col-md-4">

                                        </div>
                                        <div className="col-md-8">
                                            <select className="form-control" name="Reference" value={Reference} onChange={this.handleChange}>
                                                <option>Net Search</option>
                                                <option>Email/FAX</option>
                                                <option>Friends Recommendation</option>
                                                <option>Advertisement</option>
                                                <option>Other</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row highlight-content"  >
                                        <center><b>Documents</b></center>
                                    </div>
                                    <div className="row"  >
                                        <div className="col-md-4">
                                            <label>Photo Type</label>
                                        </div>
                                        <div className="col-md-8">
                                            <select className="form-control" name="DPhotoType" value={DPhotoType} onChange={this.handleChange}>
                                                <option>JPG</option>
                                                <option>PNG</option>
                                                <option>DOC</option>
                                                <option>PDF</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row"  >
                                        <div className="col-md-4">
                                            <label>Narration</label>
                                        </div>
                                        <div className="col-md-8">
                                            <input type="text" className="form-control" name="Narration" value={Narration} onChange={this.handleChange} />
                                        </div>
                                    </div>
                                    <div className="row"  >
                                        <div className="col-md-4">
                                            <label>Image</label>
                                        </div>
                                        <div className="col-md-8">
                                            <label className="btn btn-info" >Choose File
                                                <input type="file" className="form-control" name="DImagePath" style={{ display: "none" }} onChange={this.handleFile} /></label>
                                        </div>

                                    </div>
                                    <div className="row highlight-content" >
                                        <center><b>Company Registartion Certificate</b></center>
                                    </div>
                                    <div className="row"  >
                                        <div className="col-md-4">
                                            <label>Photo Type</label>
                                        </div>
                                        <div className="col-md-8">
                                            <select className="form-control" name="CPhotoType" value={CPhotoType} onChange={this.handleChange}>
                                                <option>JPG</option>
                                                <option>PNG</option>
                                                <option>DOC</option>
                                                <option>PDF</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row"  >
                                        <div className="col-md-4">
                                            <label>Narration</label>
                                        </div>
                                        <div className="col-md-8">
                                            <input type="text" className="form-control" name="CNarration" value={CNarration} onChange={this.handleChange} />
                                        </div>
                                    </div>
                                    <div className="row" >
                                        <div className="col-md-4">
                                            <label>Image</label>
                                        </div>
                                        <div className="col-md-8">
                                            <label className="btn btn-info" >Choose File
                                                <input type="file" className="form-control" name="CImagePath" style={{ display: "none" }} onChange={this.handleFile1} /></label>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="row" style={{ marginTop: "2%" }}>
                                <div className="col-md-12">
                                    <center><input type="checkbox" name="TermCondition" value={TermCondition} onChange={this.handleChange}></input><b>I Agree Terms & Condition</b></center>
                                </div>

                            </div>
                            <div className="row" style={{ marginTop: "2%" }}>
                                <div className="col-md-4"></div>
                                <div className="col-md-4">
                                    <center><button type="submit" className="btn btn-info" >Save</button></center>
                                </div>
                            </div>

                        </form>
                    </div>

                </div>
            </div>
        );
    }
}



export default AddCustomer;
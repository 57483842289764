import React, { Component } from 'react';
import swal from 'sweetalert'


import Axios from 'axios';


class AddInward extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inwardImport: '',
            uploadPercentage: 0,
            Party: [],
            partyID: '',
            inwID: '',
            InwardType: '',
            Date: '',
            DueDays: '',
            Cash_Credit: '',
            Less1: '',
            Less2: '',
            Prefix: '',
            Comment: '',
            InvoiceNo: '',
            BillType: '',
            CostAmount: '',
            PickupPerson: '',
            PickupDate: '',
            PickupLocation: '',
            PickupRemark: '',
            OurStock: '',
            VenderStock: '',
            Location: '',
            Availability: '',
            Shape: '',
            Cts: '', //
            Color: '',
            Purity: '',
            Cut: '',
            Polish: '',
            Symm: '',
            Flu: '',
            FluColor: '',
            CertificateFilename: '',
            DiamondImage: '',
            TradeShow: '',
            RapRate: '',
            RapAmt: '',
            Disc: '',
            NetRate: '',
            NetAmt: '',
            CashPrice: '',
            CashDisc: '',
            Measurements: '',
            Lab: '',
            Table: '',
            Depth: '',
            ReportNo: '',
            KeyToSymbol: '',
            ReportComment: '',
            MemberComment: '',
            Milky: '',
            GirdleCondition: '',
            Girdle: '',
            StarLength: '',
            BlackInclusion: '',
            CenterInclusion: '',
            Shade: '',
            CrownAngle: '',
            CrownHeight: '',
            PavilionAngle: '',
            PavilionHeight: '',
            CuletSize: '',
            CuletCondition: '',
            ReportDate: '',
            Remark: '',
            GirdleThin: '',
            GirdleThick: '',
            Brand: ''
        }
        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleFile = this.handleFile.bind(this)
        this.handleClick = this.handleClick.bind(this)
        this.handleFileDiamond = this.handleFileDiamond.bind(this)
        this.handleControls = this.handleControls.bind(this)
    }
    handleClick(e) {
        e.preventDefault()
        if (this.props.match.params.id == null) {
            const formData = new FormData()
            formData.append('partyID', this.state.partyID)
            formData.append('inwardType', this.state.InwardType)
            formData.append('DueDays', this.state.DueDays)
            formData.append('Cash_Credit', this.state.Cash_Credit)
            formData.append('Less1', this.state.Less1)
            formData.append('Less2', this.state.Less2)
            formData.append('Prefix', this.state.Prefix)
            formData.append('Comment', this.state.Comment)
            formData.append('InvoiceNo', this.state.InvoiceNo)
            formData.append('BillType', this.state.BillType)
            formData.append('CostAmount', this.state.CostAmount)
            formData.append('PickupPerson', this.state.PickupPerson)
            formData.append('PickupDate', this.state.PickupDate)
            formData.append('PickupLocation', this.state.PickupLocation)
            formData.append('PickupRemark', this.state.PickupRemark)
            formData.append('OurStock', this.state.OurStock)
            formData.append('VenderStock', this.state.VenderStock)
            formData.append('Location', this.state.Location)
            formData.append('Availability', this.state.Availability)
            formData.append('Shape', this.state.Shape)
            formData.append('Cts', this.state.Cts)
            formData.append('Color', this.state.Color)
            formData.append('Clarity', this.state.Clarity)
            formData.append('Cut', this.state.Cut)
            formData.append('Polish', this.state.Polish)
            formData.append('Symm', this.state.Symm)
            formData.append('Flu', this.state.Flu)
            formData.append('FluColor', this.state.FluColor)
            formData.append('CertificateFilename', this.state.CertificateFilename)
            formData.append('DiamondImage', this.state.DiamondImage)
            formData.append('TradeShow', this.state.TradeShow)
            formData.append('RapRate', this.state.RapRate)
            formData.append('RapAmt', this.state.RapAmt)
            formData.append('Disc', this.state.Disc)
            formData.append('NetRate', this.state.NetRate)
            formData.append('NetAmt', this.state.NetAmt)
            formData.append('CashPrice', this.state.CashPrice)
            formData.append('CashDisc', this.state.CashDisc)
            formData.append('Measurements', this.state.Measurements)
            formData.append('Lab', this.state.Lab)
            formData.append('Table', this.state.Lab)
            formData.append('Depth', this.state.Depth)
            formData.append('ReportNo', this.state.ReportNo)
            formData.append('KeyToSymbol', this.state.KeyToSymbol)
            formData.append('ReportComment', this.state.ReportComment)
            formData.append('MemberComment', this.state.MemberComment)
            formData.append('Milky', this.state.Milky)
            formData.append('GirdleCondition', this.state.GirdleCondition)
            formData.append('Girdle', this.state.Girdle)
            formData.append('StarLength', this.state.StarLength)
            formData.append('BlackInclusion', this.state.BlackInclusion)
            formData.append('CenterInclusion', this.state.CenterInclusion)
            formData.append('Shade', this.state.Shade)
            formData.append('CrownAngle', this.state.CrownAngle)
            formData.append('CrownHeight', this.state.CrownHeight)
            formData.append('PavilionAngle', this.state.PavilionAngle)
            formData.append('PavilionHeight', this.state.PavilionHeight)
            formData.append('CuletSize', this.state.CuletSize)
            formData.append('CuletCondition', this.state.CuletCondition)
            formData.append('ReportDate', this.state.ReportDate)
            formData.append('Remark', this.state.Remark)
            formData.append('GirdleThin', this.state.GirdleThin)
            formData.append('GirdleThick', this.state.GirdleThick)
            formData.append('Brand', this.state.Brand)


            Axios.post('/users/addSingleInward', formData).then(res => {
             
                swal('Inward Inserted', '', 'success')
            })
        }
        else {


            this.setState({
                DueDays: this.state.DueDays,
                Cash_Credit: this.state.Cash_Credit,
                Less1: this.state.Less1,
                Less2: this.state.Less2,
                Prefix: this.state.Prefix,
                Comment: this.state.Comment,
                InvoiceNo: this.state.InvoiceNo,
                BillType: this.state.BillType,
                CostAmount: this.state.CostAmount,
                PickupPerson: this.state.PickupPerson,
                PickupDate: this.state.PickupDate,
                PickupLocation: this.state.PickupLocation,
                PickupRemark: this.state.PickupRemark,
                OurStock: this.state.OurStock,
                VenderStock: this.state.VenderStock,
                Location: this.state.Location,
                Availability: this.state.Availability,
                Shape: this.state.Shape,
                Cts: this.state.Cts,
                Color: this.state.Color,
                Clarity: this.state.Clarity,
                Cut: this.state.Cut,
                Polish: this.state.Polish,
                Symm: this.state.Symm,
                Flu: this.state.Flu,
                FluColor: this.state.FluColor,
                CertificateFilename: this.state.CertificateFilename,
                DiamondImage: this.state.DiamondImage,
                TradeShow: this.state.TradeShow,
                RapRate: this.state.RapRate,
                RapAmt: this.state.RapAmt,
                Disc: this.state.Disc,
                NetRate: this.state.NetRate,
                NetAmt: this.state.NetAmt,
                CashPrice: this.state.CashPrice,
                CashDisc: this.state.CashDisc,
                Measurements: this.state.Measurements,
                Lab: this.state.Lab,
                Table: this.state.Table,
                Depth: this.state.Depth,
                ReportNo: this.state.ReportNo,
                KeyToSymbol: this.state.KeyToSymbol,
                ReportComment: this.state.ReportComment,
                MemberComment: this.state.MemberComment,
                Milky: this.state.Milky,
                GirdleCondition: this.state.GirdleCondition,
                Girdle: this.state.Girdle,
                StarLength: this.state.StarLength,
                BlackInclusion: this.state.BlackInclusion,
                CenterInclusion: this.state.CenterInclusion,
                Shade: this.state.Shade,
                CrownAngle: this.state.CrownAngle,
                CrownHeight: this.state.CrownHeight,
                PavilionAngle: this.state.PavilionAngle,
                PavilionHeight: this.state.PavilionHeight,
                CuletSize: this.state.CuletSize,
                CuletCondition: this.state.CuletCondition,
                ReportDate: this.state.ReportDate,
                Remark: this.state.Remark,
                GirdleThin: this.state.GirdleThin,
                GirdleThick: this.state.GirdleThick,
                Brand: this.state.Brand

            })
            // formData.append('Cash_Credit', this.state.Cash_Credit)
            // formData.append('Less1', this.state.Less1)
            // formData.append('Less2', this.state.Less2)
            // formData.append('Prefix', this.state.Prefix)
            // formData.append('Comment', this.state.Comment)
            // formData.append('InvoiceNo', this.state.InvoiceNo)
            // formData.append('BillType', this.state.BillType)
            // formData.append('CostAmount', this.state.CostAmount)
            // formData.append('PickupPerson', this.state.PickupPerson)
            // formData.append('PickupDate', this.state.PickupDate)
            // formData.append('PickupLocation', this.state.PickupLocation)
            // formData.append('PickupRemark', this.state.PickupRemark)
            // formData.append('OurStock', this.state.OurStock)
            // formData.append('VenderStock', this.state.VenderStock)
            // formData.append('Location', this.state.Location)
            // formData.append('Availability', this.state.Availability)
            // formData.append('Shape', this.state.Shape)
            // formData.append('Cts', this.state.Cts)
            // formData.append('Color', this.state.Color)
            // formData.append('Clarity', this.state.Clarity)
            // formData.append('Cut', this.state.Cut)
            // formData.append('Polish', this.state.Polish)
            // formData.append('Symm', this.state.Symm)
            // formData.append('Flu', this.state.Flu)
            // formData.append('FluColor', this.state.FluColor)
            // formData.append('CertificateFilename', this.state.CertificateFilename)
            // formData.append('DiamondImage', this.state.DiamondImage)
            // formData.append('TradeShow', this.state.TradeShow)
            // formData.append('RapRate', this.state.RapRate)
            // formData.append('RapAmt', this.state.RapAmt)
            // formData.append('Disc', this.state.Disc)
            // formData.append('NetRate', this.state.NetRate)
            // formData.append('NetAmt', this.state.NetAmt)
            // formData.append('CashPrice', this.state.CashPrice)
            // formData.append('CashDisc', this.state.CashDisc)
            // formData.append('Measurements', this.state.Measurements)
            // formData.append('Lab', this.state.Lab)
            // formData.append('Table', this.state.Table)
            // formData.append('Depth', this.state.Depth)
            // formData.append('ReportNo', this.state.ReportNo)
            // formData.append('KeyToSymbol', this.state.KeyToSymbol)
            // formData.append('ReportComment', this.state.ReportComment)
            // formData.append('MemberComment', this.state.MemberComment)
            // formData.append('Milky', this.state.Milky)
            // formData.append('GirdleCondition', this.state.GirdleCondition)
            // formData.append('Girdle', this.state.Girdle)
            // formData.append('StarLength', this.state.StarLength)
            // formData.append('BlackInclusion', this.state.BlackInclusion)
            // formData.append('CenterInclusion', this.state.CenterInclusion)
            // formData.append('Shade', this.state.Shade)
            // formData.append('CrownAngle', this.state.CrownAngle)
            // formData.append('CrownHeight', this.state.CrownHeight)
            // formData.append('PavilionAngle', this.state.PavilionAngle)
            // formData.append('PavilionHeight', this.state.PavilionHeight)
            // formData.append('CuletSize', this.state.CuletSize)
            // formData.append('CuletCondition', this.state.CuletCondition)
            // formData.append('ReportDate', this.state.ReportDate)
            // formData.append('Remark', this.state.Remark)
            // formData.append('GirdleThin', this.state.GirdleThin)
            // formData.append('GirdleThick', this.state.GirdleThick)
            // formData.append('Brand', this.state.Brand)

            // const config = {
            //     headers: {
            //         'content-type': 'multipart/form-data'
            //     }
            // }
            Axios.post(`/users/updateInward/${this.props.match.params.id}`, this.state).then((res) => {
                swal('Update SuccessFully', '', 'success')
            })
        }
    }
    handleFileDiamond(e) {
        this.setState({
            DiamondImage: e.target.files[0]
        })
    }
    handleChange(event) {
     
        this.setState({

            partyID: event.target.value,
            uploadPercentage: this.state.uploadPercentage,


        })
      
    }
    handleControls(event) {
        this.setState({
            [event.target.name]: event.target.value
        })
    }
    handleFile(event) {

        this.setState({
            inwardImport: event.target.files[0]
        })
    }
    handleSubmit(event) {
        event.preventDefault()
        Axios.get('/getInwardID').then(res => {
 
            this.setState({
                inwID: res.data.inwID
            })
        })

        const formData = new FormData()
        formData.append('inwardImport', this.state.inwardImport)
        formData.append('inwID', this.state.inwID)

        Axios.post('/CsvHeaderRemove', formData, {
            onUploadProgress: ProgressEvent => {
                //  const {loaded,total}=ProgressEvent
                let percent = Math.round(ProgressEvent.loaded / ProgressEvent.total * 100)
        

                if (percent < 100) {
                    this.setState({ uploadPercentage: percent })
                }
            }
        }).then((response) => {

            if (response.data.msg) {
                this.setState({ uploadPercentage: 100 })
                swal("File has been imported", {
                    button: false,
                    timer: 1500,
                    icon: 'success'
                });
            }
            this.setState({ uploadPercentage: 100 }, () => {
                setTimeout(() => {
                    this.setState({ uploadPercentage: 0 })

                }, 1000)
            })
        })


    }
    componentDidMount() {

    }


    render() {

        return (
            <div>
                <div className="row">

                    <div className="col-md-2">
                        <label className="btn btn-outline-secondary "><i className="fa fa-download"></i> Import Data
                                                                <input type="file" id="actual-btn" style={{ display: "none" }} name="inwardImport" onChange={this.handleFile} /></label>
                    </div>
                    <div className="col-md-6">
                        <input type="submit" className="btn btn-info " style={{ fontSize: "1rem" }} value="Add Inward"></input>
                    </div>
                </div>
            </div >
        );
    }
}



export default AddInward;
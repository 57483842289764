import Axios from 'axios';
import React, { Component } from 'react';
import Header from '../../Headers/Header';
import swal from 'sweetalert'
import axios from 'axios';
import $ from 'jquery'
import { Link } from 'react-router-dom'


class UpdateFormat extends Component {
    constructor(props) {
        super(props)
        this.state = {
            storedata: [],
            formatname: '',
            formatFields: '',
            viewFormat: [],
            arrayFields: [],
            array: [],
            array2: []

        }
        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleTextChange = this.handleTextChange.bind(this)

    }

    handleTextChange(e) {
        this.setState({
            formatname: e.target.value
        })
    }
    handleChange(e) {



        var array1 = []
        $("input:checkbox:checked").each(function () {
            array1.push($(this).val());
        });

        this.setState({
            array: array1
        })



    }

    handleSubmit(e) {
        e.preventDefault()


        if (this.state.array !== "") {
            this.setState({
                array2: this.state.array.toString()
            })
        }
        else {
            this.setState({
                array2: this.state.formatFields
            })
        }
      

        Axios.post(`/users/updateFormat/${this.props.match.params.id}`, this.state).then((res) => {
         
            swal("Format Updated", {
                button: false,
                timer: 1500,
                icon: 'success'
            })
            this.props.history.push("/Formvisibility")
        })

    }
    componentDidMount() {
       
        axios.get(`/users/getFormatById/${this.props.match.params.id}`).then(res => {
            this.setState({
                //viewFormat: res.data
                formatname: res.data.FormatName,
                formatFields: res.data.FormatFields,


            })
            this.setState({
                arrayFields: this.state.formatFields.split(",")
            })


            
            this.state.viewFormat.forEach(data => {
              
            })

            var array1 = []
            this.state.arrayFields.forEach(data => {
                $("input[type=checkbox][value='" + data + "']").prop("checked", true);
            })

            $("input:checkbox:checked").each(function () {
                array1.push($(this).val());
            });



        })

    }
    deleteFormat(e, id) {

        e.preventDefault()
        swal({
            title: "Are you sure?",
            text: "You want to delete this record?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {

                    axios.get(`/users/delFormat/${id}`).then(res => {
                        swal("Record Deleted!", "", "success")
                        this.componentDidMount()
                    })

                } else {
                    swal("Canceled");
                }
            });
    }
    render() {

        return (
            <div>
                <Header />
                <div className="wrapper">
                    <div className="content-wrapper">
                        <section className="content-header">
                            <div className="container-fluid">
                                <Link to="/Formvisibility" className="btn btn-info"><i class="fas fa-chevron-left"></i>&nbsp; Back</Link>
                                <div className="row mb-2" style={{ marginTop: "5px" }}>
                                    <div className="col-sm-6">
                                        <h1>Update Format</h1>
                                    </div>
                                    <div className="col-sm-6">
                                        <ol className="breadcrumb float-sm-right">
                                            <li className="breadcrumb-item"><a href="#" style={{ color: "#17a2b8" }}>Home</a></li>
                                            <li className="breadcrumb-item active">Update Format</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="container">
                            <div className="container-fluid">
                                <form onSubmit={this.handleSubmit}>

                                    <div className="row">
                                        <div className="col-md-3">
                                            <input type="text" name="formatname" className="form-control" value={this.state.formatname} onChange={this.handleTextChange} />
                                        </div>
                                        <div className="col-md-9 ">


                                            <label ><input type="checkbox" value="jsSrNo" onChange={this.handleChange} /><span style={{ margin: "10px" }}>SrNo</span></label>
                                            <label><input type="checkbox" value="jsStock" onChange={this.handleChange} /><span style={{ margin: "10px" }}>Stock</span></label>
                                            <label><input type="checkbox" value="jsVendorStock" onChange={this.handleChange} /><span style={{ margin: "10px" }}>VendorStock</span></label>
                                            <label><input type="checkbox" value="jsVendorName" onChange={this.handleChange} /><span style={{ margin: "10px" }}>VendorName</span></label>
                                            <label><input type="checkbox" value="jsAvailability" onChange={this.handleChange} /><span style={{ margin: "10px" }}>Availability</span></label>
                                            <label><input type="checkbox" value="jsShape" onChange={this.handleChange} /><span style={{ margin: "10px" }}>Shape</span></label>
                                            <label><input type="checkbox" value="jsWeight" onChange={this.handleChange} /><span style={{ margin: "10px" }}>Weight</span></label>
                                            <label><input type="checkbox" value="jsColor" onChange={this.handleChange} /><span style={{ margin: "10px" }}>Color</span></label>
                                            <label><input type="checkbox" value="jsClarity" onChange={this.handleChange} /><span style={{ margin: "10px" }}>Clarity</span></label>
                                            <label><input type="checkbox" value="jsCutGrade" onChange={this.handleChange} /><span style={{ margin: "10px" }}>CutGrade</span></label>
                                            <label><input type="checkbox" value="jsPolish" onChange={this.handleChange} /><span style={{ margin: "10px" }}>Polish</span></label>
                                            <label><input type="checkbox" value="jsSymmentry" onChange={this.handleChange} /><span style={{ margin: "10px" }}>Symmentry</span></label>
                                            <label><input type="checkbox" value="jsFluorescenceIntensity" onChange={this.handleChange} /><span style={{ margin: "10px" }}>FluorescenceIntensity</span></label>
                                            <label><input type="checkbox" value="jsFluorescenceColor" onChange={this.handleChange} /><span style={{ margin: "10px" }}>FluorescenceColor</span></label>
                                            <label><input type="checkbox" value="jsMeasurements" onChange={this.handleChange} /><span style={{ margin: "10px" }}>Measurements</span></label>
                                            <label><input type="checkbox" value="jsLab" onChange={this.handleChange} /><span style={{ margin: "10px" }}>Lab</span></label>
                                            <label><input type="checkbox" value="jsCertificate" onChange={this.handleChange} /><span style={{ margin: "10px" }}>Certificate</span></label>
                                            <label><input type="checkbox" value="jsTreatment" onChange={this.handleChange} /><span style={{ margin: "10px" }}>Treatment</span></label>
                                            <label><input type="checkbox" value="jsRap" onChange={this.handleChange} /><span style={{ margin: "10px" }}>Rap</span></label>

                                            <label><input type="checkbox" value="jsVendorDiscount" onChange={this.handleChange} /><span style={{ margin: "10px" }}>VendorDiscount</span></label>
                                            <label><input type="checkbox" value="jsRapnetDiscount" onChange={this.handleChange} /><span style={{ margin: "10px" }}>RapnetDiscount</span></label>
                                            <label><input type="checkbox" value="jsRapNetPrice" onChange={this.handleChange} /><span style={{ margin: "10px" }}>RapNetPrice</span></label>
                                            <label><input type="checkbox" value="jsNetValue" onChange={this.handleChange} /><span style={{ margin: "10px" }}>NetValue</span></label>
                                            <label><input type="checkbox" value="jsCashPrice" onChange={this.handleChange} /><span style={{ margin: "10px" }}>CashPrice</span></label>
                                            <label><input type="checkbox" value="jsCashPriceDiscount" onChange={this.handleChange} /><span style={{ margin: "10px" }}>CashPriceDiscount</span></label>
                                            <label><input type="checkbox" value="jsFancyColor" onChange={this.handleChange} /><span style={{ margin: "10px" }}>FancyColor</span></label>
                                            <label><input type="checkbox" value="jsFancyColorIntensity" onChange={this.handleChange} /><span style={{ margin: "10px" }}>FancyColorIntensity</span></label>
                                            <label><input type="checkbox" value="jsFancyColorOvertone" onChange={this.handleChange} /><span style={{ margin: "10px" }}>FancyColorOvertone</span></label>
                                            <label><input type="checkbox" value="jsDepth" onChange={this.handleChange} /><span style={{ margin: "10px" }}>Depth</span></label>
                                            <label><input type="checkbox" value="jsTable" onChange={this.handleChange} /><span style={{ margin: "10px" }}>Table</span></label>
                                            <label><input type="checkbox" value="jsGirdleThin" onChange={this.handleChange} /><span style={{ margin: "10px" }}>GirdleThin</span></label>
                                            <label><input type="checkbox" value="jsGirdleThick" onChange={this.handleChange} /><span style={{ margin: "10px" }}>GirdleThick</span></label>
                                            <label><input type="checkbox" value="jsGirdle" onChange={this.handleChange} /><span style={{ margin: "10px" }}>Girdle</span></label>
                                            <label><input type="checkbox" value="jsGirdleCondition" onChange={this.handleChange} /><span style={{ margin: "10px" }}>GirdleCondition</span></label>
                                            <label><input type="checkbox" value="jsCuletSize" onChange={this.handleChange} /><span style={{ margin: "10px" }}>CuletSize</span></label>
                                            <label><input type="checkbox" value="jsCuletCondition" onChange={this.handleChange} /><span style={{ margin: "10px" }}>CuletCondition</span></label>
                                            <label><input type="checkbox" value="jsCrownHeight" onChange={this.handleChange} /><span style={{ margin: "10px" }}>CrownHeight</span></label>
                                            <label><input type="checkbox" value="jsCrownAngle" onChange={this.handleChange} /><span style={{ margin: "10px" }}>CrownAngle</span></label>
                                            <label><input type="checkbox" value="jsPavilionDepth" onChange={this.handleChange} /><span style={{ margin: "10px" }}>PavilionDepth</span></label>
                                            <label><input type="checkbox" value="jsPavilionAngle" onChange={this.handleChange} /><span style={{ margin: "10px" }}>PavilionAngle</span></label>
                                            <label><input type="checkbox" value="jsLaserInscription" onChange={this.handleChange} /><span style={{ margin: "10px" }}>LaserInscription</span></label>
                                            <label><input type="checkbox" value="jsCertcomment" onChange={this.handleChange} /><span style={{ margin: "10px" }}>CertComment</span></label>
                                            <label><input type="checkbox" value="jsCountry" onChange={this.handleChange} /><span style={{ margin: "10px" }}>Country</span></label>
                                            <label><input type="checkbox" value="jsState" onChange={this.handleChange} /><span style={{ margin: "10px" }}>State</span></label>
                                            <label><input type="checkbox" value="jsCity" onChange={this.handleChange} /><span style={{ margin: "10px" }}>City</span></label>
                                            <label><input type="checkbox" value="jsTimetolocation" onChange={this.handleChange} /><span style={{ margin: "10px" }}>Timetolocation</span></label>
                                            <label><input type="checkbox" value="jsIsMatchedPairSeparable" onChange={this.handleChange} /><span style={{ margin: "10px" }}>IsMatchedPairSeparable</span></label>
                                            <label><input type="checkbox" value="jsPairStock" onChange={this.handleChange} /><span style={{ margin: "10px" }}>PairStock</span></label>
                                            <label><input type="checkbox" value="jsAllowRapLinkFeed" onChange={this.handleChange} /><span style={{ margin: "10px" }}>AllowRapLinkFeed</span></label>
                                            <label><input type="checkbox" value="jsParcelStones" onChange={this.handleChange} /><span style={{ margin: "10px" }}>ParcelStones</span></label>
                                            <label><input type="checkbox" value="jscertificateFilename" onChange={this.handleChange} /><span style={{ margin: "10px" }}>CertificateFilename</span></label>
                                            <label><input type="checkbox" value="jsDiamondImage" onChange={this.handleChange} /><span style={{ margin: "10px" }}>DiamondImage</span></label>
                                            <label><input type="checkbox" value="jsSarinLoupe" onChange={this.handleChange} /><span style={{ margin: "10px" }}>SarinLoupe</span></label>
                                            <label><input type="checkbox" value="jsTradeShow" onChange={this.handleChange} /><span style={{ margin: "10px" }}>TradeShow</span></label>
                                            <label><input type="checkbox" value="jsKeytosymbols" onChange={this.handleChange} /><span style={{ margin: "10px" }}>Keytosymbols</span></label>
                                            <label><input type="checkbox" value="jsShade" onChange={this.handleChange} /><span style={{ margin: "10px" }}>Shade</span></label>
                                            <label><input type="checkbox" value="jsStarLength" onChange={this.handleChange} /><span style={{ margin: "10px" }}>StarLength</span></label>
                                            <label><input type="checkbox" value="jsCenterInclusion" onChange={this.handleChange} /><span style={{ margin: "10px" }}>CenterInclusion</span></label>
                                            <label><input type="checkbox" value="jsBlackInclusion" onChange={this.handleChange} /><span style={{ margin: "10px" }}>BlackInclusion</span></label>
                                            <label><input type="checkbox" value="jsMemberComment" onChange={this.handleChange} /><span style={{ margin: "10px" }}>MemberComment</span></label>
                                            <label><input type="checkbox" value="jsReportIssueDate" onChange={this.handleChange} /><span style={{ margin: "10px" }}>ReportIssueDate</span></label>
                                            <label><input type="checkbox" value="jsReportType" onChange={this.handleChange} /><span style={{ margin: "10px" }}>ReportType</span></label>
                                            <label><input type="checkbox" value="jsLabLocation" onChange={this.handleChange} /><span style={{ margin: "10px" }}>LabLocation</span></label>
                                            <label><input type="checkbox" value="jsBrand" onChange={this.handleChange} /><span style={{ margin: "10px" }}>Brand</span></label>
                                            <label><input type="checkbox" value="jsMilky" onChange={this.handleChange} /><span style={{ margin: "10px" }}>Milky</span></label>
                                            <label><input type="checkbox" value="jsDPerCarat" onChange={this.handleChange} /><span style={{ margin: "10px" }}>DPerCarat</span></label>
                                            <label><input type="checkbox" value="jsFinalPrice" onChange={this.handleChange} /><span style={{ margin: "10px" }}>FinalPrice</span></label>
                                            <label><input type="checkbox" value="jsPriceFromRapnet" onChange={this.handleChange} /><span style={{ margin: "10px" }}>PriceFromRapnet</span></label>
                                            <label><input type="checkbox" value="jsRapnetDiscMarkup" onChange={this.handleChange} /><span style={{ margin: "10px" }}>RapnetDiscMarkup</span></label>
                                        </div>
                                    </div>

                                    <div className="pt-3">
                                        <center>
                                            <button type="submit" className="btn btn-info">Update</button>
                                        </center>
                                    </div>
                                </form>
                            </div>
                        </section>

                    </div>
                </div>




            </div >
        );
    }
}

export default UpdateFormat;
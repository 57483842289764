import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom'
import { withRouter } from 'react-router'
import axios from 'axios'
import swal from 'sweetalert';
import { token } from 'morgan';
import {debounce} from 'lodash'


class Header extends Component {

    
    constructor(props) {
        var intervalID;
        super(props);
        this.state = {
            UserName: localStorage.getItem('user'),
            redirect: '',
            token: localStorage.getItem('token'),
          
          
        }
        this.logout = this.logout.bind(this)
        this.resetCount=this.resetCount.bind(this)
 
    }
    logout(e) {
        localStorage.removeItem('user')
        localStorage.removeItem('token')
        this.setState({
            redirect: '/'
        })
    }
   
    resetCount(e)
    {
        // this.setState({
        //     totalNotification:0
        // })
    }
  
    componentDidMount() {
        // console.log("USERNAME IN HEADER "+localStorage.getItem('user'))
        if (this.state.token == "" || this.state.UserName == "") {
            this.props.history.push("/")
        }
       
       // setInterval(this.notification,5000)           
    }
    render() {
        const { UserName } = this.state
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }
        return (
            <div className="wrapper">
                <nav className="main-header navbar navbar-expand navbar-white navbar-light">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <a className="nav-link" data-widget="pushmenu" href="#" role="button"><i class="fas fa-bars"></i></a>
                        </li>
                    </ul>
                    <ul className="navbar-nav ml-auto">
                      
                        <li className="nav-item">
                            <a className="nav-link" data-widget="fullscreen" href="#" role="button">
                                <i className="fas fa-expand-arrows-alt"></i>
                            </a>
                        </li>
                        <li className="nav-item dropdown">

                            <a id="dropdownSubMenu1" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="nav-link dropdown-toggle "><b style={{ fontFamily: "-webkit-pictograph", fontSize: "15px" }}>{UserName}</b>&nbsp;<img src="../../dist/img/UserImage.png" width="30px" /></a>
                            <Link onClick={e => this.logout(e)}>
                                <div className="dropdown-menu dropdown-menu-right">
                                    <span style={{ color: 'black', padding: '40px' }}><i class="fas fa-power-off"></i>&nbsp;<b>Logout</b></span>

                                </div>
                            </Link>
                        </li>
                    </ul>
                </nav>
                
                <aside className="main-sidebar sidebar-dark-primary elevation-4">
                    <Link to="/adminHome" className="brand-link">
                        <img src="../../dist/img/favicon.png" alt="AdminLTE Logo" className="brand-image img-circle " style={{ opacity: ".8" }} />
                        <span className="brand-text font-weight-light"> SHREE DIAMOND </span>
                        {/* <span><a className="nav-link" data-widget="pushmenu" href="#" role="button"><i className="fa fa-arrow-left"></i></a></span> */}
                    </Link>
                    <div className="sidebar">

                        {/* <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                            <div className="image">
                            <img src="./dist/img/user2-160x160.jpg" className="img-circle elevation-2" alt="User Image"/>
                            </div>
                            <div className="info">
                            <a href="#" className="d-block">Alexander Pierce</a>
                            </div>
                        </div> */}

                        <div className="form-inline">
                                
                        </div>
                        <nav className="mt-2">
                            <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="true">
                                <li className="nav-item">
                                    <a class="nav-link" id="pushbutton" data-widget="pushmenu" href="#" role="button"><i class="fas fa-hand-point-left" style={{ fontSize: "25px" }}></i></a>
                                </li>
                                <li className="nav-item">
                                    <Link to="/adminHome" className="nav-link">
                                        <i className="nav-icon fas fa-columns"></i>
                                        <p>
                                            Dashboard
                                        </p>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/ViewCustomer" className="nav-link">
                                        <i className="fa fa-user nav-icon"></i>
                                        <p>Customer</p>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/viewApiRequest" className="nav-link">
                                        <i className="fa fa-paper-plane nav-icon"></i>
                                        <p>Requests</p>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/ViewInward" className="nav-link">
                                        <i className="far fa-gem nav-icon"></i>
                                        <p>Inward</p>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/viewParty" className="nav-link">
                                        <i className="fas fa-users nav-icon"></i>
                                        <p>Supplier</p>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/ViewOrder" className="nav-link">
                                        <i class="fas fa-cart-plus nav-icon"></i>
                                        <p>Order</p>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/ViewRapnet" className="nav-link">
                                        <i className="fas fa-upload nav-icon"></i>
                                        <p>Upload Rap</p>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/FormVisibility" className="nav-link">
                                        <i className="fas fa-low-vision nav-icon"></i>
                                        <p >Format Column</p>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/MasterEntry" className="nav-link">
                                        <i class="fas fa-database nav-icon"></i>
                                        <p >Master Entry</p>
                                    </Link>
                                </li>

                                <li className="nav-item">
                                    <Link to="/fetchapi" className="nav-link">
                                        <i class="fa fa-search-plus nav-icon" aria-hidden="true"></i>
                                        <p >Fetch API</p>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/testing" className="nav-link">
                                        <i class="fas fa-check nav-icon"></i>
                                        <p>Testing</p>
                                    </Link>
                                </li>
                            </ul>
                        </nav>

                    </div>

                </aside>
                {/* <footer class="main-footer">
                    <div class="float-right d-none d-sm-block">

                    </div>
                    <strong>Developed by:  <a href="http://voidek.in" target="_blank">Voidek Webolutions</a>.</strong>
                </footer> */}
            </div >


        );
    }
}


export default Header
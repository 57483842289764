import Axios from 'axios';
import React, { Component } from 'react';
import swal from 'sweetalert';
import Header from '../../Headers/Header';
import $ from 'jquery'

class MappingPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            mappingpage: '',
            data: [],
            pushdata: []


        }
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleFile = this.handleFile.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleClick = this.handleClick.bind(this)
    }
    handleClick(event) {
        // event.preventDefault()
        const formData = new FormData()
        formData.append('mappingpage', this.state.mappingpage)
        Axios.post(`/mapping?arraydata=${this.state.pushdata}`, formData).then((res) => {
            if (res.data.msg) {
                swal("Import Success", '', 'success')
            }
        })
    }
    handleChange(event) {
        var values = []
        var val1 = $("select[name=\'min_select[]\']").each(function (i, sel) {
            var selectedVal = $(sel).val();
            values.push(selectedVal);
        });
        this.state.pushdata = values
     




    }
    handleFile(event) {

        this.setState({
            mappingpage: event.target.files[0]
        })
    }
    handleSubmit(e) {
        e.preventDefault()
        const formData = new FormData()
        formData.append(`mappingpage`, this.state.mappingpage)

        Axios.post('/mappingpage', formData).then((res) => {
         
            this.setState({
                data: res.data
            })
           
        })
    }
    render() {
        return (
            <div>
                <Header />
                <div className="wrapper">
                    <div className="content-wrapper">
                        <section className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-2">
                                    <div className="col-sm-6">
                                        <h1>Mapping Page </h1>
                                    </div>
                                    <div className="col-sm-6">
                                        <ol className="breadcrumb float-sm-right">
                                            <li className="breadcrumb-item"><a href="#" style={{ color: "#17a2b8" }}>Home</a></li>
                                            <li className="breadcrumb-item active">Mapping Page</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="container">
                            <div className="container-fluid">

                                <input type="file" name="mappingpage" style={{ width: "30%" }} onChange={this.handleFile} />
                                <button type="submit" className="btn btn-primary mt-2" onClick={this.handleSubmit} name="btnSubmit" >Import</button>


                                <div className="row pt-2" >
                                    <div className="col-md-3">
                                        {
                                            this.state.data.map((view) => (
                                                <input type="text" className="form-control" value={view} readOnly />

                                            ))
                                        }
                                    </div>
                                    <div className="col-md-3">
                                        {
                                            this.state.data.map((view) => (
                                                // <input type="text" className="form-control" value={view} readOnly />
                                                <select id="mapping" className="form-control" name="min_select[]" onChange={this.handleChange}>
                                                    <option selected disabled>Select</option>
                                                    <option value="SrNo">Sr No</option>
                                                    <option value="Stock">Stock #</option>
                                                    <option value="VendorStock">Vendor Stock #</option>
                                                    <option value="PartyName">Vendor Name</option>
                                                    <option value="Availability">Availability</option>
                                                    <option value="Shape">Shape</option>
                                                    <option value="Weight">Weight</option>
                                                    <option value="Color">Color</option>
                                                    <option value="Clarity">Clarity</option>
                                                    <option value="CutGrade">Cut Grade</option>
                                                    <option value="Polish">Polish</option>
                                                    <option value="Symmetry">Symmetry</option>
                                                    <option value="FluorescenceIntensity">Fluorescence Intensity</option>
                                                    <option value="FluorescenceColor">Fluorescence Color</option>
                                                    <option value="Measurements">Measurements</option>
                                                    <option value="Lab">Lab</option>
                                                    <option value="Certificate">Certificate</option>
                                                    <option value="Treatment">Treatment</option>
                                                    <option value="Rap">Rap</option>
                                                    <option value="RapAmt">Rap Amt</option>
                                                    <option value="RapnetDiscount">Rapnet Discount</option>
                                                    <option value="RapNetPrice">Rap Net Price</option>
                                                    <option value="NetValue">Net Value</option>
                                                    <option value="CashPrice">Cash Price</option>
                                                    <option value="CashPriceDiscount">Cash Price Discount</option>
                                                    <option value="FancyColor">Fancy Color</option>
                                                    <option value="FancyColorIntensity">Fancy Color Intensity</option>
                                                    <option value="FancyColorOvertone">Fancy Color Overtone</option>
                                                    <option value="Depth">Depth</option>
                                                    <option value="Table">Table</option>
                                                    <option value="GirdleThin">GirdleThin</option>
                                                    <option value="GirdleThick">Girdle Thick</option>
                                                    <option value="Girdle">Girdle</option>
                                                    <option value="GirdleCondition">Girdle Condition</option>
                                                    <option value="CuletSize">Culet Size</option>
                                                    <option value="CuletCondition">Culet Condition</option>
                                                    <option value="CrownHeight">Crown Height</option>
                                                    <option value="CrownAngle">Crown Angle</option>
                                                    <option value="PavilionDepth">Pavilion Depth</option>
                                                    <option value="PavilionAngle">Pavilion Angle</option>
                                                    <option value="LaserInscription">Laser Inscription</option>
                                                    <option value="Certcomment">Cert comment</option>
                                                    <option value="Country">Country</option>
                                                    <option value="State">State</option>
                                                    <option value="City">City</option>
                                                    <option value="Timetolocation">Timetolocation</option>
                                                    <option value="IsMatchedPairSeparable">Is Matched Pair Separable</option>
                                                    <option value="PairStock">Pair Stock</option>
                                                    <option value="AllowRapLinkFeed">Allow Rap Link Feed</option>
                                                    <option value="ParcelStones">Parcel Stones</option>
                                                    <option value="CertificateFilename">Certificate Filename</option>
                                                    <option value="DiamondImage">Diamond Image</option>
                                                    <option value="SarinLoupe">SarinLoupe</option>
                                                    <option value="TradeShow">TradeShow</option>
                                                    <option value="Keytosymbols">Key to symbols</option>
                                                    <option value="Shade">Shade</option>
                                                    <option value="StarLength">Star Length</option>
                                                    <option value="CenterInclusion">Center Inclusion</option>
                                                    <option value="BlackInclusion">Black Inclusion</option>
                                                    <option value="MemberComment">Member Comment</option>
                                                    <option value="ReportIssueDate">Report Issue Date</option>
                                                    <option value="ReportType">ReportType</option>
                                                    <option value="LabLocation">LabLocation</option>
                                                    <option>Roundand</option>
                                                    <option value="Milky">Milky</option>
                                                </select>
                                            ))
                                        }
                                    </div>

                                </div>
                                <div className="row mt-2">

                                    <div className="col-md-3"></div>
                                    <div className="col-md-3 ">
                                        {this.state.data == '' ? '' : <button type="submit" className="btn btn-success" onClick={this.handleClick} >Save</button>}
                                    </div>

                                </div>


                            </div>
                        </section>
                    </div>
                </div>
            </div>
        );
    }
}

export default MappingPage;
import React, { Component } from 'react';
import Axios from 'axios';
import axios from 'axios';
import swal from 'sweetalert';
import Header from '../../Headers/Header';
import { Link } from 'react-router-dom';

class ViewParty extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewParty: [],
    };
  }
  handleDelete(e, id) {
    e.preventDefault();
    swal({
      title: 'Are you sure?',
      text: 'Once deleted, you will not be able to recover this imaginary file!',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        Axios.get(`/users/delete/${id}`).then((res) => {
          swal('Deleted Successfully', '', 'success');
        });
        this.viewParty();
      } else {
        swal('Canceled');
      }
    });
  }
  componentDidMount() {
    axios.get('/users/ViewPartyDetails').then((res) => {
      this.setState({
        viewParty: res.data,
      });
      const script = document.createElement('script');
      script.src = `js/content.js`;
      script.async = true;
      document.body.appendChild(script);
    });
  }
  viewParty() {
    axios.get('/users/ViewPartyDetails').then((res) => {
      this.setState({
        viewParty: res.data,
      });
    });
  }
  InActiveParty(e, id) {
    axios.get(`/users/InActivePartyData/${id}`).then((res) => {
      swal('Party Deactivated', '', 'success');
      this.componentDidMount();
    });
  }
  ActiveParty(e, id) {
    axios.get(`/users/ActivePartyData/${id}`).then((res) => {
      swal('Party Activated', '', 'success');
      this.componentDidMount();
    });
  }
  render() {
    const { viewParty } = this.state;
    return (
      <div>
        <Header />
        <div className="wrapper">
          <div className="content-wrapper">
            <section className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-6">
                    <h1>Manage Supplier</h1>
                  </div>
                  <div className="col-sm-6">
                    <ol className="breadcrumb float-sm-right">
                      <li className="breadcrumb-item">
                        <a href="#" style={{ color: '#17a2b8' }}>
                          Home
                        </a>
                      </li>
                      <li className="breadcrumb-item active">Add Supplier</li>
                    </ol>
                  </div>
                </div>
              </div>
            </section>
            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <Link to="/addParty" className="btn btn-info">
                      <i className="fa fa-plus"></i>&nbsp;New Supplier
                    </Link>
                    <div className="card" style={{ marginTop: '2%' }}>
                      <div className="card-header">
                        <h3 className="card-title">View Supplier Details</h3>
                      </div>
                      <div className="card-body">
                        <div className="table-responsive">
                          <table
                            id="example1"
                            className="table table-bordered table-striped"
                          >
                            <thead>
                              <tr className="text-center">
                                <td>Active/Deactive</td>
                                <td>Modification</td>
                                <td>Delete</td>
                                <td>SupplierName</td>
                                <td>UserName</td>
                                <td>Date of Birth</td>
                                <td>DisplayName</td>
                                <td>Designation</td>
                                <td>Markup</td>
                                <td>Fancy Markup</td>
                                <td>UserBusiness</td>
                                <td>SkypeID</td>
                                <td>QQID</td>
                                <td>WeChatID</td>
                                <td>EmailID</td>
                                <td>CompanyName</td>
                                <td>Address1</td>
                                <td>Address2</td>
                                <td>Address3</td>
                                <td>Country</td>
                                <td>State</td>
                                <td>City</td>
                                <td>PinCode</td>
                                <td>STDCode</td>
                                <td>ISDCode</td>
                                <td>Mobile1</td>
                                <td>Mobile2</td>
                                <td>Phone1</td>
                                <td>Phone2</td>
                                <td>CompanyEmail</td>
                                <td>FaxNo</td>
                                <td>CompanyType</td>
                                <td>Website</td>
                                <td>Association1</td>
                                <td>Association2</td>
                                <td>Comment</td>
                                <td>Reference</td>
                                <td>Document Image</td>
                                <td>Narration</td>
                                <td>Company Registration Certificate</td>
                                <td>Narration</td>
                                <td>Mapping</td>
                              </tr>
                            </thead>
                            <tbody>
                              {viewParty.map((view) => (
                                <tr className="text-center">
                                  <td>
                                    {view.IsActive ? (
                                      <Link
                                        onClick={(e) =>
                                          this.InActiveParty(e, view._id)
                                        }
                                      >
                                        <span style={{ color: '#17a2b8' }}>
                                          <i className="fa fa-toggle-on"></i>
                                        </span>
                                        <span style={{ color: '#17a2b8' }}>
                                          &nbsp;<b>Activated</b>
                                        </span>
                                      </Link>
                                    ) : (
                                      <Link
                                        onClick={(e) =>
                                          this.ActiveParty(e, view._id)
                                        }
                                      >
                                        <span style={{ color: '#17a2b8' }}>
                                          <i
                                            className="fa fa-toggle-off"
                                            style={{ color: '#c93d3a' }}
                                          ></i>
                                        </span>
                                        <span style={{ color: '#c93d3a' }}>
                                          <b>&nbsp;Deactivated</b>
                                        </span>
                                      </Link>
                                    )}
                                  </td>
                                  <td>
                                    <Link to={`/addParty/${view._id}`}>
                                      <span style={{ color: '#17a2b8' }}>
                                        <i className="fa fa-edit"></i>
                                      </span>
                                    </Link>
                                  </td>
                                  <td>
                                    <Link
                                      onClick={(e) => {
                                        this.handleDelete(e, view._id);
                                      }}
                                    >
                                      <span style={{ color: '#17a2b8' }}>
                                        <i className="fa fa-trash"></i>
                                      </span>
                                    </Link>
                                  </td>
                                  <td>{view.partyName}</td>
                                  <td>{view.UserName}</td>
                                  <td>{view.DOB}</td>
                                  <td>{view.DisplayName}</td>
                                  <td>{view.Designation}</td>
                                  <td>{view.Markup}</td>
                                  <td>{view.FancyMarkup}</td>
                                  <td>{view.UserBusiness}</td>
                                  <td>{view.SkypeID}</td>
                                  <td>{view.QQID}</td>
                                  <td>{view.WeChatID}</td>
                                  <td>{view.EmailID}</td>
                                  <td>{view.CompanyName}</td>
                                  <td>{view.Address1}</td>
                                  <td>{view.Address2}</td>
                                  <td>{view.Address3}</td>
                                  <td>{view.Country}</td>
                                  <td>{view.State}</td>
                                  <td>{view.City}</td>
                                  <td>{view.PinCode}</td>
                                  <td>{view.STD_Code}</td>
                                  <td>{view.ISD_Code}</td>
                                  <td>{view.Mobile1}</td>
                                  <td>{view.Mobile2}</td>
                                  <td>{view.Phone1}</td>
                                  <td>{view.Phone2}</td>
                                  <td>{view.CompanyEmailID}</td>
                                  <td>{view.FaxNo}</td>
                                  <td>{view.CompanyType}</td>
                                  <td>{view.Website}</td>
                                  <td>{view.Assoc1}</td>
                                  <td>{view.Assoc2}</td>
                                  <td>{view.Comment}</td>
                                  <td>{view.Reference}</td>
                                  <td>{view.DImagePath}</td>
                                  <td>{view.Narration}</td>
                                  <td>{view.CImagePath}</td>
                                  <td>{view.CNarration}</td>

                                  <td>{view.MappingColumn + ','}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    );
  }
}

export default ViewParty;

import React, { Component } from 'react';
import axios from 'axios';
import swal from 'sweetalert';

class RegisterParty extends Component {
  constructor(props) {
    super(props);
    this.state = {
      partyName: '',
      DOB: '',
      UserName: '',
      DisplayName: '',
      Designation: '',
      Password: '',
      UserBusiness: '',
      SkypeID: '',
      QQID: '',
      EmailID: '',
      WeChatID: '',
      CompanyName: '',
      Address1: '',
      Address2: '',
      Address3: '',
      Country: '',
      State: '',
      City: '',
      PinCode: '',
      STD_Code: '',
      ISD_Code: '',
      Mobile1: '',
      Mobile2: '',
      Phone1: '',
      Phone2: '',
      CompanyEmailID: '',
      FaxNo: '',
      CompanyType: '',
      Website: '',
      Assoc1: '',
      Assoc2: '',
      Comment: '',
      Reference: '',
      DImagePath: '',
      DPhotoType: '',
      Narration: '',
      CPhotoType: '',
      CNarration: '',
      CImagePath: '',

      tc: false,
      file: null,
      errors: {
        FullName: '',
        Password: '',
        EmailID: '',
        Mobile1: '',
      },
      cid1: '',
      ssid: '',
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleFile = this.handleFile.bind(this);
    this.handleFile1 = this.handleFile1.bind(this);
  }

  handleSubmit(event) {
    event.preventDefault();

    if (this.state.DImagePath === '' && this.state.CImagePath === '') {
      this.setState({
        partyName: this.state.FullName,
        DOB: this.state.DOB,
        UserName: this.state.UserName,
        DisplayName: this.state.DisplayName,
        Designation: this.state.Designation,
        Password: this.state.Password,
        UserBusiness: this.state.UserBusiness,
        SkypeID: this.state.SkypeID,
        QQID: this.state.QQID,
        EmailID: this.state.EmailID,
        WeChatID: this.state.WeChatID,
        CompanyName: this.state.CompanyName,
        Address1: this.state.Address1,
        Address2: this.state.Address2,
        Address3: this.state.Address3,
        Country: this.state.Country,
        State: this.state.State,
        City: this.state.City,
        PinCode: this.state.PinCode,
        STD_Code: this.state.STD_Code,
        ISD_Code: this.state.ISD_Code,
        Mobile1: this.state.Mobile1,
        Mobile2: this.state.Mobile2,
        Phone1: this.state.Phone1,
        Phone2: this.state.Phone2,
        CompanyEmailID: this.state.CompanyEmailID,
        FaxNo: this.state.FaxNo,
        CompanyType: this.state.CompanyType,
        Website: this.state.Website,
        Assoc1: this.state.Assoc1,
        Assoc2: this.state.Assoc2,
        Comment: this.state.Comment,
        Reference: this.state.Reference,
        DPhotoType: this.state.DPhotoType,
        Narration: this.state.Narration,
        CPhotoType: this.state.CPhotoType,
        CNarration: this.state.CNarration,
      });
      axios
        .post('/registerwithoutImage', this.state)
        .then((res) => {
          if (res.data.errors) {
            swal('User Already Exists', {
              buttons: false,
              timer: 1500,
              icon: 'error',
            });
          } else {
            swal('Data Inserted', {
              buttons: false,
              timer: 1500,
              icon: 'success',
            });
          }
        })
        .catch((err) => {});
    } else {
      const formData = new FormData();
      formData.append('partyName', this.state.partyName);
      formData.append('DOB', this.state.DOB);
      formData.append('UserName', this.state.UserName);
      formData.append('DisplayName', this.state.DisplayName);
      formData.append('Designation', this.state.Designation);
      formData.append('Password', this.state.Password);
      formData.append('UserBusiness', this.state.UserBusiness);
      formData.append('SkypeID', this.state.SkypeID);
      formData.append('QQID', this.state.QQID);
      formData.append('EmailID', this.state.EmailID);
      formData.append('WeChatID', this.state.WeChatID);
      formData.append('CompanyName', this.state.CompanyName);
      formData.append('Address1', this.state.Address1);
      formData.append('Address2', this.state.Address2);
      formData.append('Address3', this.state.Address3);
      formData.append('Country', this.state.Country);
      formData.append('State', this.state.State);
      formData.append('City', this.state.City);
      formData.append('PinCode', this.state.PinCode);
      formData.append('STD_Code', this.state.STD_Code);
      formData.append('ISD_Code', this.state.ISD_Code);
      formData.append('Mobile1', this.state.Mobile1);
      formData.append('Mobile2', this.state.Mobile2);
      formData.append('Phone1', this.state.Phone1);
      formData.append('Phone2', this.state.Phone2);
      formData.append('CompanyEmailID', this.state.CompanyEmailID);
      formData.append('FaxNo', this.state.FaxNo);
      formData.append('CompanyType', this.state.CompanyType);
      formData.append('Website', this.state.Website);
      formData.append('Assoc1', this.state.Assoc1);
      formData.append('Assoc2', this.state.Assoc2);
      formData.append('Comment', this.state.Comment);
      formData.append('Reference', this.state.Reference);
      formData.append('DImagePath', this.state.DImagePath);
      formData.append('DPhotoType', this.state.DPhotoType);
      formData.append('Narration', this.state.Narration);
      formData.append('CPhotoType', this.state.CPhotoType);
      formData.append('CNarration', this.state.CNarration);
      formData.append('CImagePath', this.state.CImagePath);
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };
      axios
        .post('/register', formData, config)
        .then((res) => {
          if (res.data.FileError) {
            swal(res.data.FileError, '', 'error');
          }
          if (res.data.errors) {
            swal(res.data.errors, '', 'error');
          } else {
            swal('Data Inserted', '', 'success');
          }
        })
        .catch((err) => {});
    }
  }
  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }
  handleFile(event) {
    this.setState({
      DImagePath: event.target.files[0],
    });
  }
  handleFile1(event) {
    this.setState({
      CImagePath: event.target.files[0],
    });
  }
  componentDidMount() {}

  render() {
    const {
      errors,
      partyName,
      DOB,
      UserName,
      DisplayName,
      Designation,
      Password,
      UserBusiness,
      SkypeID,
      QQID,
      EmailID,
      WeChatID,
      CompanyName,
      Address1,
      Address2,
      Address3,
      City,
      PinCode,
      STD_Code,
      ISD_Code,
      Mobile1,
      Mobile2,
      Phone1,
      Phone2,
      CompanyEmailID,
      FaxNo,
      CompanyType,
      Website,
      Assoc1,
      Assoc2,
      Comment,
      Reference,
      DPhotoType,
      Narration,
      CPhotoType,
      CNarration,
      Country,
      State,
    } = this.state;
    return (
      <div>
        <div className="container">
          <div className="divider mrg25T mrg25B"></div>
          <div className="card">
            <div className="card-header">
              <div className="card-title">Registration</div>
            </div>
            <div className="card-body">
              <form onSubmit={this.handleSubmit}>
                <div className="row">
                  <div className="col-md-4">
                    <div className="row marginn">
                      <div className="col-md-4">
                        <label>SupplierName*</label>
                      </div>
                      <div className="col-md-8">
                        <input
                          type="text"
                          className="form-control "
                          name="partyName"
                          value={partyName}
                          onChange={this.handleChange}
                          required
                        />
                        {errors.FullName.length > 0 && (
                          <span className="label-danger">
                            {errors.FullName}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="row marginn">
                      <div className="col-md-4">
                        <label>User Name*</label>
                      </div>
                      <div className="col-md-8">
                        <input
                          type="text"
                          className="form-control"
                          name="UserName"
                          value={UserName}
                          onChange={this.handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="row marginn">
                      <div className="col-md-4">
                        <label>Display Name</label>
                      </div>
                      <div className="col-md-8">
                        <input
                          type="text"
                          className="form-control"
                          name="DisplayName"
                          value={DisplayName}
                          onChange={this.handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="row marginn">
                      <div className="col-md-4">
                        <label>Designation</label>
                      </div>
                      <div className="col-md-8">
                        <input
                          type="text"
                          className="form-control"
                          name="Designation"
                          value={Designation}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>

                    <div className="row marginn">
                      <div className="col-md-4">
                        <label>Date of Birth*</label>
                      </div>
                      <div className="col-md-8">
                        <input
                          type="date"
                          className="form-control"
                          name="DOB"
                          value={DOB}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div className="row marginn">
                      <div className="col-md-4">
                        <label>Email Address</label>
                      </div>
                      <div className="col-md-8">
                        <input
                          type="email"
                          className="form-control"
                          name="EmailID"
                          value={EmailID}
                          onChange={this.handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="row marginn">
                      <div className="col-md-4">
                        <label>Password</label>
                      </div>
                      <div className="col-md-8">
                        <input
                          type="password"
                          className="form-control"
                          name="Password"
                          value={Password}
                          onChange={this.handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="row marginn">
                      <div className="col-md-4">
                        <label>User Business</label>
                      </div>
                      <div className="col-md-8">
                        <select
                          className="form-control"
                          name="UserBusiness"
                          value={UserBusiness}
                          onChange={this.handleChange}
                        >
                          <option defaultValue disabled="true" selected="true">
                            Select User Business
                          </option>
                          <option>Wholeseller</option>
                          <option>End User</option>
                          <option>Jewellery MFG</option>
                          <option>Retailer</option>
                          <option>Polished Distributer</option>
                          <option>Other</option>
                        </select>
                      </div>
                    </div>
                    <div className="row marginn">
                      <div className="col-md-4">
                        <label>Company Name</label>
                      </div>
                      <div className="col-md-8">
                        <input
                          type="text"
                          className="form-control"
                          name="CompanyName"
                          value={CompanyName}
                          onChange={this.handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="row marginn">
                      <div className="col-md-4">
                        <label>Skype</label>
                      </div>
                      <div className="col-md-8">
                        <input
                          type="text"
                          className="form-control"
                          name="SkypeID"
                          value={SkypeID}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div className="row marginn">
                      <div className="col-md-4">
                        <label>QQID</label>
                      </div>
                      <div className="col-md-8">
                        <input
                          type="text"
                          className="form-control"
                          name="QQID"
                          value={QQID}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div className="row marginn">
                      <div className="col-md-4">
                        <label>Wechat</label>
                      </div>
                      <div className="col-md-8">
                        <input
                          type="text"
                          className="form-control"
                          name="WeChatID"
                          value={WeChatID}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>

                    <div className="row marginn">
                      <div className="col-md-4">
                        <label>Address1</label>
                      </div>
                      <div className="col-md-8">
                        <textarea
                          className="form-control"
                          name="Address1"
                          value={Address1}
                          onChange={this.handleChange}
                          required
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="row ">
                      <div className="col-md-4">
                        <label>Address2</label>
                      </div>
                      <div className="col-md-8">
                        <textarea
                          className="form-control"
                          name="Address2"
                          value={Address2}
                          onChange={this.handleChange}
                        ></textarea>
                      </div>
                    </div>
                    <div className="row marginn">
                      <div className="col-md-4">
                        <label>Address3</label>
                      </div>
                      <div className="col-md-8">
                        <textarea
                          className="form-control"
                          name="Address3"
                          value={Address3}
                          onChange={this.handleChange}
                        ></textarea>
                      </div>
                    </div>

                    <div className="row marginn">
                      <div className="col-md-4">
                        <label>Country</label>
                      </div>
                      <div className="col-md-8">
                        <input
                          type="text"
                          name="Country"
                          className="form-control"
                          value={Country}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div className="row marginn">
                      <div className="col-md-4">
                        <label>State</label>
                      </div>
                      <div className="col-md-8">
                        <input
                          type="text"
                          name="State"
                          onChange={this.handleChange}
                          value={State}
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="row marginn">
                      <div className="col-md-4">
                        <label>City</label>
                      </div>
                      <div className="col-md-8">
                        <input
                          type="text"
                          name="City"
                          onChange={this.handleChange}
                          value={City}
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="row marginn">
                      <div className="col-md-4">
                        <label>Pincode</label>
                      </div>
                      <div className="col-md-8">
                        <input
                          type="text"
                          className="form-control"
                          name="PinCode"
                          value={PinCode}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div className="row marginn">
                      <div className="col-md-4">
                        <label>STD Code</label>
                      </div>
                      <div className="col-md-8">
                        <input
                          type="text"
                          className="form-control"
                          name="STD_Code"
                          value={STD_Code}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div className="row marginn">
                      <div className="col-md-4">
                        <label>ISD Code</label>
                      </div>
                      <div className="col-md-8">
                        <input
                          type="text"
                          className="form-control"
                          name="ISD_Code"
                          value={ISD_Code}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div className="row marginn">
                      <div className="col-md-4">
                        <label>Mobile No1</label>
                      </div>
                      <div className="col-md-8">
                        <input
                          type="tel"
                          className="form-control"
                          name="Mobile1"
                          value={Mobile1}
                          onChange={this.handleChange}
                          maxLength="10"
                        />
                        {errors.Mobile1.length > 0 && (
                          <span
                            className="label-danger"
                            style={{ color: 'red!important' }}
                          >
                            {errors.Mobile1}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="row marginn">
                      <div className="col-md-4">
                        <label>Mobile No2</label>
                      </div>
                      <div className="col-md-8">
                        <input
                          type="text"
                          className="form-control"
                          name="Mobile2"
                          value={Mobile2}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div className="row marginn">
                      <div className="col-md-4">
                        <label>Phone1</label>
                      </div>
                      <div className="col-md-8">
                        <input
                          type="text"
                          className="form-control"
                          name="Phone1"
                          value={Phone1}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div className="row marginn">
                      <div className="col-md-4">
                        <label>Phone2</label>
                      </div>
                      <div className="col-md-8">
                        <input
                          type="text"
                          className="form-control"
                          name="Phone2"
                          value={Phone2}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div className="row marginn">
                      <div className="col-md-4">
                        <label>Company EmailID</label>
                      </div>
                      <div className="col-md-8">
                        <input
                          type="email"
                          className="form-control"
                          name="CompanyEmailID"
                          value={CompanyEmailID}
                          onChange={this.handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="row marginn">
                      <div className="col-md-4">
                        <label>Fax No</label>
                      </div>
                      <div className="col-md-8">
                        <input
                          type="text"
                          className="form-control"
                          name="FaxNo"
                          value={FaxNo}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="row">
                      <div className="col-md-4">
                        <label>Company Type</label>
                      </div>
                      <div className="col-md-8">
                        <select
                          className="form-control"
                          name="CompanyType"
                          value={CompanyType}
                          onChange={this.handleChange}
                        >
                          <option defaultValue>Select Company Type</option>
                          <option>Individual</option>
                          <option>Partnership</option>
                          <option>Private Limited/Limited</option>
                          <option>Proprieter</option>
                        </select>
                      </div>
                    </div>
                    <div className="row marginn">
                      <div className="col-md-4">
                        <label>Website</label>
                      </div>
                      <div className="col-md-8">
                        <input
                          type="text"
                          className="form-control"
                          name="Website"
                          value={Website}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div className="row highlight-content">
                      <span>Member of any Association/Trading network?</span>
                    </div>
                    <div className="row marginn">
                      <div className="col-md-4">
                        <label>1</label>
                      </div>
                      <div className="col-md-8">
                        <input
                          type="text"
                          className="form-control"
                          name="Assoc1"
                          value={Assoc1}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div className="row marginn">
                      <div className="col-md-4">
                        <label>2</label>
                      </div>
                      <div className="col-md-8">
                        <input
                          type="text"
                          className="form-control"
                          name="Assoc2"
                          value={Assoc2}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div className="row marginn">
                      <div className="col-md-4">
                        <label>Comment</label>
                      </div>
                      <div className="col-md-8">
                        <input
                          type="text"
                          className="form-control"
                          name="Comment"
                          value={Comment}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div className="row highlight-content marginn">
                      <span>How did you know about Shree Diamonds?</span>
                    </div>
                    <div className="row marginn">
                      <div className="col-md-4"></div>
                      <div className="col-md-8">
                        <select
                          className="form-control"
                          name="Reference"
                          value={Reference}
                          onChange={this.handleChange}
                        >
                          <option defaultValue>Select Reference</option>
                          <option>Net Search</option>
                          <option>Email/FAX</option>
                          <option>Friends Recommendation</option>
                          <option>Advertisement</option>
                          <option>Other</option>
                        </select>
                      </div>
                    </div>
                    <div className="row highlight-content marginn">
                      <span>Documents</span>
                    </div>
                    <div className="row marginn">
                      <div className="col-md-4">
                        <label>Photo Type</label>
                      </div>
                      <div className="col-md-8">
                        <select
                          className="form-control"
                          name="DPhotoType"
                          value={DPhotoType}
                          onChange={this.handleChange}
                        >
                          <option>JPG</option>
                          <option>PNG</option>
                          <option>DOC</option>
                          <option>PDF</option>
                        </select>
                      </div>
                    </div>
                    <div className="row marginn">
                      <div className="col-md-4">
                        <label>Narration</label>
                      </div>
                      <div className="col-md-8">
                        <input
                          type="text"
                          className="form-control"
                          name="Narration"
                          value={Narration}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div className="row marginn">
                      <div className="col-md-4">
                        <label>Image</label>
                      </div>
                      <div className="col-md-8">
                        <label className="btn btn-info">
                          Choose File
                          <input
                            type="file"
                            className="form-control"
                            name="DImagePath"
                            style={{ display: 'none' }}
                            onChange={this.handleFile}
                          />
                        </label>
                      </div>
                    </div>
                    <div className="row highlight-content marginn">
                      <span>Company Registartion Certificate</span>
                    </div>
                    <div className="row marginn">
                      <div className="col-md-4">
                        <label>Photo Type</label>
                      </div>
                      <div className="col-md-8">
                        <select
                          className="form-control"
                          name="CPhotoType"
                          value={CPhotoType}
                          onChange={this.handleChange}
                        >
                          <option>JPG</option>
                          <option>PNG</option>
                          <option>DOC</option>
                          <option>PDF</option>
                        </select>
                      </div>
                    </div>
                    <div className="row marginn">
                      <div className="col-md-4">
                        <label>Narration</label>
                      </div>
                      <div className="col-md-8">
                        <input
                          type="text"
                          className="form-control"
                          name="CNarration"
                          value={CNarration}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div className="row marginn">
                      <div className="col-md-4">
                        <label>Image</label>
                      </div>
                      <div className="col-md-8">
                        <label className="btn btn-info">
                          Choose File
                          <input
                            type="file"
                            className="form-control"
                            name="CImagePath"
                            style={{ display: 'none' }}
                            onChange={this.handleFile1}
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="row"
                  style={{ marginBottom: '2%', marginTop: '2%' }}
                >
                  <div className="col-md-4"></div>
                  <div className="col-md-4">
                    <center>
                      <button type="submit" className="btn btn-info">
                        Save
                      </button>
                    </center>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default RegisterParty;

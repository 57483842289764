import React, { Component } from 'react';
import Header from '../Headers/Header';
import swal from 'sweetalert'
import disableBrowserBackButton from 'disable-browser-back-navigation'
import Axios from 'axios';
import { Link } from 'react-router-dom';

class HomePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            UserName: localStorage.getItem('user'),
            token: localStorage.getItem('token'),
            totalinward: '',
            totalUser: '',
            totalParty: '',
            lastupdateddate: ''
        }
    }


    componentDidMount() {



        if (this.state.token == null || this.state.UserName == null) {
            swal('Please Log In', '', 'error')
            this.props.history.push("/")
        }
        else {
            disableBrowserBackButton()

        }

        Axios.get('/totalrecord').then((res) => {
            this.setState({
                totalinward: res.data.totalinward,
                totalUser: res.data.totalUser,
                totalParty: res.data.totalParty,
                lastupdateddate: res.data.lastupdateddate,
                totalOrdercount: res.data.totalOrdercount
            })
        })



    }



    render() {


        return (
            <div>
                <Header />
                <div className="wrapper">
                    <div className="content-wrapper">
                        <section className="content-header">
                            <div className="container-fluid">
                                <div className="row">

                                    <div class="col-lg-3 col-6">
                                        <Link to="ViewInward">
                                            <div class="small-box bg-info ">

                                                <div class="inner">
                                                    <h3>{this.state.totalinward}</h3>

                                                    <p>Total Stock</p>
                                                </div>
                                                <div class="icon">
                                                    <i class="far fa-gem"></i>
                                                </div>

                                            </div>
                                        </Link>
                                    </div>
                                    <div class="col-lg-3 col-6">

                                        <div class="small-box bg-success ">
                                            <div class="inner">
                                                <h3>{this.state.totalUser}</h3>

                                                <p>Total Users</p>
                                            </div>
                                            <div class="icon">
                                                <i class="fa fa-user-plus"></i>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-6">
                                        <Link to="/viewParty">
                                            <div class="small-box bg-danger ">
                                                <div class="inner">
                                                    <h3>{this.state.totalParty}</h3>

                                                    <p>Total Suppliers</p>
                                                </div>
                                                <div class="icon">
                                                    <i class="fa fa-users"></i>
                                                </div>

                                            </div>
                                        </Link>
                                    </div>
                                    <div class="col-lg-3 col-6">
                                        <Link to="/ViewRapnet">

                                            <div class="small-box bg-secondary ">
                                                <div class="inner ">
                                                    <h3>{this.state.lastupdateddate}</h3>

                                                    <p>Last Updated Rap</p>
                                                </div>
                                                <div class="icon">
                                                    <i class="fa fa-download" ></i>
                                                </div>

                                            </div>
                                        </Link>
                                    </div>
                                    <div class="col-lg-3 col-6">
                                        <Link to="/ViewOrder">
                                            <div class="small-box bg-secondary ">
                                                <div class="inner ">
                                                    <h3>{this.state.totalOrdercount}</h3>
                                                    <p>Total order count</p>
                                                </div>
                                                <div class="icon">
                                                <i class="fas fa-shopping-bag"></i>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        );
    }
}



export default HomePage;
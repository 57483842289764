import Axios from 'axios';
import React, { Component } from 'react';
import Header from '../../Headers/Header'
import { Link } from 'react-router-dom'
import swal from 'sweetalert';
import '../Customers/ViewCustomer'

class ViewCustomer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            viewcustomer: []
        }
    }
    componentDidMount() {
    
        Axios.get('/CustomerList').then((res) => {
            this.setState({
                viewcustomer: res.data
            })
            const script = document.createElement("script")
            script.src = `js/content2.js`
            script.async = true
            document.body.appendChild(script)
        })
    }
    DeactiveCustomer(e, id) {
        
        swal({
            title: "Are you sure?",
            text: "You want to deactivate this user?",
            icon: "warning",
            buttons: true,
        })
        .then((willDelete) => {
                if (willDelete) {
                    Axios.get(`/deactiveCustomer/${id}`).then(res => {
                        swal('Customer Deactivated', '', 'success')
                        this.componentDidMount()
                    })
                } else {
                    swal("You Canceled!");
                }
        });
    }
    ActiveCustomers(e, id) {

        swal({
            title: "Are you sure? ",
            text: "You want to deactivate this user?",
            icon: "warning",
            buttons: true,

        })
            .then((willDelete) => {
                if (willDelete) {
                    Axios.get(`/activeCustomers/${id}`).then(res => {
                        swal('Customer Activated', '', 'success')
                        this.componentDidMount()
                    })
                } else {
                    swal("You Canceled!");
                }
            });
    }
    render() {
        const { viewcustomer } = this.state
        return (
            <div>
                <Header />
                <div className="wrapper">
                    <div className="content-wrapper">
                        <section className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-2">
                                    <div className="col-sm-6">
                                        <h1>View Customer Details</h1>
                                    </div>
                                    <div className="col-sm-6">
                                        <ol className="breadcrumb float-sm-right">
                                            <li className="breadcrumb-item"><a href="#" style={{ color: "#17a2b8" }}>Home</a></li>
                                            <li className="breadcrumb-item active">View Customer</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="content">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-12">

                                        <div className="card" >
                                            <div className="card-header">
                                                <h3 className="card-title">View Customer Details</h3>
                                            </div>
                                            <div className="card-body">
                                                <div className="table-responsive">

                                                    <table id="example1" className="table table-bordered table-striped">
                                                        <thead>
                                                            <tr>
                                                                <th className="fixed-side" scope="col"> Full Name</th>
                                                                <th>User Name</th>
                                                                <th>Date of Birth</th>
                                                                <th>Display Name</th>
                                                                <th>Designation</th>
                                                                <th>UserBusiness</th>
                                                                <th>SkypeID</th>
                                                                <th>QQID</th>
                                                                <th>WeChatID</th>
                                                                <th>EmailID</th>
                                                                <th>Company Name</th>
                                                                <th>Address 1</th>
                                                                <th>Address 2</th>
                                                                <th>Address 3</th>
                                                                <th>Country</th>
                                                                <th>State</th>
                                                                <th>City</th>
                                                                <th>Pincode</th>
                                                                <th>Sth_Code</th>
                                                                <th>ISD_Code</th>
                                                                <th>Mobile 1</th>
                                                                <th>Mobile 2</th>
                                                                <th>Phone 1</th>
                                                                <th>Phone 2</th>
                                                                <th>Company Email ID</th>
                                                                <th>Fax No</th>
                                                                <th>Company Type</th>
                                                                <th>Website</th>
                                                                <th>Assoc 1</th>
                                                                <th>Assoc 2</th>
                                                                <th>Comment</th>
                                                                <th>Reference</th>
                                                                <th>DPhotoType</th>
                                                                <th>Narration</th>

                                                                <th>CPhotoType</th>
                                                                <th>CNarration</th>

                                                                <th>Activate/Deactivate</th>
                                                            </tr>
                                                        </thead>

                                                        <tbody >
                                                            {viewcustomer.map((view) => (
                                                                <tr>
                                                                    <td >{view.FullName}</td>
                                                                    <td>{view.UserName}</td>
                                                                    <td>{view.DOB}</td>
                                                                    <td>{view.DisplayName}</td>
                                                                    <td>{view.Designation}</td>
                                                                    <td>{view.UserBusiness}</td>
                                                                    <td>{view.SkypeID}</td>
                                                                    <td>{view.QQID}</td>
                                                                    <td>{view.WeChatID}</td>
                                                                    <td>{view.EmailID}</td>
                                                                    <td>{view.CompanyName}</td>
                                                                    <td>{view.Address1}</td>
                                                                    <td>{view.Address2}</td>
                                                                    <td>{view.Address3}</td>
                                                                    <td>{view.Country}</td>
                                                                    <td>{view.State}</td>
                                                                    <td>{view.City}</td>
                                                                    <td>{view.Pincode}</td>
                                                                    <td>{view.STD_Code}</td>
                                                                    <td>{view.ISD_Code}</td>
                                                                    <td>{view.Mobile1}</td>
                                                                    <td>{view.Mobile2}</td>
                                                                    <td>{view.Phone1}</td>
                                                                    <td>{view.Phoen2}</td>
                                                                    <td>{view.CompanyEmailID}</td>
                                                                    <td>{view.FaxNo}</td>
                                                                    <td>{view.CompanyType}</td>
                                                                    <td>{view.Website}</td>
                                                                    <td>{view.Assoc1}</td>
                                                                    <td>{view.Assoc2}</td>
                                                                    <td>{view.Comment}</td>
                                                                    <td>{view.Reference}</td>
                                                                    <td>{view.DPhotoType}</td>
                                                                    <td>{view.Narration}</td>

                                                                    <td>{view.CPhotoType}</td>
                                                                    <td>{view.CNarration}</td>

                                                                    {view.ActiveStatus ? <td><Link onClick={(e) => { this.DeactiveCustomer(e, view._id) }}><i class="fa fa-toggle-on" aria-hidden="true" style={{ color: "#17a2b8" }}></i>&nbsp;<span style={{ color: "#17a2b8" }}><b>Activated</b></span></Link></td> : <td><Link onClick={(e) => { this.ActiveCustomers(e, view._id) }}><i class="fa fa-toggle-off" aria-hidden="true" style={{ color: "#c93d3a" }}></i>&nbsp;<span style={{ color: "#c93d3a" }}><b>Deactivated</b></span></Link></td>}
                                                                </tr>
                                                            ))}


                                                        </tbody>

                                                    </table>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </section>
                    </div>
                </div>
            </div >
        );
    }
}

export default ViewCustomer;
import axios from 'axios';
import React, { Component } from 'react';

class Inquiry extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inquiry: [],
      Weight: '',
      Color: '',
      Clarity: '',
      Stock: '',
      VendorStock: '',
      VendorName: '',
    };
  }
  componentDidMount() {
    axios.get('/users/ViewInquiry').then((res) => {
      this.setState({
        inquiry: res.data.Inquiry,
      });
    });
  }
  render() {
    return (
      <div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <h3 className="card-title">Inquiry Stone</h3>
              </div>
              {this.state.inquiry.length !== 0 ? (
                <div className="table-responsive">
                  <div className="card-body">
                    <table
                      id="example2"
                      className="table table-bordered table-striped"
                    >
                      <thead>
                        <tr className="text-center">
                          <th>Name</th>
                          <th>UserName</th>
                          <th>Contact No</th>
                          <th>Stock</th>
                          <th>Vendor Stock</th>

                          <th>Shape</th>
                          <th>Weight</th>
                          <th>Color</th>
                          <th>Clarity</th>

                          <th>Certificate</th>
                          <th>Vendor Name</th>
                          <th>Message</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.inquiry.map((view, index) => (
                          <tr className="text-center">
                            <td>{view.UserID.FullName}</td>
                            <td>{view.UserID.UserName}</td>
                            <td>{view.ContactNo}</td>
                            <td>{view.InwardDetails.Stock}</td>
                            <td>{view.InwardDetails.VendorStock}</td>

                            <td>{view.InwardDetails.Shape}</td>
                            <td>{view.InwardDetails.Weight}</td>
                            <td>{view.InwardDetails.Color}</td>
                            <td>{view.InwardDetails.Clarity}</td>

                            <td>{view.InwardDetails.Certificate}</td>
                            <td>{view.InwardDetails.PartyName}</td>
                            <td>{view.Message}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              ) : (
                <div className="card-body text-center">No data</div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Inquiry;

import React, { Component } from 'react';
import swal from 'sweetalert';
import { ProgressBar, Tab } from 'react-bootstrap';
import "./ViewInward.css"

import Axios from 'axios';
import Header from '../../Headers/Header';
import { Link } from 'react-router-dom';

import axios from 'axios';

class AddInward extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inwardImport: '',
      uploadPercentage: 0,
      Party: [],
      partyID: '',
      inwID: '',

      Date: '',
      DueDays: '',
      Cash_Credit: '',
      Less1: '',
      Less2: '',
      Weight: '',
      Prefix: '',
      Comment: '',
      InvoiceNo: '',
      BillType: '',
      CostAmount: '',
      PickupPerson: '',
      PickupDate: '',
      PickupLocation: '',
      PickupRemark: '',
      OurStock: '',
      VenderStock: '',
      Location: '',
      Availability: '',
      Shape: '',
      Cts: '', //
      Color: '',
      Purity: '',
      Cut: '',
      Polish: '',
      Symm: '',
      Flu: '',
      FluColor: '',

      DiamondImage: '',
      TradeShow: '',
      Rap: '',
      RapAmt: '',
      RapnetDiscount: '',
      NetRate: '',
      NetAmt: '',
      CashPrice: '',
      CashDisc: '',
      Measurements: '',
      Lab: '',
      Table: '',
      Depth: '',
      ReportNo: '',
      Keytosymbols: '',
      ReportComment: '',
      MemberComment: '',
      Milky: '',
      GirdleCondition: '',
      Girdle: '',
      StarLength: '',
      BlackInclusion: '',
      CenterInclusion: '',
      Shade: '',
      CrownAngle: '',
      CrownHeight: '',
      PavilionAngle: '',
      PavilionHeight: '',
      CuletSize: '',
      CuletCondition: '',
      ReportDate: '',
      Remark: '',
      GirdleThin: '',
      GirdleThick: '',
      Brand: '',
      PriceFromRapnet: '',
      RapnetDiscMarkup: '',
      DPerCarat: '',
      FinalPrice: '',
      getMarkup: '',
      getFancyMarkup: '',
      Certificate: '',
      getMapping: [],
      importfile: 0,
      InwardType: '',
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleFile = this.handleFile.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleFileDiamond = this.handleFileDiamond.bind(this);
    this.handleControls = this.handleControls.bind(this);
    this.handleChangeType = this.handleChangeType.bind(this);
    // this.handleChangeDrop = this.handleChangeDrop.bind(this)
  }

  // function for 'form' below the file upload feature
  handleClick(e) {
    e.preventDefault();
    if (this.props.match.params.id == null) {
      const formData = new FormData();
      formData.append('partyID', this.state.partyID);
      formData.append('inwardType', this.state.InwardType);
      formData.append('DueDays', this.state.DueDays);
      formData.append('Cash_Credit', this.state.Cash_Credit);
      formData.append('Less1', this.state.Less1);
      formData.append('Weight', this.state.Weight);
      formData.append('Certificate', this.state.Certificate);
      formData.append('Less2', this.state.Less2);
      formData.append('Prefix', this.state.Prefix);
      formData.append('Comment', this.state.Comment);
      formData.append('InvoiceNo', this.state.InvoiceNo);
      formData.append('BillType', this.state.BillType);
      formData.append('CostAmount', this.state.CostAmount);
      formData.append('PickupPerson', this.state.PickupPerson);
      formData.append('PickupDate', this.state.PickupDate);
      formData.append('PickupLocation', this.state.PickupLocation);
      formData.append('PickupRemark', this.state.PickupRemark);
      formData.append('OurStock', this.state.OurStock);
      formData.append('VenderStock', this.state.VenderStock);
      formData.append('Location', this.state.Location);
      formData.append('Availability', this.state.Availability);
      formData.append('Shape', this.state.Shape);
      formData.append('Cts', this.state.Cts);
      formData.append('Color', this.state.Color);
      formData.append('Clarity', this.state.Clarity);
      formData.append('Cut', this.state.Cut);
      formData.append('Polish', this.state.Polish);
      formData.append('Symm', this.state.Symm);
      formData.append('Flu', this.state.Flu);
      formData.append('FluColor', this.state.FluColor);

      formData.append('DiamondImage', this.state.DiamondImage);
      formData.append('TradeShow', this.state.TradeShow);
      formData.append('Rap', this.state.Rap);
      formData.append('RapAmt', this.state.RapAmt);
      formData.append('RapnetDiscount', this.state.RapnetDiscount);
      formData.append('NetRate', this.state.NetRate);
      formData.append('NetAmt', this.state.NetAmt);
      formData.append('CashPrice', this.state.CashPrice);
      formData.append('CashDisc', this.state.CashDisc);
      formData.append('Measurements', this.state.Measurements);
      formData.append('Lab', this.state.Lab);
      formData.append('Table', this.state.Lab);
      formData.append('Depth', this.state.Depth);
      formData.append('ReportNo', this.state.ReportNo);
      formData.append('KeyToSymbol', this.state.KeyToSymbol);
      formData.append('ReportComment', this.state.ReportComment);
      formData.append('MemberComment', this.state.MemberComment);
      formData.append('Milky', this.state.Milky);
      formData.append('GirdleCondition', this.state.GirdleCondition);
      formData.append('Girdle', this.state.Girdle);
      formData.append('StarLength', this.state.StarLength);
      formData.append('BlackInclusion', this.state.BlackInclusion);
      formData.append('CenterInclusion', this.state.CenterInclusion);
      formData.append('Shade', this.state.Shade);
      formData.append('CrownAngle', this.state.CrownAngle);
      formData.append('CrownHeight', this.state.CrownHeight);
      formData.append('PavilionAngle', this.state.PavilionAngle);
      formData.append('PavilionHeight', this.state.PavilionHeight);
      formData.append('CuletSize', this.state.CuletSize);
      formData.append('CuletCondition', this.state.CuletCondition);
      formData.append('ReportDate', this.state.ReportDate);
      formData.append('Remark', this.state.Remark);
      formData.append('GirdleThin', this.state.GirdleThin);
      formData.append('GirdleThick', this.state.GirdleThick);
      formData.append('Brand', this.state.Brand);
      formData.append('RapnetDiscMarkup', this.state.RapnetDiscMarkup);
      formData.append('PriceFromRapnet', this.state.PriceFromRapnet);
      formData.append('DPerCarat', this.state.DPerCarat);
      formData.append('FinalPrice', this.state.FinalPrice);
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };
      Axios.post('/users/addSingleInward', formData).then((res) => {
        swal('Inward Inserted', {
          icon: 'success',
          buttons: false,
          timer: false,
        });
      });
    } else {
      const formData = new FormData();

      this.setState({
        DueDays: this.state.DueDays,
        Cash_Credit: this.state.Cash_Credit,
        Less1: this.state.Less1,
        Less2: this.state.Less2,
        Prefix: this.state.Prefix,
        Comment: this.state.Comment,
        InvoiceNo: this.state.InvoiceNo,
        BillType: this.state.BillType,
        CostAmount: this.state.CostAmount,
        PickupPerson: this.state.PickupPerson,
        PickupDate: this.state.PickupDate,
        PickupLocation: this.state.PickupLocation,
        PickupRemark: this.state.PickupRemark,
        OurStock: this.state.OurStock,
        VenderStock: this.state.VenderStock,
        Location: this.state.Location,
        Availability: this.state.Availability,
        Weight: this.state.Weight,
        Certificate: this.state.Certificate,
        Shape: this.state.Shape,
        Cts: this.state.Cts,
        Color: this.state.Color,
        Clarity: this.state.Clarity,
        Cut: this.state.Cut,
        Polish: this.state.Polish,
        Symm: this.state.Symm,
        Flu: this.state.Flu,
        FluColor: this.state.FluColor,

        DiamondImage: this.state.DiamondImage,
        TradeShow: this.state.TradeShow,
        RapRate: this.state.Rap,
        RapAmt: this.state.RapAmt,
        RapnetDiscount: this.state.RapnetDiscount,
        NetRate: this.state.NetRate,
        NetAmt: this.state.NetAmt,
        CashPrice: this.state.CashPrice,
        CashDisc: this.state.CashDisc,
        Measurements: this.state.Measurements,
        Lab: this.state.Lab,
        Table: this.state.Table,
        Depth: this.state.Depth,
        ReportNo: this.state.ReportNo,
        Keytodymbold: this.state.Keytosymbols,
        ReportComment: this.state.ReportComment,
        MemberComment: this.state.MemberComment,
        Milky: this.state.Milky,
        GirdleCondition: this.state.GirdleCondition,
        Girdle: this.state.Girdle,
        StarLength: this.state.StarLength,
        BlackInclusion: this.state.BlackInclusion,
        CenterInclusion: this.state.CenterInclusion,
        Shade: this.state.Shade,
        CrownAngle: this.state.CrownAngle,
        CrownHeight: this.state.CrownHeight,
        PavilionAngle: this.state.PavilionAngle,
        PavilionHeight: this.state.PavilionHeight,
        CuletSize: this.state.CuletSize,
        CuletCondition: this.state.CuletCondition,
        ReportDate: this.state.ReportDate,
        Remark: this.state.Remark,
        GirdleThin: this.state.GirdleThin,
        GirdleThick: this.state.GirdleThick,
        PriceFromRapnet: this.state.PriceFromRapnet,
        DPerCarat: this.state.DPerCarat,
        RapnetDiscMarkup: this.state.RapnetDiscMarkup,
        FinalPrice: this.state.FinalPrice,
        Brand: this.state.Brand,
      });

      Axios.post(
        `/users/updateInward/${this.props.match.params.id}`,
        this.state
      ).then((res) => {
        swal('Updated SuccessFully', {
          icon: 'success',
          timer: 1500,
          buttons: false,
        });
        this.props.history.push('/ViewInward');
        this.componentDidMount();
      });
    }
  }

  handleFileDiamond(e) {
    this.setState({
      DiamondImage: e.target.files[0],
    });
  }

  handleChangeType(e) {
    this.setState({
      InwardType: e.target.value,
    });
  }

  handleChange(event) {
    this.setState({
      partyID: event.target.value,
      // uploadPercentage: this.state.uploadPercentage,
    });
    const party = this.state.Party.find((x) => x._id == event.target.value);
    console.log(party);

    if (party && party.inwardType) {
      this.setState({
        inwardType: party.inwardType,
        InwardType: party.inwardType
      });
    }

    axios.get(`/displayMarkup/${event.target.value}`).then((resp) => {
      this.setState({
        getMarkup: resp.data.markup,
        getMapping: resp.data.mapping,
        getFancyMarkup: resp.data.fancymarkup,
      });
    });
  }

  handleControls(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }
  handleFile(event) {
    this.state.inwardImport = event.target.files[0];

    const actualBtn = document.getElementById('actual-btn');

    const fileChosen = document.getElementById('file-chosen');

    fileChosen.textContent = event.target.files[0].name;
  }
  
  handleSubmit(event) {
    event.preventDefault();
    // console.log("inwardType is : ",this.state.inwardType)
    // console.log("InwardType is : ",this.state.InwardType)
    Axios.get('/getInwardID').then((res) => {
      this.setState({
        inwID: res.data.inwID,
      });
    });

    const formData = new FormData();
    formData.append('inwardImport', this.state.inwardImport);
    formData.append('inwID', this.state.inwID);
    formData.append('partyID', this.state.partyID);
    formData.append('getMapping', this.state.getMapping);
    formData.append('InwardType', this.state.InwardType);
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    Axios.post('/inwardImport', formData, {
      onUploadProgress: (ProgressEvent) => {
        //  const {loaded,total}=ProgressEvent
        let percent = Math.round(
          (ProgressEvent.loaded / ProgressEvent.total) * 100
        );

        if (percent < 100) {
          this.setState({ uploadPercentage: percent });
        }
      },
    }).then((response) => {
      if (response.data.msg == 'success') {
        this.setState({ uploadPercentage: 100 });
        swal(response.data.msg, {
          button: false,

          icon: 'success',
        });
      } else {
        this.setState({ uploadPercentage: 100 });
        swal(response.data.msg, {
          button: false,

          icon: 'error',
        });
      }
      this.setState({ uploadPercentage: 100 }, () => {
        setTimeout(() => {
          this.setState({ uploadPercentage: 0 });
        }, 1000);
      });
    });
  }

  componentDidMount() {
    Axios.get('/users/getParties').then((res) => {
      this.setState({
        Party: res.data,
      });
    });
    if (this.props.match.params.id) {
      Axios.get(`/users/updateInward/${this.props.match.params.id}`).then(
        (res) => {
          let inward = res.data.updatedata;
          this.setState({
            OurStock: inward.Stock,
            VenderStock: inward.VendorStock,
            Location: inward.Country,
            Availability: inward.Availability,
            Shape: inward.Shape,

            Weight: inward.Weight,
            Color: inward.Color,
            Clarity: inward.Clarity,
            Cut: inward.CutGrade,
            Polish: inward.Polish,
            Symm: inward.Symmetry,
            Flu: inward.FluorescenceIntensity,
            FluColor: inward.FluorescenceColor,
            Measurements: inward.Measurements,
            Lab: inward.Lab,

            TradeShow: inward.TradeShow,
            Treatment: inward.Treatment,
            Rap: inward.Rap,
            RapAmt: inward.RapAmt,
            RapnetDiscount: inward.RapnetDiscount,
            RapNetPrice: inward.RapNetPrice,
            NetValue: inward.NetValue,
            CashPrice: inward.CashPrice,
            CashPriceDiscount: inward.CashPriceDiscount,
            FancyColor: inward.FancyColor,
            FancyColorIntensity: inward.FancyColorIntensity,
            FancyColorOvertone: inward.FancyColorOvertone,
            Depth: inward.Depth,
            Table: inward.Table,
            GirdleThin: inward.GirdleThin,
            GirdleThick: inward.GirdleThick,
            Girdle: inward.Girdle,
            GirdleCondition: inward.GirdleCondition,
            CuletSize: inward.CuletSize,
            CuletCondition: inward.CuletCondition,
            CrownHeight: inward.CrownHeight,
            CrownAngle: inward.CrownAngle,
            PavilionDepth: inward.PavilionDepth,
            PavilionAngle: inward.PavilionAngle,
            LaserInscription: inward.LaserInscription,
            Certcomment: inward.Certcomment,
            Certificate: inward.Certificate,
            Country: inward.Country,
            State: inward.State,
            City: inward.City,
            Timetolocation: inward.Timetolocation,
            IsMatchedPairSeparable: inward.IsMatchedPairSeparable,
            PairStock: inward.PairStock,
            AllowRapLinkFeed: inward.AllowRapLinkFeed,
            ParcelStones: inward.ParcelStones,

            DiamondImage: inward.DiamondImage,
            SarinLoupe: inward.SarinLoupe,
            Keytosymbols: inward.Keytosymbols,
            Shade: inward.Shade,
            StarLength: inward.StarLength,
            CenterInclusion: inward.CenterInclusion,
            BlackInclusion: inward.BlackInclusion,
            MemberComment: inward.MemberComment,
            ReportIssueDate: inward.ReportIssueDate,
            ReportType: inward.ReportType,
            LabLocation: inward.LabLocation,
            Brand: inward.Brand,
            RapnetDiscMarkup: inward.RapnetDiscMarkup,
            DPerCarat: inward.DPerCarat,
            PriceFromRapnet: inward.PriceFromRapnet,
            FinalPrice: inward.FinalPrice,
            Milky: inward.Milky,
          });
        }
      );
    }
  }

  render() {
    const {
      uploadPercentage,
      Weight,
      Party,
      partyID,
      Certificate,
      InwardType,
      inwardType,
      Date,
      DueDays,
      Cash_Credit,
      Less1,
      Less2,
      Prefix,
      Comment,
      InvoiceNo,
      BillType,
      CostAmount,
      PickupPerson,
      PickupDate,
      PickupLocation,
      PickupRemark,
      OurStock,
      VenderStock,
      Location,
      Availability,
      Shape,
      Cts,
      Color,
      Purity,
      Cut,
      Polish,
      Symm,
      Flu,
      FluColor,
      DiamondImage,
      TradeShow,
      Rap,
      RapAmt,
      RapnetDiscount,
      RapNetPrice,
      NetValue,
      CashPrice,
      CashDisc,
      Measurements,
      Lab,
      Table,
      Depth,
      ReportNo,
      Keytosymbols,
      ReportComment,
      MemberComment,
      Milky,
      GirdleCondition,
      Girdle,
      StarLength,
      BlackInclusion,
      CenterInclusion,
      Shade,
      CrownAngle,
      CrownHeight,
      PavilionAngle,
      PavilionDepth,
      CuletSize,
      CuletCondition,
      ReportDate,
      Remark,
      GirdleThin,
      GirdleThick,
      Brand,
      DPerCarat,
      RapnetDiscMarkup,
      PriceFromRapnet,
      FinalPrice,
    } = this.state;

    return (
      <div>
        <Header />
        <div className="wrapper">
          <div className="content-wrapper">
            <section className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-6">
                    <h1>Add Inward</h1>
                  </div>
                  <div className="col-sm-6">
                    <ol className="breadcrumb float-sm-right">
                      <li className="breadcrumb-item">
                        <Link to="/adminHome" style={{ color: '#17a2b8' }}>
                          Home
                        </Link>
                      </li>
                      <li className="breadcrumb-item active">Add Inward</li>
                    </ol>
                  </div>
                </div>
              </div>
            </section>
            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <Link to="/ViewInward" className="btn btn-info">
                      <i class="fas fa-chevron-left"></i>&nbsp; Back
                    </Link>
                    <div className="card ">
                      <div className="card-header">
                        <h3 className="card-title">
                          {this.props.match.params.id ? 'Update' : 'Add'}{' '}
                          Details
                        </h3>
                        <div className="row" style={{ float: 'right' }}>
                          <div className="col-md-12">
                            <div style={{ display: 'inline-flex' }}>
                              <label style={{ marginTop: '5px' }}>Markup</label>
                              <input
                                type="text"
                                value={this.state.getMarkup}
                                style={{ width: '30%' }}
                                readOnly
                                className="form-control ml-2 markup"
                              />
                            </div>
                            <div style={{ display: 'inline-flex' }}>
                              <label style={{ marginTop: '5px' }}>
                                Fancy Markup
                              </label>
                              <input
                                type="text"
                                value={this.state.getFancyMarkup}
                                readOnly
                                className="form-control ml-2 markup"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-3">
                            <select
                              className="form-control"
                              name="partyID"
                              value={partyID}
                              onChange={this.handleChange}
                              size="3"
                            >
                              <option selected>--Select Party--</option>
                              {Party.map((p, key) => (
                                <option value={p._id} key={key}>
                                  {p.partyName}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="col-md-3">
                              <select
                                className="form-control"
                                name="InwardType"
                                value={InwardType}
                                onChange={this.handleChangeType}
                                size="3"
                                >
                                <option disabled>--Select Type--</option>
                                {inwardType ? (
                                  <option value={inwardType} className="bg-custom-blue">{inwardType}</option>
                                ) : (
                                  <>
                                    <option value="Natural">Natural</option>
                                    <option value="Labgrown">Labgrown</option>
                                  </>
                                )}
                              </select>
                          </div>
                          <div className="col-md-4">
                            <label className="btn btn-outline-secondary ">
                              <i className="fa fa-download"></i> Import Data
                              <input
                                type="file"
                                id="actual-btn"
                                style={{ display: 'none' }}
                                name="inwardImport"
                                onChange={this.handleFile}
                              />
                            </label>
                            <button
                              className="btn btn-info "
                              value="Add Inward"
                              style={{
                                margin: '0px 0px 10px 50px',
                                fontSize: '1rem',
                              }}
                              disabled={partyID.length < 1}
                              onClick={this.handleSubmit}
                            >
                              Add Stock
                            </button>
                            <br />
                            <span
                              id="file-chosen"
                              className="btn-secondary"
                              style={{ padding: '5px 20px', width: '85%' }}
                            >
                              No file chosen
                            </span>
                          </div>
                        </div>

                        <div className="row" style={{ marginTop: '10px' }}>
                          {uploadPercentage > 0 && (
                            <ProgressBar
                              striped
                              variant="warning"
                              now={uploadPercentage}
                              label={`${uploadPercentage}%`}
                              style={{ width: '20%', marginLeft: '2%' }}
                            />
                          )}
                        </div>
                        <hr></hr>

                        <form>
                          <div className="row">
                            <div className="col-md-3">
                              <input
                                type="date"
                                name="Date"
                                className="form-control"
                                value={Date}
                                onChange={this.handleControls}
                              />
                            </div>

                            <div className="col-md-3">
                              <input
                                type="text"
                                name="DueDays"
                                className="form-control"
                                placeholder="Due Days"
                                value={DueDays}
                                onChange={this.handleControls}
                              />
                            </div>
                          </div>
                          <div className="row pt-2">
                            <div className="col-md-3">
                              <select
                                name="Cash_Credit"
                                className="form-control"
                                value={Cash_Credit}
                                onChange={this.handleControls}
                              >
                                <option>Cash</option>
                                <option>Credit</option>
                              </select>
                            </div>
                            <div className="col-md-3">
                              <div className="row">
                                <div className="col-md-6">
                                  <input
                                    type="text"
                                    name="Less1"
                                    className="form-control"
                                    placeholder="Less 1"
                                    value={Less1}
                                    onChange={this.handleControls}
                                  />
                                </div>
                                <div className="col-md-6">
                                  <input
                                    type="text"
                                    name="Less2"
                                    className="form-control"
                                    placeholder="Less 2"
                                    value={Less2}
                                    onChange={this.handleControls}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <input
                                type="text"
                                name="Prefix"
                                className="form-control"
                                placeholder="Prefix"
                                value={Prefix}
                                onChange={this.handleControls}
                              />
                            </div>
                            <div className="col-md-3">
                              <input
                                type="text"
                                name="Comment"
                                className="form-control"
                                placeholder="Comment"
                                value={Comment}
                                onChange={this.handleControls}
                              />
                            </div>
                          </div>
                          <div className="row pt-2">
                            <div className="col-md-3">
                              <input
                                type="text"
                                name="InvoiceNo"
                                className="form-control"
                                placeholder="Invoice No"
                                value={InvoiceNo}
                                onChange={this.handleControls}
                              />
                            </div>
                            <div className="col-md-3">
                              <select
                                name="BillType"
                                className="form-control"
                                value={BillType}
                                onChange={this.handleControls}
                              >
                                <option defaultValue>--Select Bill Type</option>
                                <option>A</option>
                                <option>B</option>
                              </select>
                            </div>
                            <div className="col-md-3">
                              <input
                                type="text"
                                name="CostAmount"
                                className="form-control"
                                placeholder="Cost Amount"
                                value={CostAmount}
                                onChange={this.handleControls}
                              />
                            </div>
                            <div className="col-md-3"></div>
                          </div>
                          <div className="row pt-2">
                            <div className="col-md-3">
                              <input
                                type="text"
                                name="PickupPerson"
                                className="form-control"
                                placeholder="Pickup Person"
                                value={PickupPerson}
                                onChange={this.handleControls}
                              />
                            </div>
                            <div className="col-md-3">
                              <input
                                type="Date"
                                name="PickupDate"
                                className="form-control"
                                value={PickupDate}
                                onChange={this.handleControls}
                              />
                            </div>
                            <div className="col-md-3">
                              <input
                                type="text"
                                name="PickupLocation"
                                className="form-control"
                                placeholder="Pickup Location"
                                value={PickupLocation}
                                onChange={this.handleControls}
                              />
                            </div>
                            <div className="col-md-3">
                              <input
                                type="text"
                                name="PickupRemark"
                                className="form-control"
                                placeholder="Pickup Remark"
                                value={PickupRemark}
                                onChange={this.handleControls}
                              />
                            </div>
                          </div>
                          <hr></hr>
                          <div className="row pt-2">
                            <div className="col-md-3">
                              <input
                                type="text"
                                name="OurStock"
                                className="form-control"
                                placeholder="Our Stock"
                                value={OurStock}
                                onChange={this.handleControls}
                              />
                            </div>
                            <div className="col-md-3">
                              <input
                                type="text"
                                name="VenderStock"
                                className="form-control"
                                placeholder="Vender Stock"
                                value={VenderStock}
                                onChange={this.handleControls}
                              />
                            </div>
                            <div className="col-md-3">
                              <input
                                type="text"
                                name="Weight"
                                className="form-control"
                                placeholder="Weight"
                                value={Weight}
                                onChange={this.handleControls}
                              />
                            </div>

                            <div className="col-md-3">
                              <input
                                type="text"
                                name="Availability"
                                className="form-control"
                                placeholder="Availability"
                                value={Availability}
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>
                          <div className="row pt-2">
                            <div className="col-md-3">
                              <input
                                type="text"
                                name="Shape"
                                className="form-control"
                                placeholder="Shape"
                                value={Shape}
                                onChange={this.handleControls}
                              />
                            </div>
                            <div className="col-md-3">
                              <input
                                type="text"
                                name="Location"
                                className="form-control"
                                placeholder="Location"
                                value={Location}
                                onChange={this.handleControls}
                              />
                            </div>
                            <div className="col-md-3">
                              <input
                                type="text"
                                name="Color"
                                className="form-control"
                                placeholder="Color"
                                value={Color}
                                onChange={this.handleControls}
                              />
                            </div>
                            <div className="col-md-3">
                              <input
                                type="text"
                                name="Purity"
                                className="form-control"
                                placeholder="Purity"
                                value={Purity}
                                onChange={this.handleControls}
                              />
                            </div>
                          </div>
                          <div className="row pt-2">
                            <div className="col-md-3">
                              <input
                                type="text"
                                name="Cut"
                                placeholder="Cut"
                                className="form-control"
                                value={Cut}
                                onChange={this.handleControls}
                              />
                            </div>
                            <div className="col-md-3">
                              <input
                                type="text"
                                name="Polish"
                                className="form-control"
                                placeholder="Polish"
                                value={Polish}
                                onChange={this.handleControls}
                              />
                            </div>
                            <div className="col-md-3">
                              <input
                                type="text"
                                name="Symm"
                                className="form-control"
                                placeholder="Symm"
                                value={Symm}
                                onChange={this.handleControls}
                              />
                            </div>
                            <div className="col-md-3">
                              <input
                                type="text"
                                name="Flu"
                                className="form-control"
                                placeholder="Flu Intension"
                                value={Flu}
                                onChange={this.handleControls}
                              />
                            </div>
                          </div>
                          <div className="row pt-2">
                            <div className="col-md-3">
                              <input
                                type="text"
                                name="FluColor"
                                className="form-control"
                                placeholder="Flu Color"
                                value={FluColor}
                                onChange={this.handleControls}
                              />
                            </div>
                            <div className="col-md-3">
                              <input
                                type="text"
                                name="Certificate"
                                className="form-control"
                                placeholder="Certificate"
                                value={Certificate}
                                onChange={this.handleControls}
                              />
                            </div>
                            <div className="col-md-3">
                              <input
                                type="file"
                                name="DiamondImage"
                                className="form-control"
                                onChange={this.handleFileDiamond}
                              />
                            </div>
                            <div className="col-md-3">
                              <input
                                type="text"
                                name="TradeShow"
                                className="form-control"
                                placeholder="Trade Show"
                                value={TradeShow}
                                onChange={this.handleControls}
                              />
                            </div>
                          </div>
                          <div className="row pt-2">
                            <div className="col-md-3">
                              <input
                                type="text"
                                name="Rap"
                                placeholder="Rap"
                                className="form-control"
                                value={Rap}
                                onChange={this.handleControls}
                              />
                            </div>
                            <div className="col-md-3">
                              <input
                                type="text"
                                name="RapAmt"
                                className="form-control"
                                placeholder="Rap Amt($)"
                                value={RapAmt}
                                onChange={this.handleControls}
                              />
                            </div>
                            <div className="col-md-3">
                              <input
                                type="text"
                                name="RapnetDiscount"
                                className="form-control"
                                placeholder="Disc%"
                                value={RapnetDiscount}
                                onChange={this.handleControls}
                              />
                            </div>
                            <div className="col-md-3">
                              <input
                                type="text"
                                name="NetRate"
                                className="form-control"
                                placeholder="Net Rate($)"
                                value={RapNetPrice}
                                onChange={this.handleControls}
                              />
                            </div>
                          </div>
                          <div className="row pt-2">
                            <div className="col-md-3">
                              <input
                                type="text"
                                name="NetAmt"
                                className="form-control"
                                placeholder="Net Amt($)"
                                value={NetValue}
                                onChange={this.handleControls}
                              />
                            </div>
                            <div className="col-md-3">
                              <input
                                type="text"
                                name="CashPrice"
                                className="form-control"
                                placeholder="Cash Price($)"
                                value={CashPrice}
                                onChange={this.handleControls}
                              />
                            </div>
                            <div className="col-md-3">
                              <input
                                type="text"
                                name="CashDisc"
                                className="form-control"
                                placeholder="Cash Price Discount%"
                                value={CashDisc}
                                onChange={this.handleControls}
                              />
                            </div>
                            <div className="col-md-3">
                              <input
                                type="text"
                                name="Measurements"
                                className="form-control"
                                placeholder="Measurements"
                                value={Measurements}
                                onChange={this.handleControls}
                              />
                            </div>
                          </div>
                          <div className="row pt-2">
                            <div className="col-md-3">
                              <input
                                type="text"
                                name="Lab"
                                className="form-control"
                                placeholder="Lab"
                                value={Lab}
                                onChange={this.handleControls}
                              />
                            </div>
                            <div className="col-md-3">
                              <input
                                type="text"
                                name="Table"
                                className="form-control"
                                placeholder="Table%"
                                value={Table}
                                onChange={this.handleControls}
                              />
                            </div>
                            <div className="col-md-3">
                              <input
                                type="text"
                                name="Depth"
                                className="form-control"
                                placeholder="Depth%"
                                value={Depth}
                                onChange={this.handleControls}
                              />
                            </div>
                            <div className="col-md-3">
                              <input
                                type="text"
                                name="ReportNo"
                                className="form-control"
                                placeholder="Report No"
                                value={ReportNo}
                                onChange={this.handleControls}
                              />
                            </div>
                          </div>
                          <div className="row pt-2">
                            <div className="col-md-3">
                              <textarea
                                className="form-control"
                                name="keytosymbols"
                                placeholder="Key To Symbol"
                                value={Keytosymbols}
                                onChange={this.handleControls}
                              ></textarea>
                            </div>
                            <div className="col-md-3">
                              <textarea
                                className="form-control"
                                name="ReportComment"
                                placeholder="Report Comment"
                                value={ReportComment}
                                onChange={this.handleControls}
                              ></textarea>
                            </div>
                            <div className="col-md-3">
                              <textarea
                                className="form-control"
                                name="MemberComment"
                                placeholder="Member Comment"
                                value={MemberComment}
                                onChange={this.handleControls}
                              ></textarea>
                            </div>
                            <div className="col-md-3">
                              <input
                                type="text"
                                name="Milky"
                                className="form-control"
                                placeholder="Milky"
                                value={Milky}
                                onChange={this.handleControls}
                              />
                            </div>
                          </div>
                          <div className="row pt-2">
                            <div className="col-md-3">
                              <input
                                type="text"
                                name="GirdleCondition"
                                className="form-control"
                                placeholder="Girdle Condition"
                                value={GirdleCondition}
                                onChange={this.handleControls}
                              />
                            </div>
                            <div className="col-md-3">
                              <input
                                type="text"
                                name="Girdle"
                                className="form-control"
                                placeholder="Girdle(%)"
                                value={Girdle}
                                onChange={this.handleControls}
                              />
                            </div>
                            <div className="col-md-3">
                              <input
                                type="text"
                                name="StarLength"
                                className="form-control"
                                placeholder="Star Length"
                                value={StarLength}
                                onChange={this.handleControls}
                              />
                            </div>
                            <div className="col-md-3">
                              <input
                                type="text"
                                name="BlackInclusion"
                                className="form-control"
                                placeholder="Black Inclusion"
                                value={BlackInclusion}
                                onChange={this.handleControls}
                              />
                            </div>
                          </div>
                          <div className="row pt-2">
                            <div className="col-md-3">
                              <input
                                type="text"
                                name="CenterInclusion"
                                className="form-control"
                                placeholder="Center Inclusion"
                                value={CenterInclusion}
                                onChange={this.handleControls}
                              />
                            </div>
                            <div className="col-md-3">
                              <input
                                type="text"
                                name="Shade"
                                className="form-control"
                                placeholder="Shade"
                                value={Shade}
                                onChange={this.handleControls}
                              />
                            </div>
                            <div className="col-md-3">
                              <input
                                type="text"
                                name="CrownAngle"
                                className="form-control"
                                placeholder="Crown Angle"
                                value={CrownAngle}
                                onChange={this.handleControls}
                              />
                            </div>
                            <div className="col-md-3">
                              <input
                                type="text"
                                name="CrownHeight"
                                className="form-control"
                                placeholder="Crown Height"
                                value={CrownHeight}
                                onChange={this.handleControls}
                              />
                            </div>
                          </div>
                          <div className="row pt-2">
                            <div className="col-md-3">
                              <input
                                type="text"
                                name="PavilionAngle"
                                className="form-control"
                                placeholder="Pavilion Depth"
                                value={PavilionDepth}
                                onChange={this.handleControls}
                              />
                            </div>
                            <div className="col-md-3">
                              <input
                                type="text"
                                name="PavilionHeight"
                                className="form-control"
                                placeholder="Pavilion Angle"
                                value={PavilionAngle}
                                onChange={this.handleControls}
                              />
                            </div>
                            <div className="col-md-3">
                              <input
                                type="text"
                                name="CuletSize"
                                className="form-control"
                                placeholder="Culet Size"
                                value={CuletSize}
                                onChange={this.handleControls}
                              />
                            </div>
                            <div className="col-md-3">
                              <input
                                type="text"
                                name="CuletCondition"
                                placeholder="Culet Condition"
                                className="form-control"
                                value={CuletCondition}
                                onChange={this.handleControls}
                              />
                            </div>
                          </div>
                          <div className="row pt-2">
                            <div className="col-md-3">
                              <input
                                type="Date"
                                name="ReportDate"
                                className="form-control"
                                value={ReportDate}
                                onChange={this.handleControls}
                              />
                            </div>
                            <div className="col-md-3">
                              <textarea
                                className="form-control"
                                name="Remark"
                                placeholder="Remark"
                                value={Remark}
                                onChange={this.handleControls}
                              ></textarea>
                            </div>
                            <div className="col-md-3">
                              <input
                                type="text"
                                name="GirdleThin"
                                className="form-control"
                                placeholder="Girdle Thin"
                                value={GirdleThin}
                                onChange={this.handleControls}
                              />
                            </div>
                            <div className="col-md-3">
                              <input
                                type="text"
                                name="GirdleThick"
                                className="form-control"
                                placeholder="Girdle Thick"
                                value={GirdleThick}
                                onChange={this.handleControls}
                              />
                            </div>
                          </div>
                          <div className="row pt-2">
                            <div className="col-md-3">
                              <input
                                type="text"
                                name="Brand"
                                placeholder="Brand"
                                className="form-control"
                                value={Brand}
                                onChange={this.handleControls}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6"></div>
                            <div className="col-md-3">
                              <button
                                className="btn btn-info"
                                onClick={this.handleClick}
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    );
  }
}

export default AddInward;


import Axios from 'axios';
import React, { Component } from 'react'
import { ProgressBar } from 'react-bootstrap';
import { Link } from 'react-router-dom'
import swal from 'sweetalert';
import Header from '../../Headers/Header'

class TestingInward extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inwardImport: '',
            uploadPercentage: 0,
            Party: [],
            partyID: '',
            inwID: '',
            InwardType: '',
            getMarkup: 0,
            getFancyMarkup: 0
        }
        this.handleChangeDropDown = this.handleChangeDropDown.bind(this)
        this.handleChangeType = this.handleChangeType.bind(this)
        this.handleFile = this.handleFile.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }
    handleFile(event) {



        this.state.inwardImport = event.target.files[0]

        const fileChosen = document.getElementById('file-chosen');

        fileChosen.textContent = event.target.files[0].name


    }
    handleChangeType(e) {
        this.setState({
            InwardType: e.target.value
        })
    }

    handleChangeDropDown(e) {
        this.setState({
            partyID: e.target.value
        })
        Axios.get(`/displayMarkup/${e.target.value}`).then(resp => {
            this.setState({
                getMarkup: resp.data.markup,
                getMapping: resp.data.mapping,
                getFancyMarkup: resp.data.fancymarkup
            })
    

        })
    }
    handleSubmit(event) {
        event.preventDefault()


        const formData = new FormData()
        formData.append('inwardImport', this.state.inwardImport)

        formData.append('partyID', this.state.partyID)
        formData.append('getMapping', this.state.getMapping)
        formData.append('InwardType', this.state.InwardType)

        Axios.post('/testing', formData, {
            onUploadProgress: ProgressEvent => {
            
                let percent = Math.round(ProgressEvent.loaded / ProgressEvent.total * 100)
             

                if (percent < 100) {
                    this.setState({ uploadPercentage: percent })
                }
            }
        }).then((response) => {

            
            if (response.data.msg == "success") {

                this.setState({ uploadPercentage: 100 })
                swal(response.data.msg, {
                    button: false,

                    icon: 'success'
                });
            }
            else {
                this.setState({ uploadPercentage: 100 })
                swal(response.data.msg, {
                    button: false,

                    icon: 'error'
                });
            }
            this.setState({ uploadPercentage: 100 }, () => {
                setTimeout(() => {
                    this.setState({ uploadPercentage: 0 })

                }, 1000)
            })
        })
    }
    componentDidMount() {
        Axios.get('/users/getParties').then(res => {

            this.setState({
                Party: res.data
            })
        })
 
    }
    render() {
        return (
            <div>
                <Header />
                <div className="wrapper">
                    <div className="content-wrapper">
                        <section className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-2">
                                    <div className="col-sm-6">
                                        <h1>Testing Module</h1>
                                    </div>
                                    <div className="col-sm-6">
                                        <ol className="breadcrumb float-sm-right">
                                            <li className="breadcrumb-item"><a href="#" style={{ color: "#17a2b8" }}>Home</a></li>
                                            <li className="breadcrumb-item active">Testing</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="content-fluid">
                            <div className="container-fluid">
                                <div className="row" style={{ float: 'right' }}>

                                    <div className="col-md-12">
                                        <div style={{ display: "inline-flex" }}>
                                            <label style={{ marginTop: "5px" }}>Markup</label>
                                            <input type="text" value={this.state.getMarkup} style={{ width: "30%" }} readOnly className="form-control ml-2 markup" />

                                        </div>
                                        <div style={{ display: "inline-flex" }}>
                                            <label style={{ marginTop: "5px" }}>Fancy Markup</label>
                                            <input type="text" value={this.state.getFancyMarkup} readOnly className="form-control ml-2 markup" />

                                        </div>
                                    </div>

                                </div>
                                <Link to="/viewTesting" className="btn btn-info"><i className="fa fa-eye"></i>&nbsp; View</Link>
                                <form onSubmit={this.handleSubmit}>
                                    <div className="row pt-5">
                                        <div className="col-md-3">
                                            <select className="form-control" name="partyID" value={this.state.partyID} onChange={this.handleChangeDropDown} size="3" >
                                                <option selected >--Select Party--</option>
                                                {
                                                    this.state.Party.map((p, key) => (
                                                        <option value={p._id} key={key}>{p.partyName}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                        <div className="col-md-3">
                                            <select className="form-control" name="InwardType" value={this.state.InwardType} onChange={this.handleChangeType} size="3" >
                                                <option selected >--Select Party--</option>
                                                <option>NATURAL</option>
                                                <option>LABGROWN</option>
                                            </select>
                                        </div>
                                        <div className="col-md-4">
                                            <label className="btn btn-outline-danger "><i className="fa fa-download"></i> Import Data
                                        <input type="file" id="actual-btn" style={{ display: "none" }} name="inwardImport" onChange={this.handleFile} /></label>
                                            <input type="submit" className="btn btn-info " style={{ fontSize: "1rem" }} value="Add" style={{ margin: '0px 0px 10px 50px' }} disabled={this.state.partyID.length < 1}></input>
                                            <br /><span id="file-chosen" className="btn-secondary" style={{ padding: '5px 20px', width: "85%" }} >No file chosen</span>
                                        </div>
                                    </div>
                                    <div className="row" style={{ marginTop: "10px" }}>
                                        {this.state.uploadPercentage > 0 && <ProgressBar striped variant="warning" now={this.state.uploadPercentage} label={`${this.state.uploadPercentage}%`} style={{ width: "20%", marginLeft: "2%" }} />}
                                    </div>
                                </form>
                            </div>

                        </section>
                    </div>
                </div>
            </div>
        )
    }

}

export default TestingInward
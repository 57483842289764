import React, { Component } from 'react';
import swal from 'sweetalert';
import { ProgressBar, Tab } from 'react-bootstrap';

import Axios from 'axios';
import Header from '../../Headers/Header';
import { Link } from 'react-router-dom';
import { format } from 'morgan';

import PartyHeader from '../../Headers/PartyHeader';
class AddInwardSupplier extends Component {
  constructor(props) {
    super(props);
    this.state = {
      partyName: '',
      inwardImport: '',
      InwardType: '',
      uploadPercentage: 0,
      partyInwardType: '',
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleFile = this.handleFile.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange(e) {
    this.setState({
      InwardType: e.target.value,
    });
  }
  handleFile(e) {
    this.state.inwardImport = e.target.files[0];
  }
  handleSubmit(e) {
    e.preventDefault();

    const formData = new FormData();
    formData.append('inwardImport', this.state.inwardImport);
    formData.append('partyName', this.state.partyName);
    formData.append('InwardType', this.state.InwardType);
    Axios.post('/users/inwardImportSupplier', formData, {
      onUploadProgress: (ProgressEvent) => {
        //  const {loaded,total}=ProgressEvent
        let percent = Math.round(
          (ProgressEvent.loaded / ProgressEvent.total) * 100
        );

        if (percent < 100) {
          this.setState({ uploadPercentage: percent });
        }
      },
    }).then((response) => {
      if (response.data.msg == 'success') {
        this.setState({ uploadPercentage: 100 });
        swal(response.data.msg, {
          button: false,
          timer: 1500,
          icon: 'success',
        });
      } else {
        this.setState({ uploadPercentage: 100 });
        swal(response.data.msg, {
          button: false,
          timer: 1500,
          icon: 'error',
        });
      }
      this.setState({ uploadPercentage: 100 }, () => {
        setTimeout(() => {
          this.setState({ uploadPercentage: 0 });
        }, 1000);
      });
    });
  }
  componentDidMount() {
    Axios.get(`/getSupplierName/${localStorage.getItem('user')}`).then(
      (resp) => {
        this.setState({
          partyName: resp.data.partyName,
          partyInwardType: resp.data.inwardType,
        });
      }
    );
  }
  render() {
    return (
      <div>
        <PartyHeader />
        <div className="wrapper">
          <div className="content-wrapper">
            <section className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-6">
                    <h1>Add Stock</h1>
                  </div>
                  <div className="col-sm-6">
                    <ol className="breadcrumb float-sm-right">
                      <li className="breadcrumb-item">
                        <Link
                          to="/supplierDashboard"
                          style={{ color: '#17a2b8' }}
                        >
                          Home
                        </Link>
                      </li>
                      <li className="breadcrumb-item active">Add Stock</li>
                    </ol>
                  </div>
                </div>
              </div>
            </section>
            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <Link to="/viewStockSupplier" className="btn btn-info">
                      <i class="fas fa-chevron-left"></i>&nbsp; Back
                    </Link>
                    <div className="card" style={{ marginTop: '2%' }}>
                      <div className="card-header">
                        <h3 className="card-title">Add Stones</h3>
                      </div>
                      <div className="card-body">
                        <form>
                          <div className="row">
                            <div className="col-md-3">
                              <select
                                className="form-control"
                                name="InwardType"
                                value={this.state.InwardType}
                                onChange={this.handleChange}
                              >
                                <option defaultValue selected>
                                  Select Type
                                </option>
                                {this.state.partyInwardType ? (
                                  <option>{this.state.partyInwardType}</option>
                                ) : (
                                  <>
                                    <option>Natural</option>
                                    <option>Labgrown</option>
                                  </>
                                )}
                              </select>
                            </div>
                            <div className="col-md-2">
                              <label className="btn btn-outline-secondary ">
                                <i className="fa fa-download"></i> Select File
                                <input
                                  type="file"
                                  id="actual-btn"
                                  style={{ display: 'none' }}
                                  name="inwardImport"
                                  onChange={this.handleFile}
                                />
                              </label>
                            </div>
                            <div className="col-md-3">
                              <input
                                type="submit"
                                className="btn btn-info "
                                style={{ fontSize: '1rem' }}
                                value="Add File"
                                onClick={this.handleSubmit}
                              ></input>
                            </div>
                          </div>
                        </form>

                        <div className="row" style={{ marginTop: '10px' }}>
                          {this.state.uploadPercentage > 0 && (
                            <ProgressBar
                              striped
                              variant="warning"
                              now={this.state.uploadPercentage}
                              label={`${this.state.uploadPercentage}% `}
                              style={{ width: '20%', marginLeft: '2%' }}
                            />
                          )}
                        </div>
                        <hr></hr>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    );
  }
}

export default AddInwardSupplier;

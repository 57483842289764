import axios from 'axios';

const resources = {};

const makeRequestCreator = () => {
    let cancel;

    return async val => {
        if (cancel) {
            // Cancel the previous request before making a new request
            cancel.cancel();
        }
        // Create a new CancelToken
        cancel = axios.CancelToken.source();
        try {
            if (resources[val]) {
                // Return result if it exists
                return resources[val];
            }
            const res = await axios(val, { cancelToken: cancel.token });
        
            const result = res.data.data;
            // Store response
            resources[val] = result;

            return result;
        } catch (error) {
            if (axios.isCancel(error)) {
                // Handle if request was cancelled
              
            } else {
                // Handle usual errors
              
            }
        }
    };
};

export const search = makeRequestCreator()
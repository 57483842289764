import React, { Component } from 'react';
import Header from '../../Headers/Header';
import axios from 'axios';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';

import { Fragment } from 'react';
import Axios from 'axios';
import StoneOffer from '../Inward/StoneOffer';
import Inquiry from '../Inward/Inquiry';
import config from '../../Config/config.json';

class ViewApiRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      deviceID: '',
    };
  }

  // updateStatus(id, e) {
  //     e.preventDefault()
  //     // Axios.get(`/updateoff/${id}`).then(res=>{
  //     //     this.componentDidMount()
  //     // })

  //     swal({
  //         title: "Are you sure?",
  //         text: "Do you want to Reject the api access request?",
  //         icon: "warning",
  //         buttons: true,
  //         dangerMode: true,
  //     })
  //         .then((willDelete) => {
  //             if (willDelete) {
  //                 axios.get(`/updateoff/${id}`).then(res => {
  //                     this.componentDidMount()
  //                 })
  //             } else {
  //                 swal("You canceled");
  //             }
  //         });
  // }
  // updateStatuson(id, e) {
  //     e.preventDefault()

  //     swal({
  //         title: "Are you sure?",
  //         text: "Do you want to Accept the api access request?",
  //         icon: "warning",
  //         buttons: true,
  //     })
  //         .then((willDelete) => {
  //             if (willDelete) {
  //                 axios.get(`/updateon/${id}`).then(res => {
  //                     this.componentDidMount()
  //                 })
  //             } else {
  //                 swal("You Canceled");
  //             }
  //         });

  // }

  AcceptRequest(e, id, type) {
    axios.get(`/getCustomerDeviceToken/${id}`).then((resp) => {
      this.setState({
        deviceID: resp.data.deviceID,
      });
    });
    swal({
      title: 'Are you sure?',
      text: 'Do you want to Accept the api access request?',
      icon: 'warning',
      buttons: true,
    }).then((willDelete) => {
      if (willDelete) {
        Axios.get(`/users/AcceptApiRequest${type}/${id}`).then((res) => {
          const bodyData = {
            notification: {
              body: 'Your request for accessing the API has been accepted',
              title: 'API access request',
            },
            to: this.state.deviceID,
          };
          const options = {
            method: 'post',
            headers: {
              Accept: 'application/json, text/plain, */*',
              'Content-Type': 'application/json',
              Authorization: config.SECRET_KEY,
            },
            body: JSON.stringify(bodyData),
          };
          fetch('https://fcm.googleapis.com/fcm/send', options).then(
            (response) => {
              return response.json();
            }
          );

          this.viewRequest();
        });
        this.viewRequest();
      } else {
        swal('You Canceled');
      }
    });
  }
  RejectRequest(e, id, type) {
    axios.get(`/getCustomerDeviceToken/${id}`).then((resp) => {
      this.setState({
        deviceID: resp.data.deviceID,
      });
    });
    swal({
      title: 'Are you sure?',
      text: 'Do you want to Reject the api access request?',
      icon: 'warning',
      buttons: true,
    }).then((willDelete) => {
      if (willDelete) {
        Axios.get(`/users/RejectApiRequest${type}/${id}`).then((res) => {
          const bodyData = {
            notification: {
              body: 'Your request for accessing the API has been rejected',
              title: 'API access request',
            },
            to: this.state.deviceID,
          };
          const options = {
            method: 'post',
            headers: {
              Accept: 'application/json, text/plain, */*',
              'Content-Type': 'application/json',
              Authorization: config.SECRET_KEY,
            },
            body: JSON.stringify(bodyData),
          };
          fetch('https://fcm.googleapis.com/fcm/send', options).then(
            (response) => {
              return response.json();
            }
          );

          this.viewRequest();
        });
        this.viewRequest();
      } else {
        swal('You Canceled');
      }
    });
  }
  componentDidMount() {
    axios.get('/requestlist').then((res) => {
      this.setState({
        items: res.data,
      });
      const script = document.createElement('script');
      script.src = `js/content.js`;
      script.async = true;
      document.body.appendChild(script);
    });
  }
  viewRequest() {
    axios.get('/requestlist').then((res) => {
      this.setState({
        items: res.data,
      });
    });
  }

  render() {
    const { items } = this.state;
    return (
      <div>
        <Header />
        <div className="wrapper">
          <div className="content-wrapper">
            <section className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-6"></div>
                  <div className="col-sm-6">
                    <ol className="breadcrumb float-sm-right">
                      <li className="breadcrumb-item">
                        <a href="#" style={{ color: '#17a2b8' }}>
                          Home
                        </a>
                      </li>
                      <li className="breadcrumb-item active">API REQUESTS</li>
                    </ol>
                  </div>
                </div>
              </div>
            </section>
            <section className="container">
              <div className="container-fluid">
                <ul className="nav nav-tabs  mb-3" id="myTab" role="tablist">
                  <li className="nav-item pr-1">
                    <a
                      className="nav-link active button-color"
                      id="pills-home-tab"
                      data-toggle="pill"
                      href="#pills-home"
                      role="tab"
                      aria-controls="pills-home"
                      aria-selected="true"
                    >
                      Customer Request API
                    </a>
                  </li>
                  <li className="nav-item pr-1">
                    <a
                      className="nav-link button-color"
                      id="pills-profile-tab"
                      data-toggle="pill"
                      href="#pills-profile"
                      role="tab"
                      aria-controls="pills-profile"
                      aria-selected="false"
                    >
                      Stone Offer
                    </a>
                  </li>
                  <li className="nav-item pr-1">
                    <a
                      className="nav-link button-color"
                      id="pills-contact-tab"
                      data-toggle="pill"
                      href="#pills-contact"
                      role="tab"
                      aria-controls="pills-contact"
                      aria-selected="false"
                    >
                      View Inquiry
                    </a>
                  </li>
                </ul>
                <div className="tab-content" id="pills-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="pills-home"
                    role="tabpanel"
                    aria-labelledby="pills-home-tab"
                  >
                    <div className="row">
                      <div className="col-12">
                        <div className="card">
                          <div className="card-header">
                            <h3 className="card-title">Stock API Requests</h3>
                          </div>
                          {this.state.items.length !== 0 ? (
                            <div className="table-responsive">
                              <div className="card-body">
                                <table
                                  id="example1"
                                  className="table table-bordered table-striped"
                                >
                                  <thead>
                                    <tr>
                                      <th>Name</th>
                                      <th>UserName</th>
                                      <th>Natural Approve/Reject</th>
                                      <th>Labgrown Approve/Reject</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {items.map((item) => (
                                      <tr>
                                        <td>{item.FullName}</td>
                                        <td>{item.UserName}</td>
                                        <td>
                                          {item.IsPending === true ? (
                                            <Fragment>
                                              <Link
                                                onClick={(e) =>
                                                  this.AcceptRequest(
                                                    e,
                                                    item._id,
                                                    'Natural'
                                                  )
                                                }
                                              >
                                                <i
                                                  className="fas fa-check-circle fa-2x"
                                                  style={{ color: '#17a2b8' }}
                                                ></i>
                                              </Link>
                                              &nbsp;&nbsp;
                                              <Link
                                                onClick={(e) =>
                                                  this.RejectRequest(
                                                    e,
                                                    item._id,
                                                    'Natural'
                                                  )
                                                }
                                              >
                                                <i
                                                  className="fas fa-times-circle fa-2x"
                                                  style={{ color: '#c93d3a' }}
                                                ></i>
                                              </Link>
                                            </Fragment>
                                          ) : (
                                            <Fragment>
                                              {item.IsAcceptedNatural ===
                                              true ? (
                                                <b style={{ color: '#17a2b8' }}>
                                                  ACCEPTED{' '}
                                                  <Link
                                                    onClick={(e) =>
                                                      this.RejectRequest(
                                                        e,
                                                        item._id,
                                                        'Natural'
                                                      )
                                                    }
                                                  >
                                                    <i
                                                      className="fas fa-times-circle fa-2x"
                                                      style={{
                                                        color: '#c93d3a',
                                                      }}
                                                    ></i>
                                                  </Link>
                                                </b>
                                              ) : (
                                                <b style={{ color: '#c93d3a' }}>
                                                  REJECTED{' '}
                                                  <Link
                                                    onClick={(e) =>
                                                      this.AcceptRequest(
                                                        e,
                                                        item._id,
                                                        'Natural'
                                                      )
                                                    }
                                                  >
                                                    <i
                                                      className="fas fa-check-circle fa-2x"
                                                      style={{
                                                        color: '#17a2b8',
                                                      }}
                                                    ></i>
                                                  </Link>
                                                </b>
                                              )}
                                            </Fragment>
                                          )}
                                        </td>
                                        <td>
                                          {item.IsPending === true ? (
                                            <Fragment>
                                              <Link
                                                onClick={(e) =>
                                                  this.AcceptRequest(
                                                    e,
                                                    item._id,
                                                    'Labgrown'
                                                  )
                                                }
                                              >
                                                <i
                                                  className="fas fa-check-circle fa-2x"
                                                  style={{ color: '#17a2b8' }}
                                                ></i>
                                              </Link>
                                              &nbsp;&nbsp;
                                              <Link
                                                onClick={(e) =>
                                                  this.RejectRequest(
                                                    e,
                                                    item._id,
                                                    'Labgrown'
                                                  )
                                                }
                                              >
                                                <i
                                                  className="fas fa-times-circle fa-2x"
                                                  style={{ color: '#c93d3a' }}
                                                ></i>
                                              </Link>
                                            </Fragment>
                                          ) : (
                                            <Fragment>
                                              {item.IsAcceptedLabgrown ===
                                              true ? (
                                                <b style={{ color: '#17a2b8' }}>
                                                  ACCEPTED{' '}
                                                  <Link
                                                    onClick={(e) =>
                                                      this.RejectRequest(
                                                        e,
                                                        item._id,
                                                        'Labgrown'
                                                      )
                                                    }
                                                  >
                                                    <i
                                                      className="fas fa-times-circle fa-2x"
                                                      style={{
                                                        color: '#c93d3a',
                                                      }}
                                                    ></i>
                                                  </Link>
                                                </b>
                                              ) : (
                                                <b style={{ color: '#c93d3a' }}>
                                                  REJECTED{' '}
                                                  <Link
                                                    onClick={(e) =>
                                                      this.AcceptRequest(
                                                        e,
                                                        item._id,
                                                        'Labgrown'
                                                      )
                                                    }
                                                  >
                                                    <i
                                                      className="fas fa-check-circle fa-2x"
                                                      style={{
                                                        color: '#17a2b8',
                                                      }}
                                                    ></i>
                                                  </Link>
                                                </b>
                                              )}
                                            </Fragment>
                                          )}
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          ) : (
                            <div className="card-body text-center">No Data</div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="pills-profile"
                    role="tabpanel"
                    aria-labelledby="pills-profile-tab"
                  >
                    <StoneOffer />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="pills-contact"
                    role="tabpanel"
                    aria-labelledby="pills-contact-tab"
                  >
                    <Inquiry />
                  </div>
                </div>
              </div>
            </section>

            {/* <section className="content">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-12">



                                        <div className="card" >
                                            <div className="card-header">
                                                <h3 className="card-title">Stock API Requests</h3>
                                            </div>
                                            <div className="table-responsive">
                                                <div className="card-body">
                                                    <table id="example1" className="table table-bordered table-striped">
                                                        <thead>
                                                            <tr>
                                                                <th>Name</th>
                                                                <th>UserName</th>
                                                                <th>Approve/Reject</th>

                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {items.map(item => (
                                                                <tr>
                                                                    <td>{item.FullName}</td>
                                                                    <td>{item.UserName}</td>
                                                                    <td>{item.IsPending === false ? <Fragment><Link onClick={(e) => this.AcceptRequest(e, item._id)}><i className="fas fa-check-circle fa-2x" style={{ color: "#17a2b8" }}></i></Link>&nbsp;&nbsp;<Link onClick={(e) => this.RejectRequest(e, item._id)}><i className="fas fa-times-circle fa-2x" style={{ color: "#c93d3a" }}></i></Link></Fragment> : <Fragment>{item.IsAccepted === true ? <b style={{ color: "#17a2b8" }}>ACCEPTED</b> : <b style={{ color: "#c93d3a" }}>REJECTED</b>}</Fragment>}</td>

                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section> */}
          </div>
        </div>
      </div>
    );
  }
}

export default ViewApiRequest;

import React, { Fragment } from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import './App.css';
import AddCustomer from './Authentication/AddCustomer';
import AddSupplier from './Authentication/AddSupplier';
import checkOTP from './Authentication/CheckOTP';
import forgotpassword from './Authentication/ForgotPassword';
import Login from './Authentication/Login';
import PrivateRoute from './Authentication/PrivateRoute';
import PrivateRouteSupplier from './Authentication/PrivateRouteSupplier';
import resetPassword from './Authentication/ResetPassword';
import FormatForm from './Components/ColumnVisibility/FormatForm';
import UpdateFormat from './Components/ColumnVisibility/UpdateFormat';
import PaginationDemo from './Components/Customers/PaginationDemo';
import reactDataTable from './Components/Customers/reactDataTable';
import ViewApiRequest from './Components/Customers/ViewApiRequest';
import ViewCustomer from './Components/Customers/ViewCustomer';
import Home from './Components/HomePage';
import AddInward from './Components/Inward/AddInward';
import FetchAPI from './Components/Inward/FetchAPI';
import FileDemo from './Components/Inward/FileDemo';
import InvalidStone from './Components/Inward/InvalidStone';
import StoneOffer from './Components/Inward/StoneOffer';
import ViewInward from './Components/Inward/ViewInward';
import ImportMapping from './Components/MasterMapping/ImportMapping';
import MappingPage from './Components/MasterMapping/MappingPage';
import MasterEntry from './Components/MasterMapping/MasterEntry';
import UpdateMaster from './Components/MasterMapping/UpdateMaster';
import ViewOrder from './Components/Order/ViewOrder';
import OtpVerified from './Components/OtpVerified';
import AddInwardSupplier from './Components/Party/AddInwardSupplier';
import AddParty from './Components/Party/AddParty';
import DisplayInvalidStones from './Components/Party/InvalidStones';
import SupplierDashboard from './Components/Party/PartyDashboard';
import ViewParty from './Components/Party/ViewParty';
import ViewStockSupplier from './Components/Party/ViewStockSupplier';
import RapImport from './Components/Rapaport/RapImport';
import ViewRapnet from './Components/Rapaport/ViewRapnet';
import grid from './Components/ReactGridDemo/grid';
import TestingInward from './Components/Testing/TestingInward';
import viewTesting from './Components/Testing/ViewTesting';
import Header from './Headers/Header';
import LandingPage from './views/LandingPage';
import ViewStock from './ViewStock';

function App() {
  return (
    <Router>
      <Fragment>
        <Switch>
          <Route exact path="/" component={Login} />
          <PrivateRoute exact path="/adminHome" component={Home} />
          <PrivateRoute
            exact
            path="/viewApiRequest"
            component={ViewApiRequest}
          />
          <Route exact path="/header" component={Header} />
          <PrivateRoute exact path="/addInward" component={AddInward} />
          <PrivateRoute exact path="/addParty" component={AddParty} />
          <PrivateRoute exact path="/viewParty" component={ViewParty} />
          <PrivateRoute exact path="/viewStock" component={ViewStock} />
          <PrivateRoute exact path="/ViewInward" component={ViewInward} />
          <PrivateRoute exact path="/viewStoneOffer" component={StoneOffer} />
          <PrivateRoute exact path="/ViewCustomer" component={ViewCustomer} />
          <PrivateRoute exact path="/addParty/:id" component={AddParty} />
          <PrivateRoute exact path="/addInward/:id" component={AddInward} />
          <Route exact path="/addCustomer" component={AddCustomer} />
          <Route exact path="/forgotpassword" component={forgotpassword} />
          <Route exact path="/checkOTP" component={checkOTP} />
          <Route exact path="/resetPassword" component={resetPassword} />
          <Route exact path="/verifyOtp" component={OtpVerified} />
          <PrivateRoute
            exact
            path="/reactDataTable"
            component={reactDataTable}
          />
          <Route exact path="/PaginationDemo" component={PaginationDemo} />
          <PrivateRoute exact path="/RapImport" component={RapImport} />
          <PrivateRoute exact path="/ViewRapnet" component={ViewRapnet} />
          <PrivateRoute exact path="/ReactGrid" component={grid} />
          <PrivateRoute exact path="/Formvisibility" component={FormatForm} />
          <PrivateRoute exact path="/MasterEntry" component={MasterEntry} />
          <PrivateRoute exact path="/update/:id" component={UpdateMaster} />
          <PrivateRoute
            exact
            path="/updateFormat/:id"
            component={UpdateFormat}
          />
          <PrivateRoute exact path="/ImportMapping" component={ImportMapping} />
          <PrivateRoute exact path="/MappingPage" component={MappingPage} />
          <PrivateRoute exact path="/fetchapi" component={FetchAPI} />

          <PrivateRouteSupplier
            exact
            path="/supplierDashboard"
            component={SupplierDashboard}
          />
          <PrivateRoute exact path="/invalidStone" component={InvalidStone} />
          <Route exact path="/Supplier" component={AddSupplier} />
          <PrivateRoute exact path="/testing" component={TestingInward} />
          <PrivateRoute exact path="/ViewTesting" component={viewTesting} />
          <PrivateRouteSupplier
            exact
            path="/addInwardSupplier"
            component={AddInwardSupplier}
          />
          <PrivateRouteSupplier
            exact
            path="/displayInvalidStones"
            component={DisplayInvalidStones}
          />
          <PrivateRouteSupplier
            exact
            path="/viewStockSupplier"
            component={ViewStockSupplier}
          />
          <PrivateRoute exact path="/ViewOrder" component={ViewOrder} />
          <Route exact path="/FileDemo" component={FileDemo} />
          <Route exact path="/welcome" component={LandingPage} />
        </Switch>
      </Fragment>
    </Router>
  );
}

export default App;

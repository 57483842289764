import Axios from 'axios';
import React, { Component, useEffect, useState } from 'react';

import ArrowDownward from '@material-ui/icons/ArrowDownward';


import { Grid, GridColumn } from '@progress/kendo-react-grid';



class reactDataTable extends Component {
    constructor(props) {
        super(props)
        this.state = {
            customerlist: [],
            pager: {},
            pageOfItems: [],
            page: []
        }
    }
    componentWillMount() {
        this.unlisten = this.props.history.listen((location, action) => {
            this.loadPage()
        });
    }
    componentDidMount() {
        this.loadPage()
    }

    // onChangePage() {
    //     const params = new URLSearchParams(window.location.search)
    //     const pageincrement = this.state.pager.currentPage + 1  
    // }


    loadPage() {

        const params = new URLSearchParams(window.location.search)
        this.state.page = parseInt(params.get('page')) || 1

        if (this.state.page !== this.state.pager.currentPage) {
            // 
            Axios.get(`/users/InwardDetailData?page=${this.state.page}`).then(res => {
                this.setState({
                    pager: res.data.pager,
                    pageOfItems: res.data.pageOfItems,

                })


              
            })


        }
    }



    render() {


        const onPageChange = (event) => {
            this.state.pager.startIndex = event.page.skip
           
   
            this.state.pager.endIndex = event.page.take
            

        }


        const { pager, pageOfItems } = this.state



        return (
            <div>
                <Grid
                    data={pageOfItems.slice(pager.startIndex)}
                    pageable={true}
                    filterable={true}
                    sortable={true}

                    skip={pager.startIndex}
                    take={10}

                    onPageChange={onPageChange}
                    total={pager.totalItems}
                >
                    <GridColumn field="SrNo" title="SrNo"></GridColumn>
                    <GridColumn field="Stock" title="Stock"></GridColumn>
                    <GridColumn field="VendorStock" title="VendorStock"></GridColumn>
                    <GridColumn field="PartyName" title="PartyName"></GridColumn>
                    <GridColumn field="Availability" title="Availability"></GridColumn>
                </Grid>
            </div>



        );
    }
}

export default reactDataTable;
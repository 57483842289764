import { useState, useEffect } from "react";
import { Navigation } from "../Components/landing-page/navigation";
import { Header } from "../Components/landing-page/header";
import { Features } from "../Components/landing-page/features";
import { About } from "../Components/landing-page/about";
import { Services } from "../Components/landing-page/services";
import { Gallery } from "../Components/landing-page/gallery";
import { Testimonials } from "../Components/landing-page/testimonials";
import { Team } from "../Components/landing-page/Team";
import { Contact } from "../Components/landing-page/contact";
import JsonData from "../data/data.js";
// import SmoothScroll from "smooth-scroll";
// import "./LandingPage.css";
// import "./bootstrap.css" 
import "./style.css"
// import "./bootstrap.min.css" 
// export const scroll = new SmoothScroll('a[href*="#"]', {
//   speed: 1000,
//   speedAsDuration: true,
// });

const LandingPage = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <div>
      <Navigation />
      <Header data={landingPageData.Header} />
      <Features data={landingPageData.Features} />
      <About data={landingPageData.About} />
      <Services data={landingPageData.Services} />
      {/* <Gallery data={landingPageData.Gallery}/> */}
      <Testimonials data={landingPageData.Testimonials} />
      <Team data={landingPageData.Team} />
      <Contact data={landingPageData.Contact} />
    </div>
  );
};

export default LandingPage;

import galleryLarge01 from '../views/img/portfolio/01-large.jpg'
import gallerySmall01 from '../views/img/portfolio/01-small.jpg'

import galleryLarge02 from '../views/img/portfolio/02-large.jpg'
import gallerySmall02 from '../views/img/portfolio/02-small.jpg'

import galleryLarge03 from '../views/img/portfolio/03-large.jpg'
import gallerySmall03 from '../views/img/portfolio/03-small.jpg'

import galleryLarge04 from '../views/img/portfolio/04-large.jpg'
import gallerySmall04 from '../views/img/portfolio/04-small.jpg'

import galleryLarge05 from '../views/img/portfolio/05-large.jpg'
import gallerySmall05 from '../views/img/portfolio/05-small.jpg'

import galleryLarge06 from '../views/img/portfolio/06-large.jpg'
import gallerySmall06 from '../views/img/portfolio/06-small.jpg'

import testimodanial01 from "../views/img/testimonials/01.jpg"
import testimodanial02 from "../views/img/testimonials/02.jpg"
import testimodanial03 from "../views/img/testimonials/03.jpg"
import testimodanial04 from "../views/img/testimonials/04.jpg"
import testimodanial05 from "../views/img/testimonials/05.jpg"
import testimodanial06 from "../views/img/testimonials/06.jpg"

import founder from "../views/img/team/founder.jpg"

import { TbDiamond } from 'react-icons/tb'
import { BsFillStarFill } from 'react-icons/bs'
import { AiTwotoneApi } from 'react-icons/ai'
import { IoDiamond } from 'react-icons/io5'

const data = {
  Header: {
    title: 'Buying Diamonds Online Made Simple ',
    paragraph:
      'Check variety of diamond inventory at single click, Round, Fancy, Fancy Color, and Small to Big Size all we provide is easy and convenient way to buy diamonds at your finger tips.'
  },
  About: {
    paragraph:
      'We are India based diamond manufacturing and trading company doing business since 2008, our passion is not to sell just diamond but it should make remarkable purchase experience throughout the life as diamonds is forever we are also forever there for our client.',
    Why: ['Quality Assurance', 'Transparent Pricing', 'Smooth Transaction'],
    Why2: [
      'Help you choice right Diamond',
      'Quick response',
      'Worldwide Quick Shipping'
    ]
  },
  Gallery: [
    {
      title: 'Project Title',
      largeImage: galleryLarge01,
      smallImage: gallerySmall01
    },
    {
      title: 'Project Title',
      largeImage: galleryLarge02,
      smallImage: gallerySmall02
    },
    {
      title: 'Project Title',
      largeImage: galleryLarge03,
      smallImage: gallerySmall03
    },
    {
      title: 'Project Title',
      largeImage: galleryLarge04,
      smallImage: gallerySmall04
    },
    {
      title: 'Project Title',
      largeImage: galleryLarge05,
      smallImage: gallerySmall05
    },
    {
      title: 'Project Title',
      largeImage: galleryLarge06,
      smallImage: gallerySmall06
    }
    // {
    //   title: 'Project Title',
    //   largeImage: 'img/portfolio/07-large.jpg',
    //   smallImage: 'img/portfolio/07-small.jpg'
    // },
    // {
    //   title: 'Project Title',
    //   largeImage: 'img/portfolio/08-large.jpg',
    //   smallImage: 'img/portfolio/08-small.jpg'
    // },
    // {
    //   title: 'Project Title',
    //   largeImage: 'img/portfolio/09-large.jpg',
    //   smallImage: 'img/portfolio/09-small.jpg'
    // }
  ],
  Services: [
    {
      icon: <TbDiamond />,
      name: 'Diamond Search',
      text:
        'Either Natural or Lab grown diamonds you are looking for we have filters available to help you find your desired diamond according to 4cs of diamond and advance filter. '
    },
    {
      icon: 'fa fa-cart-arrow-down',
      name: 'Buying Online',
      text:
        'When buying online have so many questions in mind about quality and security of unseen products, we made it simple giving you quality assurance about the purchase you made is fully described before delivery.'
    },
    {
      icon: <AiTwotoneApi />,
      name: 'Data Integration (API)',
      text:
        'We provide diamonds data feed for your jewelry stores at no cost.'
    },
    // {
    //   icon: 'fa fa-language',
    //   name: 'Consectetur adipiscing',
    //   text:
    //     'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sed dapibus leo nec ornare diam sedasd commodo nibh ante facilisis bibendum dolor feugiat at.'
    // },
    {
      icon: 'fa fa-plane',
      name: 'Worldwide Shipping',
      text:
        'We provide worldwide shipping to our client with insurance so you no need to worry about delivery of diamonds we take care of it. '
    },
    // {
    //   icon: 'fa fa-pie-chart',
    //   name: 'Consectetur adipiscing',
    //   text:
    //     'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sed dapibus leo nec ornare diam sedasd commodo nibh ante facilisis bibendum dolor feugiat at.'
    // }
  ],
  Testimonials: [
    {
      img: testimodanial01,
      text:
        'this is what we need all they have, thanks shree team for you support',
      name: 'Jason'
    },
    {
      img: testimodanial02,
      text:
        'it was smooth transaction thank you for support to help me buy in my budget better diamond.',
      name: 'Johnathan '
    },
    {
      img: testimodanial03,
      text:
        'it was difficult for me to understand my first purchase they gave options to choose from, it made simple for me',
      name: 'Yan'
    },
    {
      img: testimodanial04,
      text:
        'it was difficult for me to understand my first purchase they gave options to choose from, it made simple for me',
      name: 'Katty'
    },
    {
      img: testimodanial05,
      text:
        'Whenever have diamonds in mind I have Shree Diamonds in mind.',
      name: 'Roger'
    },
    {
      img: testimodanial06,
      text:
        'thanks for your help from buying to delivery regular updates and fast response.',
      name: 'Sophia'
    }
  ],
  Team: [
    {
      img: founder,
      name: 'Gaurang Gadhavi',
      job: 'Founder of Shree diamonds'
    },
    // {
    //   img: 'img/team/02.jpg',
    //   name: 'Mike Doe',
    //   job: 'Senior Designer'
    // },
    // {
    //   img: 'img/team/03.jpg',
    //   name: 'Jane Doe',
    //   job: 'Senior Designer'
    // },
    // {
    //   img: 'img/team/04.jpg',
    //   name: 'Karen Doe',
    //   job: 'Project Manager'
    // }
  ],
  Contact: {
    address: 'D-304 Shaswat Building,  Mahidharpura, Surat - 395006',
    phone: '+91 9898773044',
    email: 'shreediams@gmail.com',
    facebook: 'fb.com',
    twitter: 'twitter.com',
    youtube: 'youtube.com'
  },
  Features: [
    {
      icon: <TbDiamond />,
      title: 'Natural Diamonds',
      text: 'GIA, IGI, HRD, Certified Natural Diamonds '
    },
    {
      icon: <IoDiamond />,
      title: 'Lab Grown Diamonds',
      text: 'GIA, IGI Certified Lab Grown Diamonds'
    },
    {
      icon: <BsFillStarFill />,
      title: 'Quality Assurance',
      text:
        'is assuring all diamonds checked by our team to require specification provided is correct.'
    },
    {
      icon: 'fa fa-magic',
      title: 'Need data?',
      text:
        'We provide no cost diamond data feed integration for your jewelry store.'
    }
  ]
}

export default data

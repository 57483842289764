import axios from 'axios';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../Headers/Header';
import $ from 'jquery'
import { LeakAddTwoTone } from '@material-ui/icons';

var script, script1
class ViewRapnet extends Component {
    constructor(props) {
        super(props);
        this.state = {
            viewRound: [],
            viewPear: []
        }
        this.handleItemChange = this.handleItemChange.bind(this)
    }

    handleItemChange(e) {


        axios.get(`/users/getRapnetData`).then(res => {
            // if (e.target.value == "round") {
            //     this.setState({
            //         viewRound: res.data
            //     })



            //    
            // }
            // else {
            //     this.setState({
            //         viewPear: res.data
            //     })

            //     $(".table1").hide()
            //     $(".table2").show()
            // }
            // 

            this.setState({
                viewRound: res.data
            })
            // this.componentDidMount()
            if (e.target.value === "round") {


                script = document.createElement("script")
                script.src = `js/content.js`
                script.id = "one"
                script.type = "text/javascript";

                document.body.append(script)

                if (document.getElementById("two") !== undefined || document.getElementById("two") !== "") {

                    $("#two").remove()


                }
                $("#pearDiv").hide()
                $("#roundDiv").show()
            }
            else {



                script1 = document.createElement("script")
                script1.src = `js/content1.js`
                script1.id = "two"
                script1.type = "text/javascript";

                document.body.append(script1)

                if (document.getElementById("one") !== undefined && document.getElementById("one") !== "") {
                    $("#one").remove()


                }
                $("#roundDiv").hide()

                $("#pearDiv").show()





            }
        })
    }
    componentDidMount() {
        $("#roundDiv").hide()
        $("#pearDiv").hide()

    }



    render() {
        return (
            <div>
                <Header />

                <div className="wrapper">
                    <div className="content-wrapper">
                        <section className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-2">
                                    <div className="col-sm-6">
                                        <h1 >View Rapaport</h1>
                                    </div>
                                    <div className="col-sm-6">
                                        <ol className="breadcrumb float-sm-right">
                                            <li className="breadcrumb-item"><a href="#" style={{ color: "#17a2b8" }}>Home</a></li>
                                            <li className="breadcrumb-item active">View Rapnet</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section>
                            <div className="content">
                                <div className="container-fluid">
                                    <Link to="/RapImport" className="btn btn-info" ><i className="fa fa-plus"></i>&nbsp;Import Rapaport</Link>
                                    <div className="card" >
                                        <div className="card-header">
                                            <h3 className="card-title">View Rapaport Details</h3>
                                            <div>

                                                <select id="drop" className="form-control col-md-3" onChange={this.handleItemChange} style={{ float: "right" }}>
                                                    <option disabled selected>Select Shape</option>
                                                    <option value="pear">Pear</option>
                                                    <option value="round">Round</option>

                                                </select>

                                            </div>
                                        </div>

                                        <div id="roundDiv" className="card-body">
                                            <div className="table-responsive"  >
                                                <div>
                                                    <table id="example1" className="table1 jsDataTable table table-bordered table-striped" >
                                                        <thead>
                                                            <tr>
                                                                <th>Shape</th>
                                                                <th>Clarity</th>
                                                                <th>Color</th>
                                                                <th>LowSize</th>
                                                                <th>HighSize</th>
                                                                <th>Price</th>
                                                                <th>PriceDate</th>

                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.viewRound.filter(dta => dta.Shapename === "round").map((view) => (

                                                                <tr>
                                                                    <td>{view.Shape}</td>
                                                                    <td>{view.Clarity}</td>
                                                                    <td>{view.Color}</td>
                                                                    <td>{view.LowSize}</td>
                                                                    <td>{view.HighSize}</td>
                                                                    <td>{view.Price}</td>
                                                                    <td>{view.PriceDate}</td>
                                                                </tr>

                                                            ))}
                                                        </tbody>


                                                    </ table >
                                                </div>



                                            </div>

                                        </div>
                                        <div id="pearDiv" className="card-body">
                                            <div className="table-responsive" >
                                                <div>
                                                    <table id="example2" className="table2 jsDataTable table table-bordered table-striped" >
                                                        <thead>
                                                            <tr>
                                                                <th>Shape</th>
                                                                <th>Clarity</th>
                                                                <th>Color</th>
                                                                <th>LowSize</th>
                                                                <th>HighSize</th>
                                                                <th>Price</th>
                                                                <th>PriceDate</th>

                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.viewRound.filter(dta => dta.Shapename === "pear").map((view) => (

                                                                <tr>
                                                                    <td>{view.Shape}</td>
                                                                    <td>{view.Clarity}</td>
                                                                    <td>{view.Color}</td>
                                                                    <td>{view.LowSize}</td>
                                                                    <td>{view.HighSize}</td>
                                                                    <td>{view.Price}</td>
                                                                    <td>{view.PriceDate}</td>
                                                                </tr>

                                                            ))}
                                                        </tbody>


                                                    </ table >
                                                </div>



                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        );
    }
}



export default ViewRapnet;
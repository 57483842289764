import axios from 'axios';
import React, { Component } from 'react';
import { ProgressBar, Jumbotron, Button, Form } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';

const chunkSize = 1048576; //its 3MB, increase the number measure in mb
class FileDemo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showProgress: false,
      counter: 1,
      fileToBeUpload: {},
      beginingOfTheChunk: 0,
      endOfTheChunk: chunkSize,
      progress: 0,
      fileGuid: '',
      fileSize: 0,
      chunkCount: 0,
    };
    this.handleClick = this.handleClick.bind(this);
  }
  handleClick(e) {
    e.preventDefault();
    axios.get('/users/FileDemoBulk').then((resp) => {});
  }
  render() {
    return (
      <Jumbotron>
        <Form>
          <Form.Group>
            <Form.File
              id="exampleFormControlFile1"
              label="Example file input"
            />
          </Form.Group>
          {/* <Form.Group style={{ display: showProgress ? "block" : "none" }}>
                    {progressInstance}
                    </Form.Group> */}
        </Form>
      </Jumbotron>
    );
  }
}

export default FileDemo;


import axios from 'axios';
import React, { Component } from 'react';
import swal from 'sweetalert';
import Header from '../../Headers/Header'

class InvalidStone extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fileimport: ''
        }
        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    handleChange(e) {
        this.state.fileimport = e.target.files[0]
    }
    handleSubmit(e) {
        e.preventDefault()
        const formData = new FormData()
        formData.append('fileimport', this.state.fileimport)
        axios.post('/users/invalidStone', formData).then((res) => {
            if (res.data.status === "Status") {
                swal('File Import', '', 'success')
            }
        })
    }
    render() {
        return (
            <div>
                <Header />
                <div className="wrapper">
                    <div className="content-wrapper">
                        <section className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-2">
                                    <div className="col-sm-6">
                                        <h1>Invalid Stone</h1>
                                    </div>
                                    <div className="col-sm-6">
                                        <ol className="breadcrumb float-sm-right">
                                            <li className="breadcrumb-item"><a href="#" style={{ color: "#17a2b8" }}>Home</a></li>
                                            <li className="breadcrumb-item active">Invalid Stone</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="container">
                            <form onSubmit={this.handleSubmit}>
                                <input type="file" name="fileimport" onChange={this.handleChange} />
                                <button type="submit">Submit</button>
                            </form>
                        </section>

                    </div>
                </div>
            </div>
        );
    }
}

export default InvalidStone;
import Axios from 'axios';

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../Headers/Header';
import swal from 'sweetalert'

class ImportMapping extends Component {
    constructor(props) {
        super(props)
        this.state = {
            import: '',
            Party: [],
            partyID: '',
            uploadPercentage: 0,
            getMarkup: ''
        }
        this.handleFile = this.handleFile.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleChange = this.handleChange.bind(this)
    }
    handleFile(event) {

        this.setState({
            import: event.target.files[0]
        })

    }
    handleChange(event) {


        this.setState({

            partyID: event.target.value,
            // uploadPercentage: this.state.uploadPercentage,
        })
        Axios.get(`/displayMarkup/${event.target.value}`).then(resp => {
            this.setState({
                getMarkup: resp.data.markup
            })
         
        })


    }
    componentDidMount() {
        Axios.get('/users/getParties').then(res => {
         
            this.setState({
                Party: res.data
            })
        })
    }
    handleSubmit(e) {
        e.preventDefault()
        const formData = new FormData()
        formData.append('import', this.state.import)
        // formData.append('Shapename', this.state.Shapename)
        formData.append('partyID', this.state.partyID)


        Axios.post(`/users/MappingImport`, formData, {

            onUploadProgress: ProgressEvent => {
                //  const {loaded,total}=ProgressEvent
                let percent = Math.round(ProgressEvent.loaded / ProgressEvent.total * 100)
               

                if (percent < 100) {
                    this.setState({ uploadPercentage: percent })
                }

            }
        }).then((response) => {

            if (response.data.msg) {

                swal("File has been imported", {
                    button: false,
                    timer: 1500,
                    icon: 'success'
                });
            }


        })
    }
    render() {
        const {
            Party, partyID
        } = this.state
        return (
            <div>
                <Header />
                <div className="wrapper">
                    <div className="content-wrapper">
                        <section className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-2">
                                    <div className="col-sm-6">
                                        <h1>Import Mapping</h1>
                                    </div>
                                    <div className="col-sm-6">
                                        <ol className="breadcrumb float-sm-right">
                                            <li className="breadcrumb-item"><Link to="/adminHome" style={{ color: "#17a2b8" }}>Home</Link></li>
                                            <li className="breadcrumb-item active">Import Mapping</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="container">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-4">
                                        <select className="form-control" name="partyID" value={partyID} onChange={this.handleChange}>
                                            <option disabled={this.props.defaultDisabled ? true : null} selected>--Select Party--</option>
                                            {
                                                Party.map((p, key) => (
                                                    <option value={p._id} key={key}>{p.partyName}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                    <div className="col-md-2">
                                        <label className="btn btn-secondary"><input type="file" name="import" style={{ display: "none" }} onChange={this.handleFile} />Formula Column</label>

                                    </div>
                                    <div className="col-md-3">
                                        <button className="btn btn-info" type="submit" onClick={this.handleSubmit} >Save </button>
                                    </div>
                                    <div className="col-md-3">
                                        <div style={{ display: "flex" }}>
                                            <label style={{ marginTop: "5px" }}>Markup</label>
                                            <input type="text" value={this.state.getMarkup} readOnly className="form-control ml-2" style={{ width: "20%" }} />

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </section>
                    </div>

                </div>
            </div>
        );
    }
}

export default ImportMapping;
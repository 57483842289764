import React, { Component } from 'react';
import Header from '../../Headers/PartyHeader';
import swal from 'sweetalert'
import disableBrowserBackButton from 'disable-browser-back-navigation'

class PartyDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            UserName: localStorage.getItem('user'),
            token: localStorage.getItem('token')
        }
    }


    componentDidMount() {
      
        if (this.state.token == null || this.state.UserName == null) {
            swal('Please Log In', '', 'error')
            this.props.history.pushxz("/")
        }
        else {
            disableBrowserBackButton()
        }


    }



    render() {
        return (
            <div>
                <Header />
                <div className="wrapper">
                    <div class="content-wrapper">
                        <h1>Suppliers</h1>
                    </div>
                </div>
            </div>
        );
    }
}



export default PartyDashboard;

import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import Header from '../../Headers/Header'

import Axios from 'axios'
import exportFromJSON from 'export-from-json'

class viewTesting extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inwards: [],
            pager: {},
            pageOfItems: [],
            nextpage: '',
            page: {},
            InwardType: "NATURAL"
        }
        this.dropDownInwardType = this.dropDownInwardType.bind(this)
    }
    dropDownInwardType(e) {
        this.state.InwardType = e.target.value
    
        this.componentDidMount()
    }
    loadPage(name, click) {
        const params = new URLSearchParams(window.location.search)
        this.state.page = parseInt(params.get('page')) || 1
        this.state.name = name
     


        if (this.state.page !== this.state.pager.currentPage) {


            Axios.get(`/TestingData?page=${this.state.page}&InwardType=${this.state.InwardType}`).then(res => {
                this.setState({
                    pager: res.data.pager,
                    pageOfItems: res.data.pageOfItems,
                    nextpage: window.location.search,
                    status: res.data.status
                })
               
            })
        }

    }
    exportAll() {
        Axios.get('/exportAll').then((res) => {
            var now = new Date();
            // var blob = new Blob([JSON.stringify(res)], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
            // saveAs(blob, "inward.xlsx");
            const data = res.data
            const fileName = new Date().toLocaleDateString() + "_Inward"
            const exportType = 'csv'

            exportFromJSON({ data, fileName, exportType })
        })
    }
    componentWillMount() {
        this.unlisten = this.props.history.listen((location, action) => {
            this.loadPage(this.state.name)

        });
    }
    componentDidMount() {
        this.loadPage(this.state.name)
    }
    render() {
        const { pager } = this.state
        return (
            <div>
                <Header />
                <div className="wrapper">
                    <div className="content-wrapper">
                        <section className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-2">
                                    <div className="col-sm-6">
                                        <h1>Testing Module</h1>
                                    </div>
                                    <div className="col-sm-6">
                                        <ol className="breadcrumb float-sm-right">
                                            <li className="breadcrumb-item" key="home"><a href="#" style={{ color: "#17a2b8" }}>Home</a></li>
                                            <li className="breadcrumb-item active" key="testing">Testing</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="content-fluid">
                            <div className="container-fluid">
                                <Link to="/testing" className="btn btn-info"><i className="fas fa-chevron-left"></i>&nbsp; Back</Link>
                                <div className="card">
                                    <div className="card-header">
                                        <div className="row">
                                            <div className="col-md-1">
                                                <button type="button" className="btn btn-secondary" style={{ marginLeft: "3px" }} onClick={this.exportAll}>Export All</button>

                                            </div>
                                            <div className="col-md-2">
                                                <select className="form-control" name="InwardType" value={this.state.InwardType} style={{ marginTop: "5px" }} onChange={this.dropDownInwardType}>
                                                    <option>NATURAL</option>
                                                    <option>LABGROWN</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card-body">
                                        <div className="table-responsive scroll">
                                            <div style={{ overflowX: "auto" }} className="tableFixHead " >

                                                <table id="example2" className="table table-bordered table-striped " >
                                                    <thead >
                                                        <tr >
                                                            <th data-index="2" className="jsStock "><div><Link className="removestyle  " onClick={(e) => this.loadPage('Stock')}>Stock</Link></div></th>
                                                            <th data-index="3" className="jsVendorStock"><div><Link className="removestyle  " onClick={(e) => this.loadPage('VendorStock')}>VenderStock</Link></div></th>
                                                            <th className="jsPartyName"><div><Link className="removestyle  " onClick={(e) => this.loadPage('VendorName')}>VendorName</Link></div></th>
                                                            <th className="jsAvailability"><div><Link className="removestyle  " onClick={(e) => this.loadPage('Availability')}>Availability</Link></div></th>
                                                            <th className="jsShape"><div><Link className="removestyle  " onClick={(e) => this.loadPage('Shape')}>Shape</Link></div></th>
                                                            <th className="jsWeight"><div><Link className="removestyle  " onClick={(e) => this.loadPage('Weight')}>Weight </Link></div></th>
                                                            <th className="jsColor"><div><Link className="removestyle  " onClick={(e) => this.loadPage('Color')}>Color </Link></div></th>
                                                            <th className="jsClarity"><div><Link className="removestyle  " onClick={(e) => this.loadPage('Clarity')}>Clarity </Link></div></th>
                                                            <th className="jsCutGrade"><div><Link className="removestyle  " onClick={(e) => this.loadPage('CutGrade')}>CutGrade </Link></div></th>
                                                            <th className="jsPolish"><div><Link className="removestyle  " onClick={(e) => this.loadPage('Polish')}>Polish </Link></div></th>
                                                            <th className="jsSymmetry"><div><Link className="removestyle  " onClick={(e) => this.loadPage('SYMM')}>Symmentry </Link></div></th>
                                                            <th className="jsFluorescenceIntensity"><div><Link className="removestyle  " onClick={(e) => this.loadPage('FluorescenceIntensity')}>FluorescenceIntensity </Link></div></th>
                                                            <th className="jsFluorescenceColor"><div><Link className="removestyle  " onClick={(e) => this.loadPage('FluorescenceColor')}>FluorescenceColor </Link></div></th>
                                                            <th className="jsMeasurements"><div><Link className="removestyle  " onClick={(e) => this.loadPage('Measurements')}>Measurements </Link></div></th>
                                                            <th className="jsLab"><div><Link className="removestyle  " onClick={(e) => this.loadPage('Lab')}>Lab </Link></div></th>
                                                            <th className="jsCertificate"><div><Link className="removestyle  " onClick={(e) => this.loadPage('Certificate')}>Certificate </Link></div></th>
                                                            <th className="jsTreatment"><div><Link className="removestyle  " onClick={(e) => this.loadPage('Treatment')}>Treatment </Link></div></th>

                                                            <th className="jsVendorDiscount"><div><Link className="removestyle  " onClick={(e) => this.loadPage('VendorDiscount')}>VendorDiscount </Link></div></th>

                                                            <th className="jsCashPrice"><div><Link className="removestyle  " onClick={(e) => this.loadPage('CashPrice')}>CashPrice </Link></div></th>
                                                            <th className="jsCashPriceDiscount"><div><Link className="removestyle  " onClick={(e) => this.loadPage('CashPriceDiscount')}>CashPriceDiscount </Link></div></th>
                                                            <th className="jsFancyColor"><div><Link className="removestyle  " onClick={(e) => this.loadPage('FancyColor')}>FancyColor </Link></div></th>
                                                            <th className="jsFancyColorIntensity"><div><Link className="removestyle  " onClick={(e) => this.loadPage('FancyColorIntensity')}>FancyColorIntensity </Link></div></th>
                                                            <th className="jsFancyColorOvertone"><div><Link className="removestyle  " onClick={(e) => this.loadPage('FancyColorOvertone')}>FancyColorOvertone </Link></div></th>
                                                            <th className="jsDepth"><div><Link className="removestyle  " onClick={(e) => this.loadPage('Depth')}>Depth </Link></div></th>
                                                            <th className="jsTable"><div><Link className="removestyle  " onClick={(e) => this.loadPage('Table')}>Table </Link></div></th>
                                                            <th className="jsGirdleThin"><div><Link className="removestyle  " onClick={(e) => this.loadPage('GirdleThin')}>GirdleThin </Link></div></th>
                                                            <th className="jsGirdleThick"><div><Link className="removestyle  " onClick={(e) => this.loadPage('GirdleThick')}>GirdleThick </Link></div></th>
                                                            <th className="jsGirdle"><div><Link className="removestyle  " onClick={(e) => this.loadPage('Girdle')}>Girdle </Link></div></th>
                                                            <th className="jsGirdleCondition"><div><Link className="removestyle  " onClick={(e) => this.loadPage('GirdleCondition')}>GirdleCondition </Link></div></th>
                                                            <th className="jsCuletSize"><div><Link className="removestyle  " onClick={(e) => this.loadPage('CuletSize')}>CuletSize </Link></div></th>
                                                            <th className="jsCuletCondition"><div><Link className="removestyle  " onClick={(e) => this.loadPage('CuletCondition')}>CuletCondition </Link></div></th>
                                                            <th className="jsCrownHeight"><div><Link className="removestyle  " onClick={(e) => this.loadPage('CrownHeight')}>CrownHeight </Link></div></th>
                                                            <th className="jsCrownAngle"><div><Link className="removestyle  " onClick={(e) => this.loadPage('CrownAngle')}>CrownAngle </Link></div></th>
                                                            <th className="jsPavilionDepth"><div><Link className="removestyle  " onClick={(e) => this.loadPage('PavilionDepth')}>PavilionDepth </Link></div></th>
                                                            <th className="jsPavilionAngle"><div><Link className="removestyle  " onClick={(e) => this.loadPage('PavilionAngle')}>PavilionAngle </Link></div></th>
                                                            <th className="jsLaserInscription"><div><Link className="removestyle  " onClick={(e) => this.loadPage('LaserInscription')}>LaserInscription </Link></div></th>
                                                            <th className="jsCertcomment"><div><Link className="removestyle  " onClick={(e) => this.loadPage('Certcomment')}>Certcomment </Link></div></th>
                                                            <th className="jsCountry"><div><Link className="removestyle  " onClick={(e) => this.loadPage('Country')}>Country </Link></div></th>
                                                            <th className="jsState"><div><Link className="removestyle  " onClick={(e) => this.loadPage('State')}>State </Link></div></th>
                                                            <th className="jsCity"><div><Link className="removestyle  " onClick={(e) => this.loadPage('City')}>City </Link></div></th>
                                                            <th className="jsTimetolocation"><div><Link className="removestyle  " onClick={(e) => this.loadPage('Timetolocation')}>Timetolocation </Link></div></th>
                                                            <th className="jsIsMatchedPairSeparable"><div><Link className="removestyle  " onClick={(e) => this.loadPage('IsMatchedPairSeparable')}>IsMatchedPairSeparable </Link></div></th>
                                                            <th className="jsPairStock"><div><Link className="removestyle  " onClick={(e) => this.loadPage('PairStock')}>PairStock </Link></div></th>
                                                            <th className="jsAllowRapLinkFeed"><div><Link className="removestyle  " onClick={(e) => this.loadPage('AllowRapLinkFeed')}>AllowRapLinkFeed </Link></div></th>
                                                            <th className="jsParcelStones"><div><Link className="removestyle  " onClick={(e) => this.loadPage('ParcelStones')}>ParcelStones </Link></div></th>
                                                            <th className="jscertificateFilename"><div><Link className="removestyle  " onClick={(e) => this.loadPage('CertificateFilename')}>CertificateFilename </Link></div></th>
                                                            <th className="jsDiamondImage"><div><Link className="removestyle  " onClick={(e) => this.loadPage('DiamondImage')}>DiamondImage{this.state.status ? <i className="fa fa-caret-down" aria-hidden="true"></i> : <i className="fa fa-caret-up" aria-hidden="true"></i>}</Link></div></th>
                                                            <th className="jsSarinLoupe"><div><Link className="removestyle  " onClick={(e) => this.loadPage('SarinLoupe')}>SarinLoupe </Link></div></th>
                                                            <th className="jsTradeShow"><div><Link className="removestyle  " onClick={(e) => this.loadPage('TradeShow')}>TradeShow </Link></div></th>
                                                            <th className="jsKeytosymbols"><div><Link className="removestyle  " onClick={(e) => this.loadPage('Keytosymbols')}>Keytosymbols </Link></div></th>
                                                            <th className="jsShade"><div><Link className="removestyle  " onClick={(e) => this.loadPage('Shade')}>Shade </Link></div></th>
                                                            <th className="jsStarLength"><div><Link className="removestyle  " onClick={(e) => this.loadPage('StarLength')}>StarLength </Link></div></th>
                                                            <th className="jsCenterInclusion"><div><Link className="removestyle  " onClick={(e) => this.loadPage('CenterInclusion')}>CenterInclusion </Link></div></th>
                                                            <th className="jsBlackInclusion"><div><Link className="removestyle  " onClick={(e) => this.loadPage('BlackInclusion')}>BlackInclusion </Link></div></th>
                                                            <th className="jsMemberComment"><div><Link className="removestyle  " onClick={(e) => this.loadPage('MemberComment')}>MemberComment </Link></div></th>
                                                            <th className="jsReportIssueDate"><div><Link className="removestyle  " onClick={(e) => this.loadPage('ReportIssueDate')}>ReportIssueDate </Link></div></th>
                                                            <th className="jsReportType"><div><Link className="removestyle  " onClick={(e) => this.loadPage('ReportType')}>ReportType </Link></div></th>
                                                            <th className="jsLabLocation"><div><Link className="removestyle  " onClick={(e) => this.loadPage('LabLocation')}>LabLocation </Link></div></th>
                                                            <th className="jsBrand"><div><Link className="removestyle  " onClick={(e) => this.loadPage('Brand')}>Brand </Link></div></th>
                                                            <th className="jsMilky"><div><Link className="removestyle  " onClick={(e) => this.loadPage('Milky')}>Milky </Link></div></th>
                                                            <th className="jsRapnetDiscount"><div><Link className="removestyle" onClick={(e) => this.loadPage('RapnetDiscount')}>RapnetDiscount </Link></div></th>
                                                            <th className="jsRapNetPrice"><div><Link className="removestyle" onClick={(e) => this.loadPage('RapNetPrice')}>RapNetPrice </Link></div></th>
                                                            <th className="jsRap"><div><Link className="removestyle" onClick={(e) => this.loadPage('Rap')}>Rap </Link></div></th>
                                                            <th className="jsNetValue"><div><Link className="removestyle" onClick={(e) => this.loadPage('NetValue')}>NetValue </Link></div></th>
                                                            {/* <th>Export Excel</th> */}
                                                            <th><div><Link className="removestyle  " onClick={(e) => this.loadPage('FinalPrice')}>INWARD TYPE{this.state.status == "asc" ? <i className="fa fa-caret-down" aria-hidden="true"></i> : <i className="fa fa-caret-up" aria-hidden="true"></i>}</Link></div></th>



                                                        </tr>
                                                    </thead>

                                                    <tbody >
                                                        {
                                                            this.state.pageOfItems.map((view, index) => (
                                                                <tr key={index}>

                                                                    <td className="jsStock">{view.Stock}</td>
                                                                    <td className="jsVendorStock">{view.VendorStock}</td>

                                                                    <td className="jsPartyName">{view.PartyName}</td>
                                                                    <td className="jsAvailability">{view.Availability}</td>
                                                                    <td className="jsShape">{view.Shape}</td>
                                                                    <td className="jsWeight">{view.Weight}</td>
                                                                    <td className="jsColor">{view.Color}</td>
                                                                    <td className="jsClarity">{view.Clarity}</td>
                                                                    <td className="jsCutGrade">{view.CutGrade}</td>
                                                                    <td className="jsPolish">{view.Polish}</td>
                                                                    <td className="jsSymmetry">{view.Symmetry}</td>
                                                                    <td className="jsFluorescenceIntensity">{view.FluorescenceIntensity}</td>
                                                                    <td className="jsFluorescenceColor">{view.FluorescenceColor}</td>
                                                                    <td className="jsMeasurements">{view.Measurements}</td>
                                                                    <td className="jsLab">{view.Lab}</td>
                                                                    <td className="jsCertificate">{view.Certificate}</td>
                                                                    <td className="jsTreatment">{view.Treatment}</td>
                                                                    <td className="jsVendorDiscount">{view.VendorDiscount}</td>
                                                                    <td className="jsCashPrice">{view.CashPrice}</td>
                                                                    <td className="jsCashPriceDiscount">{view.CashPriceDiscount}</td>
                                                                    <td className="jsFancyColor">{view.FancyColor}</td>
                                                                    <td className="jsFancyColorIntensity">{view.FancyColorIntensity}</td>
                                                                    <td className="jsFancyColorOvertone">{view.FancyColorOvertone}</td>
                                                                    <td className="jsDepth">{view.Depth}</td>
                                                                    <td className="jsTable">{view.Table}</td>
                                                                    <td className="jsGirdleThin">{view.GirdleThin}</td>
                                                                    <td className="jsGirdleThick">{view.GirdleThick}</td>
                                                                    <td className="jsGirdle">{view.Girdle}</td>
                                                                    <td className="jsGirdleCondition">{view.GirdleCondition}</td>
                                                                    <td className="jsCuletSize">{view.CuletSize}</td>
                                                                    <td className="jsCuletCondition">{view.CuletCondition}</td>
                                                                    <td className="jsCrownHeight">{view.CrownHeighht}</td>
                                                                    <td className="jsCrownAngle">{view.CrownAngle}</td>
                                                                    <td className="jsPavilionDepth">{view.PavilionDepth}</td>
                                                                    <td className="jsPavilionAngle">{view.PavilionAngle}</td>
                                                                    <td className="jsLaserInscription">{view.LaserInscription}</td>
                                                                    <td className="jsCertcomment">{view.Certcomment}</td>
                                                                    <td className="jsCountry">{view.Country}</td>
                                                                    <td className="jsState">{view.State}</td>
                                                                    <td className="jsCity">{view.City}</td>
                                                                    <td className="jsTimetolocation">{view.Timetolocation}</td>
                                                                    <td className="jsIsMatchedPairSeparable">{view.IsMatchedPairSeparable}</td>
                                                                    <td className="jsPairStock">{view.PairStock}</td>
                                                                    <td className="jsAllowRapLinkFeed">{view.AllowRapLinkFeed}</td>
                                                                    <td className="jsParcelStones">{view.ParcelStones}</td>
                                                                    <td className="jscertificateFilename">{view.CertificateFilename}</td>
                                                                    <td className="jsDiamondImage">{view.DiamondImage}</td>
                                                                    <td className="jsSarinLoupe">{view.SarinLoupe}</td>
                                                                    <td className="jsTradeShow">{view.TardeShow}</td>
                                                                    <td className="jsKeytosymbols">{view.Keytosymbols}</td>
                                                                    <td className="jsShade">{view.Shade}</td>
                                                                    <td className="jsStarLength">{view.StarLength}</td>
                                                                    <td className="jsCenterInclusion">{view.CenterInclusion}</td>
                                                                    <td className="jsBlackInclusion">{view.BlackInclusion}</td>
                                                                    <td className="jsMemberComment">{view.MemberComment}</td>
                                                                    <td className="jsReportIssueDate">{view.ReportIssueDate}</td>
                                                                    <td className="jsReportType">{view.ReportType}</td>
                                                                    <td className="jsLabLocation">{view.LabLocation}</td>
                                                                    <td className="jsBrand">{view.Brand}</td>
                                                                    <td className="jsMilky">{view.Milky}</td>
                                                                    <td className="jsRapnetDiscount">{view.RapnetDiscount}</td>
                                                                    <td className="jsRapNetPrice">{view.RapNetPrice}</td>
                                                                    <td className="jsRap">{view.Rap}</td>
                                                                    <td className="jsNetValue">{view.NetValue}</td>

                                                                    <td>{view.InwardType}</td>


                                                                </tr>

                                                            ))
                                                        }


                                                    </tbody>


                                                </table>

                                            </div>
                                            <div className="showingtotal">
                                                {"Showing  " + parseInt(pager.startIndex + 1) + "  to " + parseInt(pager.endIndex + 1) + "  of " + pager.totalItems + "  entries"}
                                            </div>
                                            {
                                                pager.pages && pager.pages.length &&
                                                <ul className="pagination" style={{ float: "right", marginTop: "5px" }}>
                                                    <li className={`page-item previous-item ${pager.currentPage == 1 ? 'disabled' : ''}`}>
                                                        <Link to={{ search: `?page=${1}` }} className="page-link">First</Link>
                                                    </li>
                                                    <li className={`page-item previous-item ${pager.currentPage == 1 ? 'disabled' : ''}`}>
                                                        <Link to={{ search: `?page=${pager.currentPage - 1}` }} className="page-link">Previous</Link>
                                                    </li>


                                                    {pager.pages.map(page =>

                                                        < li key={page} className={`page-item number-item ${this.state.page === page ? 'active' : ''}`}>
                                                            <Link to={{ search: `?page=${page}` }} className="page-link">{page}</Link>
                                                        </li>

                                                    )}


                                                    <li className={`page-item next-item ${pager.currentPage === pager.totalPages ? 'disabled' : ''}`}>
                                                        <Link to={{ search: `?page=${parseInt(pager.currentPage) + 1}` }} className="page-link" >Next</Link>
                                                    </li>
                                                    <li className={`page-item last-item ${pager.currentPage === pager.totalPages ? 'disabled' : ''}`}>
                                                        <Link to={{ search: `?page=${pager.totalPages}` }} className="page-link">Last</Link>
                                                    </li>
                                                </ul>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        )
    }
}

export default viewTesting